import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import { getProfileSuccess, putProfileSuccess } from "./actions";

export function* getProfile() {
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;
  const { id_login } = yield select(userInfo);
  try {
    const response = yield call(api.get, API.MY_PROFILE_LIST, {
      id_instituicao,
      id_login,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(getProfileSuccess(response.data.data));
  } catch (err) {
    toast.error("Erro ao carregar informações do perfil!");
  }
}
export function* putProfile({ payload }) {
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;
  const { id_login } = yield select(userInfo);
  const { data } = payload;
  try {
    const response = yield call(api.put, API.MY_PROFILE_EDIT, {
      id_instituicao,
      id_login,
      ...data,
    });
    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    toast.success("Perfil atualizado!");
    yield put(putProfileSuccess());
  } catch (err) {
    toast.error("Erro ao atualizar informações do perfil!");
  }
}

export function* putPassword({ payload }) {
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;
  const { id_login } = yield select(userInfo);
  const { ds_senha } = payload;
  try {
    const response = yield call(api.put, API.PASSWORD_EDIT, {
      id_instituicao,
      id_login,
      ds_senha,
    });
    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    toast.success("Senha atualizado!");
    yield put(putProfileSuccess());
  } catch (err) {
    toast.error("Erro ao senha do perfil!");
  }
}

export default all([
  takeLatest("@profile/GET_PROFILE_REQUEST", getProfile),
  takeLatest("@profile/PUT_PROFILE_REQUEST", putProfile),
  takeLatest("@profile/PUT_PASSWORD_REQUEST", putPassword),
]);
