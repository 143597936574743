export function getFaqSearchRequest(search) {
  return {
    type: "@faq/GET_FAQ_SEARCH_REQUEST",
    payload: { search },
  };
}

export function getFaqSearchFailure() {
  return {
    type: "@faq/GET_FAQ_SEARCH_FAILURE",
  };
}

export function getFaqSearchSuccess(data) {
  return {
    type: "@faq/GET_FAQ_SEARCH_SUCCESS",
    payload: { data },
  };
}

export function getFaqQuestionsRequest(id_faq_categoria) {
  return {
    type: "@faq/GET_FAQ_QUESTION_REQUEST",
    payload: { id_faq_categoria },
  };
}

export function getFaqQuestionsSuccess(data) {
  return {
    type: "@faq/GET_FAQ_QUESTION_SUCCESS",
    payload: { data },
  };
}
export function getFaqCategoriesRequest() {
  return {
    type: "@faq/GET_FAQ_CATEGORIES_REQUEST",
  };
}

export function getFaqCategoriesSuccess(data) {
  return {
    type: "@faq/GET_FAQ_CATEGORIES_SUCCESS",
    payload: { data },
  };
}
