import React from "react";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div`
  width: 280px;
  .accordion-chevron {
    transition: var(--material-transition);
  }
  .dateUnlock-container {
    display: flex;
    align-items: center;
  }
  .dateUnlock {
    border: 1px solid ${({ theme }) => theme.palette.senary[theme.palette.type]};
    background-color: ${({ theme }) =>
      theme.palette.tertiary[theme.palette.type]};
    border-radius: 2px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: normal;
    color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    flex-direction: column;
    svg {
      margin-bottom: 4px;
    }
  }
  .classe-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    &:not(:last-child) {
      border-bottom: 1px solid
        ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
    }
    a {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    }
    .info {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
      color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
    }
    .dateUnlock {
      margin-right: 0;
    }
  }
  .link-book {
    margin: 5px 10px;
  }
`;

export const DisciplineProgress = styled.div`
  position: absolute;
  bottom: -14px;
  left: -16px;
  width: 126%;
  .percent {
    margin-left: ${({ percent }) => {
      const finalPercent = percent > 10 ? percent - 10 : percent;
      return `${finalPercent}%`;
    }};
    height: 14px;
    width: 25px;
    color: #5433f1;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 14px;
  }
  .bar-bg {
    display: block;
    background: ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
    height: 5px;
    position: relative;
    .bar {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      background: ${({ theme }) => theme.palette.primary[theme.palette.type]};
      height: 5px;
    }
  }
`;

export const ClassContainer = styled.div`
  padding: 0 16px;
  height: 53px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? "#F1F1F1" : "white")};
`;

export const AccordionTitle = styled.div`
  display: flex;
  width: 100%;
  min-height: 53px;
  position: relative;
  flex-direction: column;
  * {
    transition: var(--material-transition);
  }
  .module-status {
    display: flex;
    align-items: center;
  }
  .module-number {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    color: ${({ expanded, theme, type }) => {
      if (type === "module") {
        return !expanded
          ? theme.palette.text[theme.palette.type]
          : theme.palette.tertiary[theme.palette.type];
      }
      return theme.palette.text[theme.palette.type];
    }};
    margin-right: 24px;
    margin-bottom: 0;
  }
  .module-name {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    color: ${({ expanded, theme, type }) => {
      if (type === "module") {
        return !expanded
          ? theme.palette.text[theme.palette.type]
          : theme.palette.tertiary[theme.palette.type];
      }
      return theme.palette.text[theme.palette.type];
    }};

    margin-bottom: 0;
  }
  .discipline {
    margin-top: 8px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    min-width: 95px;
    max-width: 160px;
    height: 23px;
    background: ${({ expanded, theme }) =>
      !expanded ? theme.palette.senary[theme.palette.type] : "transparent"};
    border-radius: 3px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: normal;
    color: ${({ expanded, theme }) =>
      !expanded
        ? theme.palette.secondary[theme.palette.type]
        : theme.palette.tertiary[theme.palette.type]};
    .quantity {
      font-weight: 600;
    }
  }
`;

export const FirstLine = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & > div > div > span {
    color: #252525;
    font-family: Montserrat;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
  }

  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const ClassLine = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  span {
    color: #252525;
    font-family: Montserrat;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
  }

  p {
    margin: 0;
    color: #1f1f1f;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 13px;
  }
`;

export const AccordionBase = withStyles((theme) => ({
  root: {
    borderRadius: "3px",
    backgroundColor: `${theme.palette.tertiary[theme.palette.type]}`,
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    transition: "var(--material-transition)",
  },

  expanded: (props) => ({
    backgroundColor:
      props.type === "module" && theme.palette.secondary[theme.palette.type],
  }),
}))(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    borderRadius: "3px",
    border: "0px solid",
    padding: "14px 16px",
    minHeight: "53px",
    height: "auto",
    alignItems: "flex-start",
  },
  content: {
    margin: 0,
    justifyContent: "space-between",
    alignItems: "flex-start",
    "&$expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    margin: 0,
    marginLeft: "10px",
    marginRight: "-4px",
    padding: 0,
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    borderRadius: "3px",
    display: "flex",
    minHeight: "53px",
    height: "auto",
    flexDirection: "column",
    background: theme.palette.senary[theme.palette.type],
    padding: 0,
    borderTop: `1px solid
      ${theme.palette.quaternary[theme.palette.type]}`,
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: "normal",
    color: theme.palette.textTwo[theme.palette.type],
    overflow: "hidden",
  },
}))(MuiAccordionDetails);

export const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#03C2B7 !important",
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);
