const INITIAL_STATE = {};

export default function academicSuport(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@suport/GET_ACADEMIC_SUPORT_SUCCESS":
      return action.payload.data;
    default:
      return state;
  }
}
