export function getAcademicSuportRequest(id_matricula) {
  return {
    type: "@suport/GET_ACADEMIC_SUPORT_REQUEST",
    payload: { id_matricula },
  };
}

export function getAcademicSuportSuccess(data) {
  return {
    type: "@suport/GET_ACADEMIC_SUPORT_SUCCESS",
    payload: { data },
  };
}
