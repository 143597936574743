const enUS = {
  pageTitle: "FAQ",
  pageSubtitle: "Frequently asked questions",
  searchBarPlaceHolder: "Search",
  searchBarSubtitle: "Or chose one of the options bellow.",
  option: "Choose an option",
};

const ptBR = {
  pageTitle: "FAQ",
  pageSubtitle: "Perguntas frequentes",
  searchBarPlaceHolder: "Procurar",
  searchBarSubtitle: "Ou escolha uma das opções abaixo.",
  option: "Escolha uma opção",
};

export default { enUS, ptBR };
