/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  useTheme,
} from "@material-ui/core";
import ContainerMD from "@material-ui/core/Container";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import md5 from "md5";

import { Button, Loading, Select } from "~/components";
import { Title } from "~/components/Title/styles";
import {
  getCEPRequest,
  getPhoneTypeRequest,
} from "~/store/modules/phone/actions";
import {
  getProfileRequest,
  putPasswordRequest,
  putProfileRequest,
} from "~/store/modules/profile/actions";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";
import { capitalize } from "~/utils/stringUtils";

import { MainHeader } from "../Classroom/styles";
import {
  Container,
  LeftMenu,
  BtnCallback,
  UserForm,
  Input,
  GreenCheckbox,
  InputControl,
} from "./styles";

function Config() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const { phoneTypes, cep } = useSelector((state) => state.phone);
  const { userInfo } = useSelector((state) => state.auth);

  const itemTypes = phoneTypes.map((type) => {
    return {
      label: type.pes_telefone_tipo.ds_tipo,
      value: type,
    };
  });

  const [profileState, setProfileState] = useState({});
  const [phonesState, setPhonesState] = useState([]);
  const [passwordState, setPasswordState] = useState({
    password: "",
    repeatPassword: "",
    isEqual: true,
    showPassword: false,
  });

  documentTitle("Configurações");

  function goBack() {
    window.history.go(-1);
  }

  function handleProfileDataChange(event) {
    setProfileState({
      ...profileState,
      [event.target.name]: event.target.value,
    });
  }

  function handleNewPhoneChange(event, idx) {
    const newPhonesState = phonesState;
    newPhonesState[idx].pes_telefone[event.target.name] = event.target.value;
    setPhonesState([...newPhonesState]);
  }

  function handlePhoneCheckboxChange(idx) {
    const newPhonesState = phonesState;
    const isPrincipal = newPhonesState[idx].pes_telefone.tp_principal === "S";
    newPhonesState[idx].pes_telefone.tp_principal = !isPrincipal ? "S" : "N";
    setPhonesState([...newPhonesState]);
  }

  function handlePasswordChange(event) {
    setPasswordState({
      ...passwordState,
      [event.target.name]: event.target.value,
    });
  }
  function handleUpdatePassword() {
    if (!passwordState.isEqual) {
      toast.error("A senhas não conferem!");
      return;
    }
    if (
      !passwordState.password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{6,}$/
      )
    ) {
      toast.error("A senha não contem os requisitos mínimos");
      return;
    }

    dispatch(putPasswordRequest({ ds_senha: md5(passwordState.password) }));
  }

  const handleClickShowPassword = () => {
    setPasswordState({
      ...passwordState,
      showPassword: !passwordState.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleCepSearch() {
    if (profileState.nr_cep.toString().length === 8) {
      dispatch(getCEPRequest({ cep: profileState.nr_cep }));
    }
  }

  function handleSaveProfile() {
    if (!profileState.nr_endereco) {
      toast.error("Digite um número válido para o endereço!");
      return;
    }
    dispatch(putProfileRequest({ ...profileState, data: phonesState }));
  }

  useEffect(() => {
    setProfileState({
      ...profileState,
      ds_bairro: cep.bairro,
      ds_endereco: cep.logradouro,
      ds_uf: cep.uf,
    });
  }, [cep]);

  useEffect(() => {
    if (passwordState.repeatPassword) {
      setPasswordState({
        ...passwordState,
        isEqual: passwordState.repeatPassword === passwordState.password,
      });
    }
  }, [passwordState.repeatPassword, passwordState.password]);

  useEffect(() => {
    if (isEmpty(profile)) {
      dispatch(getProfileRequest());
    }
    dispatch(getPhoneTypeRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(profile)) {
      const newProfileState = {
        ds_foto: profile.pes_login.ds_foto,
        ds_foto_carteira: profile.pes_login.ds_foto_carteira,
        ds_email: profile.pes_email.ds_email,
        id_endereco: profile.pes_endereco.id_endereco,
        nr_cep: profile.pes_endereco.nr_cep,
        ds_uf: profile.opr_estado.ds_uf,
        ds_endereco: profile.pes_endereco.ds_endereco,
        nr_endereco: profile.pes_endereco.nr_endereco,
        ds_bairro: profile.pes_endereco.ds_bairro,
        ds_complemento: profile.pes_endereco.ds_complemento,
        id_cidade: profile.opr_cidade.id_cidade,
        id_estado: profile.opr_cidade.id_estado,
      };
      setProfileState(newProfileState);
      setPhonesState(profile.contato);
    }
  }, [profile]);

  return (
    <Container>
      {isEmpty(phoneTypes) || isEmpty(profile) ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <ContainerMD maxWidth="lg">
          <LeftMenu>
            <BtnCallback onClick={goBack}>
              <span>
                <MdKeyboardArrowLeft
                  size={16}
                  color={theme.palette.primary[theme.palette.type]}
                />
              </span>
              <p>Voltar</p>
            </BtnCallback>
          </LeftMenu>
          <div className="content">
            <MainHeader>
              <Title>Minha Conta</Title>
              <p>
                Olá {capitalize(userInfo.ds_nome)}. Seja bem-vindo novamente.
              </p>
            </MainHeader>
            <UserForm>
              <h3>Configurações</h3>
              <div className="form-box">
                <FormGroup>
                  <h4>Dados pessoais</h4>
                  <div className="row">
                    <FormControl fullWidth variant="outlined">
                      <InputControl
                        label="Nome"
                        variant="filled"
                        name="ds_nome"
                        value={capitalize(userInfo.ds_nome)}
                        onChange={handleProfileDataChange}
                      />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                      <InputControl
                        label="Email"
                        variant="filled"
                        name="ds_email"
                        value={profileState.ds_email}
                        onChange={handleProfileDataChange}
                        disabled
                      />
                    </FormControl>
                  </div>
                  <div className="row">
                    <Input
                      label="CEP"
                      variant="filled"
                      name="nr_cep"
                      value={profileState.nr_cep}
                      onChange={handleProfileDataChange}
                      type="number"
                      maxLength={8}
                      onBlur={handleCepSearch}
                    />
                    <Input
                      label="Rua"
                      variant="filled"
                      name="ds_endereco"
                      value={profileState.ds_endereco}
                      onChange={handleProfileDataChange}
                      disabled
                    />
                  </div>
                  <div className="row">
                    <Input
                      label="Bairro"
                      variant="filled"
                      name="ds_bairro"
                      value={profileState.ds_bairro}
                      onChange={handleProfileDataChange}
                      disabled
                    />
                    <Input
                      label="Estado"
                      variant="filled"
                      name="ds_uf"
                      value={profileState.ds_uf}
                      onChange={handleProfileDataChange}
                      disabled
                    />
                    <Input
                      label="N°"
                      variant="filled"
                      name="nr_endereco"
                      value={profileState.nr_endereco}
                      onChange={handleProfileDataChange}
                      type="number"
                    />
                  </div>
                  <div className="row">
                    <Input
                      label="Complemento"
                      variant="filled"
                      name="ds_complemento"
                      value={profileState.ds_complemento}
                      onChange={handleProfileDataChange}
                      type="text"
                    />
                  </div>
                  {phonesState.map((tel, idx) => {
                    return (
                      <div
                        key={tel.pes_telefone.id_telefone}
                        className="row contact-info"
                      >
                        <FormControl
                          className="ddd"
                          fullWidth
                          variant="outlined"
                        >
                          <InputControl
                            label="DDD"
                            variant="filled"
                            name="nr_ddd"
                            value={tel.pes_telefone.nr_ddd}
                            onChange={(event) =>
                              handleNewPhoneChange(event, idx)
                            }
                            type="number"
                            maxLength={2}
                          />
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                          <InputControl
                            label="Número"
                            variant="filled"
                            name="nr_telefone"
                            value={tel.pes_telefone.nr_telefone}
                            onChange={(event) =>
                              handleNewPhoneChange(event, idx)
                            }
                            type="number"
                            maxLength={9}
                          />
                        </FormControl>
                        <Select
                          label="Tipo"
                          items={itemTypes}
                          defaultValue={{
                            label: tel.pes_telefone_tipo.ds_tipo,
                            value: tel,
                          }}
                          onChange={(type) => {
                            const newPhonesState = phonesState;
                            newPhonesState[idx].pes_telefone_tipo = {
                              id_telefone_tipo:
                                type.value.pes_telefone_tipo.id_telefone_tipo,
                              ds_tipo: type.value.pes_telefone_tipo.ds_tipo,
                            };
                            setPhonesState([...newPhonesState]);
                          }}
                        />
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={tel.pes_telefone.tp_principal === "S"}
                              onChange={() => handlePhoneCheckboxChange(idx)}
                              name="tp_principal"
                            />
                          }
                          label="Principal"
                          labelPlacement="bottom"
                        />
                      </div>
                    );
                  })}
                  <Button className="save-button" onClick={handleSaveProfile}>
                    Salvar
                  </Button>
                </FormGroup>
                <h4 className="access-title">Acesso</h4>
                <p className="requirments">Requisitos:</p>
                <ul className="requirments-list">
                  <li>Ao menos 6 dígitos</li>
                  <li>Uma letra maiúscula</li>
                  <li>Uma letra minúscula</li>
                  <li>Um número</li>
                </ul>
                <div className="row">
                  <FormControl fullWidth variant="outlined">
                    <InputControl
                      error={!passwordState.isEqual}
                      label="Senha"
                      variant="filled"
                      name="password"
                      value={passwordState.password}
                      onChange={handlePasswordChange}
                      type={passwordState.showPassword ? "text" : "password"}
                      endadornment={
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex={-1}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {passwordState.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <InputControl
                      error={!passwordState.isEqual}
                      label="Repetir senha"
                      variant="filled"
                      name="repeatPassword"
                      value={passwordState.repeatPassword}
                      onChange={handlePasswordChange}
                      type={passwordState.showPassword ? "text" : "password"}
                      endadornment={
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex={-1}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {passwordState.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                <Button className="save-button" onClick={handleUpdatePassword}>
                  Atualizar Senha
                </Button>
              </div>
            </UserForm>
          </div>
        </ContainerMD>
      )}
    </Container>
  );
}

export default Config;
