import React from "react";
import { useIntl } from "react-intl";

import PropTypes from "prop-types";

import { Container, CardForSlide } from "./styles";

export default function CardForCarousel({
  image,
  area,
  nivel,
  title,
  duracaoHoras,
  duracaoMeses,
  ...rest
}) {
  const { formatMessage: _e } = useIntl();
  return (
    <Container>
      <CardForSlide className="cardCarousel" {...rest}>
        <div className="preview" style={{ backgroundImage: `url(${image})` }} />
        <p className="subtitulo-curso">
          {area} - {nivel}
        </p>
        <h1>{title}</h1>
        <div className="bottom">
          <div className="info-single">
            <div className="label">
              {_e({ id: "relatedCourse.durationHours" })}
            </div>
            <div className="text">{duracaoHoras}</div>
          </div>
          <div className="info-single">
            <div className="label">
              {_e({ id: "relatedCourse.durationMonths" })}
            </div>
            <div className="text">{duracaoMeses}</div>
          </div>
        </div>
      </CardForSlide>
    </Container>
  );
}

CardForCarousel.propTypes = {
  image: PropTypes.string,
  area: PropTypes.string,
  nivel: PropTypes.string,
  title: PropTypes.string,
  duracaoHoras: PropTypes.number,
  duracaoMeses: PropTypes.number,
};

CardForCarousel.defaultProps = {
  image: "",
  area: "",
  nivel: "",
  title: "",
  duracaoHoras: 0,
  duracaoMeses: 0,
};
