import React from "react";

import PropTypes from "prop-types";

import { Icon_pdf, Icon_link } from "../../images";
import { Container } from "./styles";

export default function CourseLinkDisplay({ url, file }) {
  return (
    <Container file={file}>
      <img
        src={file ? Icon_pdf : Icon_link}
        alt={file ? "Ícone PDF" : "Ícone link"}
      />

      {/* link do href provisorio */}

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={url ? `${process.env.REACT_APP_API_FILE_URL}/${url}` : file}
      >
        {file || url}
      </a>
    </Container>
  );
}

CourseLinkDisplay.propTypes = {
  url: PropTypes.string,
  file: PropTypes.string,
};

CourseLinkDisplay.defaultProps = {
  url: "/",
  file: "",
};
