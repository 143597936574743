export function getNotesRequest() {
  return {
    type: "@notes/GET_NOTES_REQUEST",
  };
}

export function getNotesSuccess(data) {
  return {
    type: "@notes/GET_NOTES_SUCCESS",
    payload: { data },
  };
}

export function getNotesFailure() {
  return {
    type: "@notes/GET_NOTES_FAILURE",
  };
}

export function getSearchNotesRequest(ds_anotacao) {
  return {
    type: "@notes/GET_SEARCH_NOTES_REQUEST",
    payload: { ds_anotacao },
  };
}
export function getSearchNotesSuccess(data) {
  return {
    type: "@notes/GET_SEARCH_NOTES_SUCCESS",
    payload: { data },
  };
}
export function getSearchNotesFailure() {
  return {
    type: "@notes/GET_SEARCH_NOTES_FAILURE",
  };
}
