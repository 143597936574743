import { createGlobalStyle } from "styled-components";

import "react-toastify/dist/ReactToastify.css";

import "./fonts.css";

export default createGlobalStyle`

  html {	
    font-family: sans-serif;	
    line-height: 1.15;	
    -webkit-text-size-adjust: 100%;	
    -webkit-tap-highlight-color: transparent;	
  }

  .Toastify__close-button {
    max-width: 20px;
  }

  * {
    outline: none;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }

  :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --horizontal-padding: 16px;
    --material-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    min-height: 100%;
    background-color: transparent;
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }

  body, input, button {
    font-size: 16px;
    width: 100%;
  }

  #root {
    display: block;
    width: 100%;
    min-height: 100vh;

    @media(min-width: 576px) {
      --horizontal-padding: 32px;
    }
    @media(min-width: 768px) {
      --horizontal-padding: 40px;
    }
    @media(min-width: 992px) {
      --horizontal-padding: 66px;
    }
    @media(min-width: 1200px) {
      --horizontal-padding: 132px;
    }
  }

  button {
    cursor: pointer;
    outline: none !important;
  }

  p {
    color: #424242;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 20px;
    a {
      text-decoration: underline;
      color: #424242;
    }
  }

  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  .container {
    margin: 0 auto;
    padding: 0 var(--horizontal-padding);
    @media(min-width: 1400px) {
      max-width: 1340px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
  }

  button, select {	
    text-transform: none;	
  }

  button, input {	
    overflow: visible;	
  }

  button, input, optgroup, select, textarea {	
    margin: 0;	
    font-family: inherit;	
    font-size: inherit;	
    line-height: inherit;	
  }

  button {	
    border-radius: 0;	
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }

`;
