import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import { ThemeProvider as MaterialProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledProvider } from "styled-components";

import { theme as customTheme } from "~/styles/themes";

import { Loading } from "./components";
import { AUTO_CLOSE } from "./constants/toast";
import Routes from "./routes";
import { themeRequest } from "./store/modules/theme/actions";
import GlobalStyle from "./styles/global";

function Provider() {
  const dispatch = useDispatch();
  const { colors } = useSelector((state) => state.theme);

  useMemo(() => dispatch(themeRequest()), [dispatch]);
  const theme = useMemo(() => customTheme(colors), [colors]);

  return (
    <>
      {!colors.primary ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <MaterialProvider theme={theme}>
            <StyledProvider theme={theme}>
              <Routes />
            </StyledProvider>
            <GlobalStyle />
          </MaterialProvider>
          <ToastContainer autoClose={AUTO_CLOSE} />
        </>
      )}
    </>
  );
}

export default Provider;
