import React from "react";
import { useIntl } from "react-intl";

import ContainerMD from "@material-ui/core/Container";

import { Footer } from "./styles";

export default function FooterTheme() {
  const date = new Date();
  const year = date.getFullYear();

  const { formatMessage: _e } = useIntl();

  /**
   * @todo textos com internacionalização mesclada em hardcode, o react Intl permite passar parametros para mensagens.
   */
  return (
    <Footer>
      <ContainerMD maxWidth="lg">
        <div className="footer-wrapper">
          <p>
            © AVA Connection {year}. {_e({ id: "footer.allRightsReserved" })}
          </p>
        </div>
      </ContainerMD>
    </Footer>
  );
}
