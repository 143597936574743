import styled from "styled-components";

import { CheckNoti } from "~/images";

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90px;
  background: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
  color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
  padding: 15px 0;
  grid-area: layoutHeader;
  .header-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .right {
    display: flex;
    width: fit-content;
    position: relative;
    .form-search {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: calc(100% - 60px);
      display: block;
      width: fit-content;
      height: fit-content;
      z-index: 10;
      pointer-events: none;
      opacity: 0;
      transition: all 0.4s ease-in-out;
      &.active {
        opacity: 1;
        pointer-events: all;
        right: calc(100% - 40px);
      }
    }
    .area-icons {
      display: flex;
      padding-right: 30px;
      position: relative;
      button {
        display: block;
        width: fit-content;
        height: fit-content;
        background: transparent;
        border: 0px solid;
        margin-left: 10px;
        position: relative;
        span.noti-number {
          position: absolute;
          right: -4px;
          top: -4px;
          color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: 800;
          font-size: 10px;
          line-height: 10px;
          width: 16px;
          height: 16px;
          background: ${({ theme }) =>
            theme.palette.primary[theme.palette.type]};
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
        }
      }
      .notifys {
        position: absolute;
        right: -130%;
        top: 100%;
        background-color: ${({ theme }) =>
          theme.palette.tertiary[theme.palette.type]};
        box-shadow: 0px 30px 35px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        width: 400px;
        box-shadow: 0px 30px 35px rgba(0, 0, 0, 0.1),
          inset 0 5px 0 0
            ${({ theme }) => theme.palette.primary[theme.palette.type]};
        pointer-events: none;
        opacity: 0;
        transition: all 0.4s ease;
        z-index: 100;
        &.active {
          top: calc(100% + 15px);
          pointer-events: all;
          opacity: 1;
        }
        .top {
          width: 100%;
          height: 80px;
          border-bottom: 1px solid
            ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
          padding: 0 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title-box {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 22px;
            color: ${({ theme }) =>
              theme.palette.secondary[theme.palette.type]};
          }
          .show-all {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            text-align: right;
            text-decoration-line: underline;
            color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
          }
        }
        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          * {
            color: ${({ theme }) => theme.palette.secondary};
          }
          li {
            margin: 0;
            padding: 0;
            display: flex;
            padding: 25px;
            border-bottom: 1px solid
              ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
            &:before {
              content: "";
              display: block;
              width: 40px;
              height: 40px;
              background-image: url(${CheckNoti});
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 15px;
            }
            .content {
              .category {
                font-family: ${({ theme }) => theme.typography.fontFamily};
                font-style: normal;
                font-weight: normal;
                font-size: 9px;
                line-height: 11px;
                letter-spacing: 0.84375px;
                text-transform: uppercase;
                color: ${({ theme }) =>
                  theme.palette.textTwo[theme.palette.type]};
              }
              .title-noti {
                font-family: ${({ theme }) => theme.typography.fontFamily};
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                color: ${({ theme }) =>
                  theme.palette.secondary[theme.palette.type]};
              }
              .time {
                font-family: ${({ theme }) => theme.typography.fontFamily};
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 18px;
                color: ${({ theme }) =>
                  theme.palette.textTwo[theme.palette.type]};
              }
            }
          }
        }
      }
    }
    .area-account {
      padding-left: 30px;
      border-left: 1px solid
        ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
      .account {
        min-width: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .content {
          cursor: pointer;
          border: 0px solid;
          background: transparent;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .user-info {
            display: flex;
            align-items: center;
          }
          .avatar {
            display: block;
            width: 25px;
            height: 25px;
            background-color: ${({ theme }) =>
              theme.palette.quaternary[theme.palette.type]};
            border-radius: 100px;
            margin-right: 10px;
            transition: all 0.4s ease;
            object-fit: cover;
          }
          .name {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
            margin-bottom: 0;
            text-transform: capitalize;
          }
          svg {
            transition: all 0.4s ease;
          }
        }
        div.account-overlay {
          opacity: 0;
          transition: all 0.4s ease;
          display: block;
          width: 100%;
          padding: 25px 25px 15px;
          background: white;
          position: absolute;
          pointer-events: none;
          left: 0;
          top: 100%;
          box-shadow: 0px 30px 35px rgba(0, 0, 0, 0.1),
            inset 0 4px 0 0
              ${({ theme }) => theme.palette.primary[theme.palette.type]};
          z-index: 100;
          ul {
            margin: 0px;
            padding: 0px;
            margin-bottom: 35px;
            list-style-type: none;
            li {
              margin: 0px;
              padding: 0px;
              &:not(:last-child) {
                margin-bottom: 14px;
              }
              a {
                font-family: ${({ theme }) => theme.typography.fontFamily};
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                color: ${({ theme }) =>
                  theme.palette.secondary[theme.palette.type]};
              }
            }
          }
          a.exit {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: lighter;
            font-size: 14px;
            color: ${({ theme }) =>
              theme.palette.secondary[theme.palette.type]};
          }
        }
        &.active {
          div.account-overlay {
            opacity: 1;
            top: calc(100% + 15px);
            pointer-events: all;
          }
          .content {
            .avatar {
              box-shadow: 0 0 0 2px
                ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
            }
          }
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`;
