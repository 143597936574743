import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import ContainerMD from "@material-ui/core/Container";
import { parseISO } from "date-fns";

import { Loading, Title, Pagination } from "~/components";
import { ROUTES } from "~/constants/routes";
import { getAnnouncementsRequest } from "~/store/modules/announcements/actions";
import { formatDate } from "~/utils/date";
import documentTitle from "~/utils/documentTitle";

import { Container, BtnCallback } from "./styles";

export default function Announcements() {
  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "announcements.title" }));

  const dispatch = useDispatch();
  const { loading, announcements, total } = useSelector(
    (state) => state.announcements
  );
  const [page, setPage] = useState(1);
  const [announcementsToDisplay, setAnnouncementsToDisplay] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    dispatch(
      getAnnouncementsRequest({
        from: 1,
        to: 50,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (page % 10 === 0) {
      dispatch(
        getAnnouncementsRequest({
          from: ((page * 2) / 10 - 1) * 50 + 1,
          to: ((page * 2) / 10) * 50,
        })
      );
    }
  }, [dispatch, page, announcementsToDisplay]);

  useEffect(() => {
    setAnnouncementsToDisplay(
      announcements.slice(page > 1 ? (page - 1) * 5 : 0, page * 5)
    );
  }, [page, announcements]);

  function goBack() {
    window.history.go(-1);
  }

  return (
    <Container>
      {loading ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <BtnCallback onClick={goBack}>
            <span>
              <MdKeyboardArrowLeft
                size={16}
                color={theme.palette.primary[theme.palette.type]}
              />
            </span>
            <p>Voltar</p>
          </BtnCallback>
          <ContainerMD maxWidth="md">
            <div className="content-wrapper">
              <div className="title-wrapper">
                <Title>{_e({ id: "announcements.title" })}</Title>
                <p>{_e({ id: "announcements.description" })}</p>
              </div>
              <div className="announcements-container">
                <ul className="ul-announcements">
                  {announcementsToDisplay.map((announcement) => (
                    <li key={String(announcement.id)}>
                      <div className="content">
                        <div className="topbar">
                          {/* <div className="category">
                            {announcement.category}
                          </div> */}
                          <Link
                            to={`${ROUTES.ANNOUNCEMENTS}/${announcement.id}`}
                            className="title-announcements"
                          >
                            {announcement.title}
                          </Link>
                          <div className="time">
                            {formatDate(parseISO(announcement.date))}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <Pagination
                  postsPerPage={5}
                  totalPosts={total}
                  setPaged={(number) => setPage(number)}
                  currentPage={page}
                />
              </div>
            </div>
          </ContainerMD>
        </>
      )}
    </Container>
  );
}
