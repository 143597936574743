import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import { getAcademicSuportSuccess } from "./actions";

function formatData(data) {
  const fortatedData = {};
  fortatedData.daysAvailable = data.horario_atendimento.map((horario) => {
    return {
      id: horario.pes_horario_atendimento_polo.id_atendimento,
      day: horario.opr_dia_semana.ds_dia,
      openTime: horario.pes_horario_atendimento_polo.ds_hora_inicial,
      closeTime: horario.pes_horario_atendimento_polo.ds_hora_final,
    };
  });

  fortatedData.nextRecesses = data.horario_recesso.map((recesso) => {
    return {
      id: recesso.pes_horario_recesso.id_recesso,
      description: recesso.pes_horario_recesso.ds_motivo,
      startDate: recesso.pes_horario_recesso.dt_inicial,
      endDate: recesso.pes_horario_recesso.dt_final,
    };
  });

  return fortatedData;
}

export function* getAcademicSuport() {
  const { id_login, id_matricula } = yield select(userInfo);
  try {
    const faqSearchResponse = yield call(api.get, API.SUPORTE_ACADEMICO, {
      id_matricula,
      id_login,
    });

    if (faqSearchResponse.data.errors.length)
      throw new Error(faqSearchResponse.data.errors[0].message);

    const academicSuportData = formatData(faqSearchResponse.data.data);

    yield put(getAcademicSuportSuccess(academicSuportData));
  } catch (err) {
    toast.error("Erro ao carregar suporte academico!");
  }
}

export default all([
  takeLatest("@suport/GET_ACADEMIC_SUPORT_REQUEST", getAcademicSuport),
]);
