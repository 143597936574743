import React from "react";
import { MdPlayArrow } from "react-icons/md";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import PropTypes from "prop-types";

import { formatWrittenDate } from "~/utils/date";

import { Container } from "./styles";

export default function MyCoursesPreview({
  image,
  category,
  title,
  url,
  startDate,
  situation,
  endDate,
  ...rest
}) {
  const theme = useTheme();

  const { formatMessage: _e } = useIntl();

  return (
    <Container situation={situation} {...rest}>
      <div className="preview" style={{ backgroundImage: `url(${image})` }}>
        {situation !== "trancamento" && situation !== "cancelado" && (
          <Link to={url}>
            <MdPlayArrow
              size={15}
              color={theme.palette.primary[theme.palette.type]}
            />
          </Link>
        )}
        {situation !== "matriculado" && (
          <span className="situation_info">{situation.toUpperCase()}</span>
        )}
      </div>

      <div className="course-preview-content">
        <div className="top">
          <div className="left">
            <div>
              <div className="category">{category}</div>
              <div className="title">{title}</div>
            </div>
          </div>
        </div>
        <div>
          <div className="time-period-container">
            <div>
              <div className="label">{_e({ id: "myCourses.courseStart" })}</div>
              <div className="text">{formatWrittenDate(startDate)}</div>
            </div>
            <div>
              <div className="label">{_e({ id: "myCourses.courseEnd" })}</div>
              <div className="text">{formatWrittenDate(endDate)}</div>
            </div>
          </div>
          <div className="bottom">
            <div className="progress-area">
              <div className="progress-mts">
                <span className="bar-bg">
                  <span className="bar" style={{ width: "80%" }} />
                </span>
                <p className="porcent">80%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

MyCoursesPreview.propTypes = {
  image: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  progress: PropTypes.number,
  url: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  situation: PropTypes.string,
};

MyCoursesPreview.defaultProps = {
  image: "",
  category: "",
  title: "",
  progress: 0,
  url: "/",
  startDate: new Date(),
  endDate: new Date(),
  situation: "",
};
