export function getModuleRequest({ id_matricula }) {
  return {
    type: "@module/GET_MODULE_REQUEST",
    payload: { id_matricula },
  };
}

export function getModuleSuccess(data) {
  return {
    type: "@module/GET_MODULE_SUCCESS",
    payload: { data },
  };
}

export function getModuleFailure(data) {
  return {
    type: "@module/GET_MODULE_FAILURE",
    payload: { data },
  };
}
export function getSubjectRequest(data) {
  return {
    type: "@module/GET_SUBJECT_REQUEST",
    payload: data,
  };
}

export function getSubjectSuccess(data) {
  return {
    type: "@module/GET_SUBJECT_SUCCESS",
    payload: { data },
  };
}

export function getSubjectFailure(data) {
  return {
    type: "@module/GET_SUBJECT_FAILURE",
    payload: { data },
  };
}
