import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import { getStatitiscsSuccess } from "./actions";

export function* getStatitiscs() {
  const { id_login } = yield select(userInfo);
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;
  try {
    const response = yield call(api.get, API.STATISTICS, {
      id_login,
      id_instituicao,
    });
    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);
    yield put(getStatitiscsSuccess(response.data.data));
  } catch (err) {
    toast.error("Erro ao carregar estatísticas!");
  }
}

export default all([
  takeLatest("@statistics/GET_STATISTICS_REQUEST", getStatitiscs),
]);
