import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import { getSearchSuccess, getSearchFailure } from "./actions";

export function* getSearch({ payload }) {
  const { search } = payload;

  const { id_login } = yield select(userInfo);

  try {
    const { coursesResponse, subjectsResponse, classesResponse } = yield all({
      coursesResponse: call(api.get, API.SEARCH_COURSE, {
        id_login,
        id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
        ds_curso: search,
      }),
      subjectsResponse: call(api.get, API.SEARCH_SUBJECT, {
        id_login,
        id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
        ds_disciplina: search,
      }),
      classesResponse: call(api.get, API.SEARCH_CLASSES, {
        id_login,
        id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
        ds_aula: search,
      }),
    });

    if (coursesResponse.data.errors.length)
      throw new Error(coursesResponse.data.errors[0].message);
    if (subjectsResponse.data.errors.length)
      throw new Error(subjectsResponse.data.errors[0].message);
    if (classesResponse.data.errors.length)
      throw new Error(classesResponse.data.errors[0].message);

    yield put(
      getSearchSuccess({
        courses: coursesResponse.data.data,
        subjects: subjectsResponse.data.data,
        classes: classesResponse.data.data,
        total:
          coursesResponse.data.data.length +
          subjectsResponse.data.data.length +
          classesResponse.data.data.length,
      })
    );
  } catch (err) {
    toast.error(err.message);
    return yield put(getSearchFailure());
  }
}

export default all([takeLatest("@search/GET_SEARCH_REQUEST", getSearch)]);
