import React from "react";

import { Slider, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  .area-loadding {
    display: block;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 60px;
    padding-right: 60px;
    padding-left: 60px;
  }
  .right-side {
    margin-left: 40px;
  }
  .MuiSlider-thumb.Mui-disabled {
    height: 18px !important;
    width: 18px !important;
    background-color: ${({ theme }) =>
      theme.palette.primary[theme.palette.type]} !important;
    margin-top: -6px !important;
    margin-left: -12px !important;
  }
  .highlighted {
    color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    font-weight: 600;
  }

  .default-plan {
    margin: 30px 0;
    width: 100%;
    height: 60px !important;
  }
  .default-plan.selected {
    border: solid 1px
      ${({ theme }) => theme.palette.quinary[theme.palette.type]};
    border-radius: 5px;
  }
  .form-label {
    font-weight: 800;
    margin-bottom: 10px;
  }
  .days-checkbox {
    border-right: 1px solid
      ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
    padding: 0 15px;
    margin: 0 0 40px;
    &:last-child {
      border-right: none;
    }
  }

  .left-bottom {
    p {
      font-size: 0.9rem;
      margin-bottom: 1.2rem;
      &:first-child {
        margin-bottom: 0.2rem;
      }
    }
    .button-group {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      flex-grow: 1;
      margin-bottom: 40px;
      button.white-button {
        background: #fff;
        color: ${({ theme }) => theme.palette.text[theme.palette.type]};
        border: 2px solid
          ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
      }
      button {
        padding: 8px 25px;
        width: 230px;
        font-size: 0.8rem;
      }
    }
  }
`;

export const Title = styled.div`
  margin-left: 45px;
  h2 {
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 20px;
  }
  p {
    font-size: 1.1rem;
    font-weight: 500;
    width: 500px;
    margin-bottom: 10rem;
    color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
    letter-spacing: 0.5px;
  }
`;

export const CurseInfo = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #efefef;
  font-size: 0.9rem;
  margin-bottom: 60px;
  .row {
    color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
    padding: 15px;
    background-color: ${({ theme }) =>
      theme.palette.background[theme.palette.type]};
    &:nth-of-type(odd) {
      background-color: ${({ theme }) =>
        theme.palette.backgroundTwo[theme.palette.type]};
    }
    &:last-child {
      padding: 20px 15px;
    }
    span.type-content {
      display: inline-block;
      width: 35%;
    }
  }
`;

export const GreenCheckbox = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: `${theme.palette.quinary[theme.palette.type]} !important`,
    },
  },

  checked: {},
}))((props) => <Checkbox {...props} />);

export const SliderHours = withStyles((theme) => ({
  root: {
    color: theme.palette.primary[theme.palette.type],
    height: 8,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "currentColor",
    marginTop: -6,
    marginLeft: -12,
    border: "2px solid #fff",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 6,
  },
  rail: {
    height: 6,
    backgroundColor: "#d1d1d1",
  },
  mark: {
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginTop: -3,
    marginLeft: -6,
    backgroundColor: "#d1d1d1",
  },
  markLabel: {
    fontWeight: 600,
  },
  markLabelActive: {
    color: theme.palette.primary[theme.palette.type],
  },
  markActive: {
    opacity: 1,
    backgroundColor: theme.palette.primary[theme.palette.type],
  },
}))(Slider);
