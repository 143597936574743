import produce from "immer";

const INITIAL_STATE = {
  phoneTypes: [],
  cep: {},
};

export default function phone(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@phone/GET_PHONE_TYPE_SUCCESS":
        draft.phoneTypes = action.payload.data;
        break;
      case "@phone/GET_CEP_SUCCESS":
        draft.cep = action.payload.data;
        break;
      default:
        return state;
    }
  });
}
