export function getStudyPlanRequest(data) {
  return {
    type: "@studyPlan/GET_STUDY_PLAN_REQUEST",
    payload: data,
  };
}

export function getStudyPlanSuccess(data) {
  return {
    type: "@studyPlan/GET_STUDY_PLAN_SUCCESS",
    payload: { data },
  };
}

export function getStudyPlanFailure(data) {
  return {
    type: "@studyPlan/GET_STUDY_PLAN_FAILURE",
    payload: { data },
  };
}

export function postStudyPlanRequest(data) {
  return {
    type: "@studyPlan/POST_STUDY_PLAN_REQUEST",
    payload: data,
  };
}

export function postStudyPlanSuccess() {
  return {
    type: "@studyPlan/POST_STUDY_PLAN_SUCCESS",
  };
}

export function postStudyPlanFailure() {
  return {
    type: "@studyPlan/POST_STUDY_PLAN_FAILURE",
  };
}

export function putStudyPlanRequest(data) {
  return {
    type: "@studyPlan/PUT_STUDY_PLAN_REQUEST",
    payload: data,
  };
}

export function putStudyPlanSuccess() {
  return {
    type: "@studyPlan/PUT_STUDY_PLAN_SUCCESS",
  };
}

export function putStudyPlanFailure() {
  return {
    type: "@studyPlan/PUT_STUDY_PLAN_FAILURE",
  };
}
