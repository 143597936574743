import React, { useRef, useState } from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useIntl } from "react-intl";
import Slider from "react-slick";

import { useTheme } from "@material-ui/core";
import PropTypes from "prop-types";

import { AvatarTwo } from "~/images";

import { Container } from "./styles";

import "slick-carousel/slick/slick.css";
import { isEmpty } from "~/utils/object";

export default function Tutors({ title, description, tutors }) {
  const sliderRef = useRef();
  const { formatMessage: _e } = useIntl();
  const theme = useTheme();

  const [active, setActive] = useState(1);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActive(next + 1),
  };

  return (
    !isEmpty(tutors) && (
      <Container>
        <div className="tutors-title-container">
          <div>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
          <div className="arrows-slick">
            <button type="button" onClick={() => sliderRef.current.slickPrev()}>
              <MdKeyboardArrowLeft
                color={theme.palette.primary[theme.palette.type]}
                size={20}
              />
            </button>
            <button type="button" onClick={() => sliderRef.current.slickNext()}>
              <MdKeyboardArrowRight
                color={theme.palette.primary[theme.palette.type]}
                size={20}
              />
            </button>
          </div>
        </div>
        <Slider {...settings} ref={sliderRef} className="slider-content">
          {tutors.map((item, index) => (
            <div key={String(index)}>
              <div className="item-single">
                <div className="left">
                  <div className="tutor-info">
                    <img src={AvatarTwo} alt="avatar" />
                    <div>
                      <div className="name">{item.pes_pessoa.ds_nome}</div>
                      <div className="formation">Matematico</div>
                    </div>
                  </div>
                  <div>
                    <div className="connect">
                      <span>{_e({ id: "course.logIn" })}</span>
                    </div>
                    <div className="redes">
                      {item.pes_configuracao.ds_url_facebook && (
                        <a
                          rel="noopener noreferrer"
                          href={item.pes_configuracao.ds_url_facebook}
                          target="_blank"
                        >
                          <FaFacebookF
                            color={theme.palette.primary[theme.palette.type]}
                            size={20}
                          />
                        </a>
                      )}
                      {item.pes_configuracao.ds_url_instagram && (
                        <a
                          rel="noopener noreferrer"
                          href={item.pes_configuracao.ds_url_instagram}
                          target="_blank"
                        >
                          <FaInstagram
                            color={theme.palette.primary[theme.palette.type]}
                            size={20}
                          />
                        </a>
                      )}
                      {item.pes_configuracao.ds_url_twitter && (
                        <a
                          rel="noopener noreferrer"
                          href={item.pes_configuracao.ds_url_twitter}
                          target="_blank"
                        >
                          <FaTwitter
                            color={theme.palette.primary[theme.palette.type]}
                            size={20}
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="right">{`"${item.pes_configuracao.ds_sobre}"`}</div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="slide-active">
          {active} / {tutors.length}
        </div>
      </Container>
    )
  );
}

Tutors.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tutors: PropTypes.array,
};

Tutors.defaultProps = {
  title: "",
  description: "",
  tutors: [{}],
};
