import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 100px;
  position: relative;
  .studies-title-container {
    display: flex;
    justify-content: space-between;
  }
  .month-title {
    text-align: center;
    width: 100%;
  }
  .slider-content {
    margin-top: 1.5rem;
  }
  h2 {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
    margin-bottom: 10px;
  }
  .arrows-slick {
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    button {
      background: #ffffff;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 3px;
      width: 45px;
      height: 45px;
      &:nth-child(1) {
        margin-right: 15px;
      }
    }
  }
  .edit-button {
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
  }
`;

export const Week = styled.div`
  height: 300px;
  background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 5px;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    border: 1px solid #efefef;
    & span:first-child {
      opacity: 0.7;
      font-weight: 500;
    }
    & span:last-child {
      font-weight: 700;
    }
  }
  div.current {
    border-bottom: 4px solid
      ${({ theme }) => theme.palette.primary[theme.palette.type]};
  }

  .body {
    border: 1px solid #efefef;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;

    .item-event {
      display: flex;
      svg {
        margin-right: 8px;
      }
      div {
        width: calc(100% - 20px);
        font-size: 12px;
        .title-event {
          font-weight: 700;
          white-space: pre;
        }
        span:last-child {
          opacity: 0.6;
        }
      }
    }
  }
`;
