export const API = Object.freeze({
  SESSIONID_GENERATE: "/sessionid/generate",
  NOTES_LIST: "/anotacoes/buscar",
  NOTES_CREATE: "/anotacoes/gravar",
  PASSWORDRESET_RECOVER: "/esqueceusenha/recuperar",
  PASSWORDRESET_REQUEST: "/esqueceusenha/solicitar",
  SEARCH_COURSE: "/busca/curso",
  SEARCH_CLASSES: "/busca/aulas",
  SEARCH_SUBJECT: "/busca/disciplina",
  SEARCH_MODULE_SUBJECT: "/busca/modulo/disciplina",
  COMMENTS_LIST: "/comentarios/buscar",
  COMMENTS_CREATE: "/comentarios/gravar",
  COMMENTS_REPLY_CREATE: "/comentarios/respostas/gravar",
  COMMENTS_FILE_DELETE: "/comentarios/deletar",
  MODULES_LIST: "/modulo/listar",
  FAVORITE_SUBJECTS_LIST: "/favoritos/listar",
  FAVORITE_SUBJECTS_CREATE: "/favoritos/gravar",
  FAVORITE_SUBJECTS_EDIT: "/favoritos/atualizar",
  NOTES_SUBJECTS_LIST: "/anotacoes/buscar",
  NOTES_SUBJECTS_CREATE: "/anotacoes/gravar",
  NOTES_SUBJECTS_EDIT: "/anotacoes/editar",
  NOTES_SUBJECTS_DELETE: "/anotacoes/deletar",
  LOGIN: "/login/logar",
  PROFILE_LIST: "/meuperfil/listar",
  PROFILE_UPDATE: "/meuperfil/atualizar",
  TEACHERS_LIST: "/professores/listar",
  PHONESTYPES_LIST: "/professores/listar",
  DASHBOARD_LIST: "/dashboard/listar",
  COURSES_LIBRARY_LIST: "/cursos/biblioteca",
  COURSES_LIST: "/cursos/buscar",
  RELATED_COURSES: "/cursos/buscar-relacionados",
  ANNOUNCEMENTS_LIST: "/comunicado/listar",
  NOTIFICATIONS_LIST: "/notificacoes/listar",
  NOTIFICATIONS_UPDATE: "/notificacoes/atualizar",
  SUBJECTS_LIST: "/disciplina/listar",
  LIKEDISLIKE_CREATE: "/likeaula/gravar",
  LIKEDISLIKE_UPDATE: "/likeaula/atualizar",
  GET_VIDEO: "/player",
  FAQ_SEARCH: "/faq/buscar",
  FAQ_CATEGORIES: "/faq/buscar-categoria",
  SUPORTE_ACADEMICO: "/suporte-academico/buscar",
  REPORTAR_COMENTARIO: "/reportarcomentario/reportar",
  MARKER_LIST: "/grifo/buscar",
  MARKER_CREATE: "/grifo/gravar",
  MARKER_DELETE: "/grifo/apagar",
  STATISTICS: "/busca-estatistica/buscar",
  STUDY_PLAN_LIST: "/planoestudo/buscar",
  STUDY_PLAN_CREATE: "/planoestudo/gravar",
  STUDY_PLAN_EDIT: "/planoestudo/atualizar",
  DOCUMENTS_LIST: "/documentos/buscar",
  MY_PROFILE_LIST: "/meuperfil/listar",
  MY_PROFILE_EDIT: "/meuperfil/atualizar",
  PHONE_TYPE_LIST: "/telefone/tipo/listar",
  CEP_LIST: "/cep/buscar",
  PASSWORD_EDIT: "/meuperfil/atualizar-senha",
});

export const ROUTES = Object.freeze({
  DEFAULT: "/",
  LOST_PASSWORD: "/solicitar-senha",
  RESET_PASSWORD: "/redefinir-senha/:token",
  DASHBOARD: "/dashboard",
  COURSE: "/curso",
  WORK_IN_PROGRESS: "/pagina-em-construcao",
  MY_COURSES: "/meus-cursos",
  COURSE_STATISTICS: "/estatisticas-de-estudo",
  TUTOR: "/fale-com-o-tutor",
  MY_MARKS: "/minhas-notas",
  FAQ: "/faq",
  MY_CERTIFICATES: "/meus-certificados",
  MY_NOTES: "/minhas-anotacoes",
  NOTIFYS: "/notificacoes",
  PROFILE: "/meu-perfil",
  CONFIG: "/configuracoes",
  FINANCIAL: "/financeiro",
  LOGIN: "/login/logar",
  CLASSROOM: "/sala-aula",
  ANNOUNCEMENTS: "/comunicados",
  LIBRARY: "/biblioteca",
  SEARCH: "/pesquisa",
  ACADEMIC_SUPORT: "/ajuda-academica",
  DOCS_SEE_ALL: "/documentos",
  STUDY_PLAN: "/plano-estudo",
});

export const PUBLIC = Object.freeze([
  "/",
  "/solicitar-senha",
  "/redefinir-senha/:token",
]);

export const ANCHOR = Object.freeze({
  ABOUT_COURSE: "#about-course",
});
