import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  .area-loadding {
    display: block;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    margin-top: 5px;
  }
  .searchBar {
    width: 100%;
  }
  .card-container {
    display: flex;
    justify-content: flex-start;
  }
  .card-container div {
    margin: 10px 15px 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .select-container {
    margin-bottom: 30px;
  }
`;
