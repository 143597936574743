/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import {
  getMarkersSuccess,
  getMarkersFailure,
  postMarkerSuccess,
  postMarkerFailure,
  deleteMarkerSuccess,
  deleteMarkerFailure,
} from "./actions";

export function* getMarkers({ payload }) {
  const { id_matricula, id_trecho } = payload;
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;

  try {
    const response = yield call(api.get, API.MARKER_LIST, {
      id_matricula,
      id_trecho,
      id_instituicao,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);
    yield put(getMarkersSuccess(response.data));
  } catch (err) {
    toast.error("Erro ao carregar grifo!");
    return yield put(getMarkersFailure());
  }
}

export function* postMarker({ payload }) {
  const { id_login } = yield select(userInfo);
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;
  const { id_matricula, id_trecho, ds_grifo } = payload;

  let sendObject = {
    id_matricula,
    id_trecho,
    ds_grifo,
    id_login,
    id_instituicao,
  };

  try {
    const response = yield call(api.post, API.MARKER_CREATE, sendObject);

    if (response.data.errors.length) throw new Error(response.data.errors);

    yield getMarkers({ payload: { id_matricula, id_trecho } });
    yield put(postMarkerSuccess(response.data));
  } catch (err) {
    toast.error(err.message);
    return yield put(postMarkerFailure());
  }
}

export function* deleteMarker({ payload }) {
  const { id_grifo, id_matricula, id_trecho } = payload;
  const { id_login } = yield select(userInfo);
  try {
    const response = yield call(
      api.delete,
      API.MARKER_DELETE,
      {},
      { data: { id_grifo, id_login } }
    );

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield getMarkers({ payload: { id_matricula, id_trecho } });
    yield put(deleteMarkerSuccess());
  } catch (err) {
    toast.error(err.message);
    return yield put(deleteMarkerFailure());
  }
}

export default all([
  takeLatest("@markers/GET_MARKERS_REQUEST", getMarkers),
  takeLatest("@markers/POST_MARKER_REQUEST", postMarker),
  takeLatest("@markers/DELETE_MARKER_REQUEST", deleteMarker),
]);
