import React, { useMemo, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Form } from "@unform/web";
import { parseISO } from "date-fns";

import { Title, Loading, Pagination, SearchBar } from "~/components";
import {
  getNotesRequest,
  getSearchNotesRequest,
} from "~/store/modules/notes/actions";
import { formatDateStandard } from "~/utils/date";
import documentTitle from "~/utils/documentTitle";

import { Container, Note, NoteInfo } from "./styles";

function MyNotes() {
  const dispatch = useDispatch();
  const { notes, loading } = useSelector((state) => state.notes);

  const { formatMessage: _e } = useIntl();

  const [page, setPage] = useState(1);
  const [notesToDisplay, setNotesToDisplay] = useState([]);
  const [notesState, setNotesState] = useState([]);

  documentTitle(_e({ id: "notes.title" }));

  function TitleMyNotes() {
    return `${_e({ id: "notes.title" })}`;
  }

  const handleSearch = (data) => {
    if (data.search.length === 0) {
      dispatch(getNotesRequest());
      return;
    }
    if (data.search.length < 3) {
      toast.error("Texto de pesquisa muito curto!");
      return;
    }
    dispatch(getSearchNotesRequest(data.search));
    setPage(1);
  };

  useMemo(() => {
    dispatch(getNotesRequest());
  }, [dispatch]);

  useMemo(() => {
    if (notes && notes.length > 0)
      setNotesState(
        notes
          .map((note) => {
            return note.data.map((nt) => (
              <Note key={String(nt.mat_anotacao.id_anotacao)}>
                <NoteInfo>
                  <p>{`${note.crs_curso.ds_curso.toLowerCase()} > ${note.crs_nivel.ds_nivel.toLowerCase()}`}</p>
                  <h3>{nt.mat_anotacao.ds_anotacao}</h3>
                  <span>
                    {formatDateStandard(parseISO(nt.mat_anotacao.dt_cadastro))}
                  </span>
                </NoteInfo>
              </Note>
            ));
          })
          .reduce((curr, note) => curr.concat(note), [])
      );
  }, [notes]);

  useEffect(() => {
    setNotesToDisplay(
      notesState.slice(page > 1 ? (page - 1) * 5 : 0, page * 5)
    );
  }, [notesState, page]);

  return (
    <Container>
      <div className="title-container">
        <div>
          <Title>
            <TitleMyNotes />
          </Title>
          {/* <p>Lorem isum dolor</p> */}
        </div>

        {/* <Button>{_e({ id: "notes.createNote" })}</Button> */}
      </div>
      <Form className={'form-search "active'} onSubmit={handleSearch}>
        <SearchBar
          placeholder={_e({ id: "faq.searchBarPlaceHolder" })}
          name="search"
          type="text"
        />
      </Form>
      {loading ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <div>{notesToDisplay}</div>
          <Pagination
            postsPerPage={5}
            totalPosts={notesState.length}
            setPaged={(number) => setPage(number)}
            currentPage={page}
          />
        </>
      )}
    </Container>
  );
}

export default MyNotes;
