import styled, { css } from "styled-components";

/**
 * @todo box-shadow com cor hardcode
 */
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
  border: 1px solid
    ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;

  .situation_info {
    position: absolute;
    right: 19px;
    border-radius: 4px;
    padding: 5px;
    top: 160px;
    background-color: ${(props) =>
      props.situation === "concluído" ? "#03c3b8" : "#F13350"};
    text-align: center;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 13px;
  }

  .preview {
    max-width: 330px;
    height: 170px;
    background-color: ${({ theme }) =>
      theme.palette.quaternary[theme.palette.type]};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    align-items: center;
    justify-content: center;
    a {
      width: 30px;
      height: 30px;
      ${(props) =>
        (props.situation === "cancelado" ||
          props.situation === "trancamento") &&
        css`
          cursor: not-allowed;
        `}
      background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
      border-radius: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }
  }
  .time-period-container {
    display: flex;
    justify-self: flex-end;
    justify-content: space-between;
    align-content: flex-end;
    & > div {
      display: flex;
    }

    .label {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.375px;
      color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
      margin-right: 4px;
    }

    .text {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 17px;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
      text-transform: capitalize;
    }
  }
  .course-preview-content {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    .top {
      display: flex;
      justify-content: space-between;
      align-self: flex-start;
      align-items: center;
    }
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .category {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 11px;
        margin-bottom: 7px;
        letter-spacing: 0.84375px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
      }
      .title {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme.palette.text[theme.palette.type]};
        text-transform: capitalize;
      }
    }
    .bottom {
      justify-self: flex-end;
      display: grid;
      grid-auto-columns: 1fr;
      grid-column-gap: 1rem;
      grid-auto-flow: column;
      padding-top: 15px;
      margin-top: 8px;
      border-top: 1px solid
        ${({ theme }) => theme.palette.quaternary[theme.palette.type]};

      .progress-area {
        margin-top: 8px;
        p {
          margin: 0;
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.4375px;
          color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
          &.porcent {
            font-weight: 800;
            font-size: 11px;
            line-height: 13px;
            color: ${(props) => {
              switch (props.situation) {
                case "concluído":
                  return "#03c3b8";
                case "trancamento":
                  return "#F13350";
                case "cancelado":
                  return "#F13350";
                default:
                  return props.theme.palette.primary[props.theme.palette.type];
              }
            }};
          }
        }
        .progress-mts {
          display: flex;
          align-items: center;
          justify-content: center;
          .bar-bg {
            display: block;
            background: ${({ theme }) =>
              theme.palette.quaternary[theme.palette.type]};
            border-radius: 5px;
            width: 207px;
            height: 5px;
            margin-right: 15px;
            position: relative;
            .bar {
              display: block;
              background: ${(props) => {
                switch (props.situation) {
                  case "concluído":
                    return "#03c3b8";
                  case "trancamento":
                    return "#F13350";
                  case "cancelado":
                    return "#F13350";
                  default:
                    return props.theme.palette.primary[
                      props.theme.palette.type
                    ];
                }
              }};
              border-radius: 5px;
              height: 5px;
            }
          }
        }
      }
    }
  }
`;
