/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import { postLikedislikeSuccess, postLikedislikeFailure } from "./actions";

export function* postLikedislike({ payload }) {
  const { id_login } = yield select(userInfo);
  const { id_matricula, id_video, id_aula, nr_like, nr_dislike } = payload;

  try {
    const response = yield call(api.post, API.LIKEDISLIKE_CREATE, {
      id_matricula,
      id_video,
      id_aula,
      nr_like,
      nr_dislike,
      id_login,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(postLikedislikeSuccess());
  } catch (err) {
    toast.error("Erro no like/dislike!");
    return yield put(postLikedislikeFailure());
  }
}

export default all([
  takeLatest("@likedislike/POST_LIKEDISLIKE_REQUEST", postLikedislike),
]);
