import React, { useMemo } from "react";
import { Router } from "react-router-dom";

import { AppProvider } from "~/config/rootProvider";
import generateSession from "~/utils/generateSession";

import Provider from "./Provider";
import history from "./services/history";

const sessionGenerator = () => generateSession();
function App() {
  useMemo(sessionGenerator, [sessionGenerator]);

  return (
    <AppProvider>
      <Router history={history}>
        <Provider />
      </Router>
    </AppProvider>
  );
}

export default App;
