import React from "react";

import ContainerMD from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import { Footer, Header, Sidebar } from "~/components";

import { Container } from "./styles";

export default function DefaultLayout({ children }) {
  return (
    <Container>
      <Header />
      <div className="layout-content">
        <ContainerMD maxWidth="lg">
          <div className="account-content">
            <Grid container spacing={3}>
              <Grid item sm={3}>
                <Sidebar />
              </Grid>
              <Grid item sm={9}>
                {children}
              </Grid>
            </Grid>
            {/* <div className="main-grid">
              <div className="sidebar-grid-area">
                <Sidebar />
              </div>
              <div className="content-grid-area">{children}</div>
            </div> */}
          </div>
        </ContainerMD>
      </div>
      <Footer />
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};
