const INITIAL_STATE = {};

export default function statistics(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@statistics/GET_STATISTICS_SUCCESS":
      return action.payload.data;
    default:
      return state;
  }
}
