/* eslint-disable react/destructuring-assignment */
import React from "react";

import { Checkbox, makeStyles, TextField, withStyles } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  overflow-x: hidden;
  margin-left: 30px;
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  .area-loadding {
    display: block;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1 {
    color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    font-size: 2rem;
    line-height: 40px;
  }
  .content {
    width: 100%;
    padding: 30px 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  h4 {
    margin-bottom: 15px;
  }

  button.save-button {
    width: 220px;
    margin-bottom: 40px;
  }
  .requirments {
    margin-bottom: 5px;
  }
  .requirments-list {
    padding-left: 25px;
    font-size: 1rem;
    margin-top: 5px;
  }
`;

export const LeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  position: absolute;
  z-index: 1;
`;

export const BtnCallback = styled.a`
  z-index: 1;
  margin-bottom: 20px;
  width: 91px;
  span {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  p {
    margin-bottom: 0;
    color: #000;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 18px;
    text-decoration: none !important;
  }
  display: flex;
  align-items: center;
  transition: opacity 0.2s ease;
  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
`;

export const UserForm = styled.div`
  .form-box {
    min-width: 870px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid
      ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
    padding: 60px 90px;
    .row {
      width: 100%;
      display: flex;
      justify-items: stretch;
      align-items: stretch;
      margin-bottom: 20px;
      max-height: 60px;
    }
    .row > div {
      margin-right: 20px;
    }
    .ddd {
      width: 20%;
      min-width: 50px;
    }
  }
`;

export const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: `2px solid ${theme.palette.quaternary[theme.palette.type]}`,
    overflow: "hidden",
    borderRadius: 5,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
  error: {
    border: `2px solid ${theme.palette.error[theme.palette.type]}`,
  },
}));

export function Input(props) {
  const classes = useStylesReddit();

  return (
    <TextField
      InputProps={{ classes, disableUnderline: true }}
      style={{ width: "60%" }}
      {...props}
      in="true"
    />
  );
}
export function InputControl(props) {
  const classes = useStylesReddit();
  return (
    <TextField
      InputProps={{
        classes,
        disableUnderline: true,
        // eslint-disable-next-line react/prop-types
        endAdornment: props.endadornment,
      }}
      style={{ width: "100%" }}
      {...props}
    />
  );
}

export const GreenCheckbox = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: `${theme.palette.quinary[theme.palette.type]} !important`,
    },
  },

  checked: {},
}))((props) => <Checkbox {...props} />);
