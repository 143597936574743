import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  search: {},
  total: 0,
};

export default function search(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@search/GET_SEARCH_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@search/GET_SEARCH_SUCCESS": {
        const { courses, subjects, classes, total } = action.payload.data;
        draft.loading = false;
        draft.search = { courses, subjects, classes };
        draft.total = total;
        break;
      }
      case "@search/GET_SEARCH_FAILURE": {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
