import styled from "styled-components";

export const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  .area-loadding {
    display: block;
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    text-align: center;
    margin-bottom: 60px;
    h2 {
      font-size: 1.9rem;
    }
  }
  .notify-content-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 60px 0 0;
  }
`;

export const BtnCallback = styled.a`
  span {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  p {
    padding-top: 15px;
  }
  position: absolute;
  left: 100px;
  top: ${({ bottom }) => (bottom ? "auto" : "70px")};
  bottom: ${({ bottom }) => (bottom ? "70px" : "auto")};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none !important;
  color: ${({ theme }) =>
    theme.palette.secondary[theme.palette.type]} !important;
  transition: opacity 0.2s ease;
  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
`;
