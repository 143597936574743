export function themeRequest() {
  return {
    type: "@theme/GET_THEME_REQUEST",
  };
}

export function themeSuccess(data) {
  return {
    type: "@theme/GET_THEME_SUCCESS",
    payload: data,
  };
}

export function themeFailure() {
  return {
    type: "@theme/GET_THEME_FAILURE",
  };
}
