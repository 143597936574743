import { all } from "redux-saga/effects";

import academicSuport from "./academicSuport/sagas";
import annotations from "./annotations/sagas";
import announcements from "./announcements/sagas";
import auth from "./auth/sagas";
import comments from "./comments/sagas";
import course from "./course/sagas";
import dashboard from "./dashboard/sagas";
import documents from "./documents/sagas";
import faq from "./faq/sagas";
import favorites from "./favorites/sagas";
import likedislikes from "./likedislike/sagas";
import marker from "./marker/sagas";
import modules from "./module/sagas";
import notes from "./notes/sagas";
import notifys from "./notifys/sagas";
import passwordReset from "./passwordReset/sagas";
import phone from "./phone/sagas";
import profile from "./profile/sagas";
import relatedCourse from "./relatedCourse/sagas";
import search from "./search/sagas";
import statistics from "./statistics/sagas";
import studyPlan from "./studyPlan/sagas";
import theme from "./theme/sagas";
import tutors from "./tutors/sagas";
import videos from "./videos/sagas";

export default function* rootSaga() {
  return yield all([
    academicSuport,
    annotations,
    announcements,
    auth,
    comments,
    course,
    dashboard,
    faq,
    favorites,
    likedislikes,
    marker,
    modules,
    notes,
    notifys,
    passwordReset,
    relatedCourse,
    search,
    statistics,
    theme,
    videos,
    tutors,
    studyPlan,
    documents,
    profile,
    phone,
  ]);
}
