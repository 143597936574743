const enUS = {
  pageTitle: "Course",
  modules: "Modules",
  modulesDisciplines: "Modules and disciplines.",
  aboutTeachers: "About teachers",
  checkSummary: "Check a summary of your activities.",
  commonQuestions: "Common questions",
  frequentlyAsked: "Frequently asked questions about the modules.",
  logIn: "Log in",
  back: "Back",
  modulesBannerPlural: "modules",
  modulesBannerSingular: "module",
  duration: "Duration",
  continue: "Continue",
  aboutCourse: "About the course",
  myCourses: "My courses",
};

const ptBR = {
  pageTitle: "Curso",
  modules: "Módulos",
  modulesDisciplines: "Módulos e disciplinas.",
  aboutTeachers: "Sobre os professores",
  checkSummary: "Confira um resumo de suas atividades.",
  studyPlan: "Planejamento de Estudo",
  studiesWeek: "Este é o seu plano de estudo para essa semana.",
  commonQuestions: "Perguntas Frequentes",
  frequentlyAsked: "Dúvidas mais frequentes sobre os módulos.",
  logIn: "Conecte-se",
  back: "Voltar",
  modulesBannerPlural: "módulos",
  modulesBannerSingular: "módulo",
  duration: "Duração",
  continue: "Continuar",
  aboutCourse: "Sobre o curso",
  myCourses: "Meus cursos",
};

export default { enUS, ptBR };
