import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import { getDocumentsSuccess } from "./actions";

export function* getDocumentsCourse(course) {
  const { id_matricula } = course.mat_matricula;
  const { id_curso } = course.crs_curso;

  try {
    const response = yield call(api.get, API.DOCUMENTS_LIST, {
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
      id_curso,
      id_matricula,
    });

    if (response.data.errors) throw new Error(response.data.errors[0].message);

    course.documents = response.data.data;
    delete course.crs_area;
    delete course.crs_nivel;
    delete course.mat_modulo;

    return course;
  } catch (err) {
    toast.error("Erro ao buscar documentos!");
  }
}

export function* getCousresWithDocuments() {
  const { id_login } = yield select(userInfo);
  try {
    const responseCourse = yield call(api.get, API.COURSES_LIST, {
      id_login,
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
    });

    if (responseCourse.data.errors.length)
      throw new Error(responseCourse.data.errors[0].message);

    const courseWithDocuments = yield all(
      responseCourse.data.data.map(getDocumentsCourse)
    );
    yield put(getDocumentsSuccess(courseWithDocuments));
  } catch (err) {
    toast.error("Erro ao carregar cursos!");
  }
}

export default all([
  takeLatest("@documents/GET_DOCUMENTS_REQUEST", getCousresWithDocuments),
]);
