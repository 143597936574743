/* eslint-disable valid-typeof */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";

import { getRelatedCourseFailure, getRelatedCourseSuccess } from "./actions";

export function* getRelatedCourse({ payload }) {
  const id_curso = payload.course_id;

  try {
    const responseCourse = yield call(api.get, API.RELATED_COURSES, {
      id_curso,
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
    });

    if (responseCourse.data.errors.length)
      throw new Error(responseCourse.data.errors[0].message);

    if (!responseCourse.data.data.curso.length) {
      yield put(getRelatedCourseSuccess([]));
      return;
    }
    const courses = responseCourse.data.data.curso.map((crs) => {
      return {
        area: crs.crs_curso.crs_area.ds_area,
        nivel: crs.crs_curso.crs_nivel.ds_nivel,
        title: crs.crs_curso.ds_curso,
        image: `${process.env.REACT_APP_S3_URL}/curso/${crs.crs_curso.ds_imagem}`,
        duracaoMeses: crs.crs_curso.nr_duracao_meses,
        duracaoHoras: crs.crs_curso.nr_duracao_horas,
      };
    });

    yield put(getRelatedCourseSuccess(courses));
  } catch (err) {
    toast.error("Erro ao carregar cursos relacionados!");
    yield put(getRelatedCourseFailure());
  }
}

export default all([
  takeLatest("@relatedCourse/GET_RELATED_COURSE_REQUEST", getRelatedCourse),
]);
