import React from "react";
import { MdClose } from "react-icons/md";

import { useTheme } from "@material-ui/core";
import PropTypes from "prop-types";

import { Container } from "./styles";

export default function News({ backgroundImage, link, onClose }) {
  const theme = useTheme();

  return (
    <Container
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      image={backgroundImage}
    >
      <div className="right">
        <button type="button" onClick={onClose}>
          <MdClose color={theme.palette.tertiary[theme.palette.type]} />
        </button>
      </div>
    </Container>
  );
}

News.propTypes = {
  link: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  backgroundImage: PropTypes.string,
};

News.defaultProps = {
  link: "/",
  backgroundImage: "",
};
