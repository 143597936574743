import styled from "styled-components";

export const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  .area-loadding {
    display: block;
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  ul.ul-announcements {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: 100px;
    * {
      color: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
    }
    li {
      margin: 0;
      padding: 0;
      display: flex;
      padding: 25px;
      ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
      .content {
        width: 100%;
        .topbar {
          display: flex;
          justify-content: center;
        }
        .user_portrait {
          width: 56px;
          height: 56px;
          background-color: #d8d8d8;
          border-radius: 50%;
        }
        /* .category {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: normal;
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.84375px;
            text-transform: uppercase;
            color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
          } */
        .author_container {
          margin-top: 13px;
          margin-bottom: 35px;
          display: flex;
        }
        .author_text {
          margin-left: 20px;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .author_text > span:nth-of-type(1n) {
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 19px;
        }
        .author_text > span:nth-of-type(2n) {
          font-size: 12px;
          font-weight: 200;
          letter-spacing: 0;
          line-height: 19px;
        }
        .text_body {
          font-size: 14px;
          line-height: 23px;
          color: #424242;
        }
      }
      .title-announcements {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 36px;
        color: ${({ theme }) => theme.palette.primary[theme.palette.text]};
      }
    }
  }
`;

export const BtnCallback = styled.a`
  span {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  p {
    padding-top: 15px;
  }
  position: absolute;
  left: 100px;
  top: ${({ bottom }) => (bottom ? "auto" : "70px")};
  bottom: ${({ bottom }) => (bottom ? "70px" : "auto")};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none !important;
  color: ${({ theme }) =>
    theme.palette.secondary[theme.palette.type]} !important;
  transition: opacity 0.2s ease;
  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
`;
