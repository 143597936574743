/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import {
  useTheme,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import ContainerMD from "@material-ui/core/Container";
import { parseISO } from "date-fns";

import { Button, Loading } from "~/components";
import { StudyPlan } from "~/images";
import {
  getStudyPlanRequest,
  putStudyPlanRequest,
  postStudyPlanRequest,
} from "~/store/modules/studyPlan/actions";
import { formatDateDot } from "~/utils/date";
import { isEmpty } from "~/utils/object";
import { capitalize } from "~/utils/stringUtils";

import {
  Container,
  Title,
  CurseInfo,
  GreenCheckbox,
  SliderHours,
} from "./styles";

function Study() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);
  const { studyPlan, course, mat_matricula } = useSelector(
    (state) => state.studyPlan
  );

  const marks = [
    {
      value: 10,
      label: "10h",
    },
    {
      value: 15,
      label: "15h",
    },
    {
      value: 20,
      label: "20h",
    },
    {
      value: 25,
      label: "25h",
    },
    {
      value: 30,
      label: "30h",
    },
  ];

  const selectedAllDays = {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  };

  const defaultStudyPlan = {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: false,
    sun: false,
    totalHours: 15,
    averageHours: 3,
  };

  const [planState, setPlanState] = useState({});

  const [isDefaultPlan, setIsDefaultPlan] = useState(false);
  const [isSelectedAllDays, setIsSelectedAllDays] = useState(false);
  const [averageHoursDisplay, setAverageHoursDisplay] = useState(0);

  const countStudyDays = () => {
    let days = 0;
    Object.keys(planState).forEach((key) => {
      return typeof planState[key] === "boolean" && planState[key]
        ? (days += 1)
        : (days += 0);
    });
    return days;
  };

  function goBack() {
    window.history.go(-1);
  }

  const handleDaysCheckboxChange = (event) => {
    setPlanState({ ...planState, [event.target.name]: event.target.checked });
  };

  const handleSelectAlldays = () => {
    if (!isSelectedAllDays) setPlanState({ ...planState, ...selectedAllDays });
    setIsSelectedAllDays(!isSelectedAllDays);
  };

  const handleSliderChange = (event, newValue) => {
    setPlanState({ ...planState, totalHours: newValue });
  };

  const handleSetDefaultPlan = () => {
    if (!isDefaultPlan) setPlanState({ id: planState.id, ...defaultStudyPlan });
    setIsDefaultPlan(!isDefaultPlan);
  };

  const handleSaveStudyPlan = () => {
    const { id_matricula } = userInfo;
    if (planState && planState.id) {
      dispatch(putStudyPlanRequest({ id_matricula, studyPlan: planState }));
    } else {
      dispatch(postStudyPlanRequest({ id_matricula, studyPlan: planState }));
    }
    goBack();
  };

  useMemo(() => {
    dispatch(getStudyPlanRequest({ id_matricula: userInfo.id_matricula }));
  }, [dispatch]);

  useMemo(() => {
    if (!isEmpty(studyPlan)) {
      setPlanState(studyPlan[0].studyDays);
    } else {
      setPlanState(defaultStudyPlan);
    }
  }, [studyPlan]);

  useMemo(() => {
    if (!isEmpty(planState)) {
      const daysSelecetedValue = countStudyDays();
      const averageHours = planState.totalHours / daysSelecetedValue;
      setIsSelectedAllDays(daysSelecetedValue === 7);
      setAverageHoursDisplay(averageHours);
    }
  }, [planState]);

  return (
    <Container>
      {isEmpty(studyPlan) ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <ContainerMD className="content" maxWidth="lg">
            <div className="left-side">
              <Title>
                <h2>Monte seu plano de estudo</h2>
                <p>
                  Monte seu plano de estudo de acordo com seus hábitos e
                  objetivos. Seja descanso nos finais de semana ou não.
                </p>
              </Title>
              <img src={StudyPlan} alt="planoestudo" width="600" />
            </div>
            <div className="right-side">
              <h4>Informações gerais</h4>

              <CurseInfo>
                <div className="row">
                  <span className="type-content">Curso:</span>
                  <span className="highlighted">
                    {capitalize(course.ds_curso)}
                  </span>
                </div>
                <div className="row">
                  <span className="type-content">Carga horária total:</span>{" "}
                  <span className="highlighted">
                    {course.nr_duracao_horas} horas
                  </span>
                </div>
                <div className="row">
                  <span className="type-content">Data de início:</span>{" "}
                  <span className="highlighted">
                    {formatDateDot(parseISO(mat_matricula.dt_inicio))}
                  </span>
                </div>
                <div className="row">
                  <span className="type-content">Data de término:</span>{" "}
                  <span className="highlighted">
                    {formatDateDot(parseISO(mat_matricula.dt_fim))}
                  </span>
                </div>
                <div className="row">
                  <IoCalendarOutline
                    color={theme.palette.primary[theme.palette.type]}
                    size={25}
                  />{" "}
                  <span className="highlighted">157 de 200 </span> dias letivos{" "}
                  <span className="highlighted">05</span> feriados.
                </div>
              </CurseInfo>

              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={isDefaultPlan}
                    onChange={handleSetDefaultPlan}
                    name="mon"
                  />
                }
                label={
                  <Typography style={{ fontSize: 18, fontWeight: 800 }}>
                    Plano de estudos recomendado
                  </Typography>
                }
                className={`default-plan ${isDefaultPlan && "selected"}`}
              />
              <FormGroup>
                <span className="form-label">
                  Quais dias gostaria de estudar?
                </span>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      disabled={isDefaultPlan}
                      checked={isSelectedAllDays}
                      onChange={handleSelectAlldays}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                      Todos os dias
                    </Typography>
                  }
                />
                <div className="group-checkbox">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={isDefaultPlan}
                        checked={planState.mon}
                        onChange={handleDaysCheckboxChange}
                        name="mon"
                      />
                    }
                    label="SEG"
                    labelPlacement="bottom"
                    className="days-checkbox"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={isDefaultPlan}
                        checked={planState.tue}
                        onChange={handleDaysCheckboxChange}
                        name="tue"
                      />
                    }
                    label="TER"
                    labelPlacement="bottom"
                    className="days-checkbox"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={isDefaultPlan}
                        checked={planState.wed}
                        onChange={handleDaysCheckboxChange}
                        name="wed"
                      />
                    }
                    label="QUA"
                    labelPlacement="bottom"
                    className="days-checkbox"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={isDefaultPlan}
                        checked={planState.thu}
                        onChange={handleDaysCheckboxChange}
                        name="thu"
                      />
                    }
                    label="QUI"
                    labelPlacement="bottom"
                    className="days-checkbox"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={isDefaultPlan}
                        checked={planState.fri}
                        onChange={handleDaysCheckboxChange}
                        name="fri"
                      />
                    }
                    label="SEX"
                    labelPlacement="bottom"
                    className="days-checkbox"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={isDefaultPlan}
                        checked={planState.sat}
                        onChange={handleDaysCheckboxChange}
                        name="sat"
                      />
                    }
                    label="SÁB"
                    labelPlacement="bottom"
                    className="days-checkbox"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={isDefaultPlan}
                        checked={planState.sun}
                        onChange={handleDaysCheckboxChange}
                        name="sun"
                      />
                    }
                    label="DOM"
                    labelPlacement="bottom"
                    className="days-checkbox"
                  />
                </div>
              </FormGroup>
              <Typography id="discrete-slider-custom" gutterBottom>
                Quantas horas por semana você quer estudar?
              </Typography>
              <SliderHours
                value={planState.totalHours}
                aria-labelledby="discrete-slider-custom"
                step={5}
                min={10}
                max={30}
                valueLabelDisplay="off"
                marks={marks}
                disabled={isDefaultPlan}
                onChange={handleSliderChange}
              />
              <div className="left-bottom">
                <p>Equivalente a média de</p>
                <p className="highlighted">
                  {averageHoursDisplay.toFixed(1)} horas por dia.
                </p>
                <div className="button-group">
                  <Button className="white-button" onClick={goBack}>
                    CANCELAR
                  </Button>
                  <Button onClick={handleSaveStudyPlan}>CONTINUAR</Button>
                </div>
              </div>
            </div>
          </ContainerMD>
        </>
      )}
    </Container>
  );
}

export default Study;
