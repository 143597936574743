const INITIAL_STATE = {};

export default function profile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@profile/GET_PROFILE_SUCCESS":
      return action.payload.data;
    default:
      return state;
  }
}
