import styled from "styled-components";

export const MenuButton = styled.button`
  height: 50px;
  width: 150px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.palette.secondary[theme.palette.type] : "#ffffff"};
  border: ${(props) => (props.selected ? 0 : "#efefef")};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
`;

export const ButtonText = styled.span`
  height: 18px;
  width: 93px;
  color: ${({ selected, theme }) =>
    selected ? "#ffffff" : theme.palette.secondary[theme.palette.type]};
  font-weight: 700;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;

  span {
    font-weight: 400;
  }
`;
