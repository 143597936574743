export function getMarkersRequest(data) {
  return {
    type: "@markers/GET_MARKERS_REQUEST",
    payload: data,
  };
}

export function getMarkersSuccess(data) {
  return {
    type: "@markers/GET_MARKERS_SUCCESS",
    payload: { data },
  };
}

export function getMarkersFailure(data) {
  return {
    type: "@markers/GET_MARKERS_FAILURE",
    payload: { data },
  };
}

export function postMarkerRequest({ id_matricula, id_trecho, ds_grifo }) {
  return {
    type: "@markers/POST_MARKER_REQUEST",
    payload: { id_matricula, id_trecho, ds_grifo },
  };
}

export function postMarkerSuccess() {
  return {
    type: "@markers/POST_MARKER_SUCCESS",
  };
}

export function postMarkerFailure(data) {
  return {
    type: "@markers/POST_MARKER_FAILURE",
    payload: { data },
  };
}

export function deleteMarkerRequest({ id_grifo, id_matricula, id_trecho }) {
  return {
    type: "@markers/DELETE_MARKER_REQUEST",
    payload: { id_grifo, id_matricula, id_trecho },
  };
}

export function deleteMarkerSuccess() {
  return {
    type: "@markers/DELETE_MARKER_SUCCESS",
  };
}

export function deleteMarkerFailure() {
  return {
    type: "@markers/DELETE_MARKER_FAILURE",
  };
}
