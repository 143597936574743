export function getTutorsRequest({ course_id }) {
  return {
    type: "@tutors/GET_TUTORS_REQUEST",
    payload: { id_curso: course_id },
  };
}

export function getTutorsSuccess(data) {
  return {
    type: "@tutors/GET_TUTORS_SUCCESS",
    payload: { data },
  };
}
