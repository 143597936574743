/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ContainerMD from "@material-ui/core/Container";
import { parseISO } from "date-fns";

import {
  BannerCourse,
  CoursePreview,
  Modules,
  Questions,
  Loading,
  Carousel,
  Tutors,
  CourseStudyPlan,
} from "~/components";
import { Course } from "~/images";
import { getCourseRequest } from "~/store/modules/course/actions";
import {
  getModuleRequest,
  getSubjectRequest,
} from "~/store/modules/module/actions";
import { getRelatedCourseRequest } from "~/store/modules/relatedCourse/actions";
import { getStudyPlanRequest } from "~/store/modules/studyPlan/actions";
import { getTutorsRequest } from "~/store/modules/tutors/actions";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";

import { Container } from "./styles";

function CoursePage() {
  const dispatch = useDispatch();

  const { course_id } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const { course } = useSelector((state) => state.course);
  const tutors = useSelector((state) => state.tutors);
  const studyPlan = useSelector((state) => state.studyPlan);
  const relatedCourse = useSelector((state) => state.relatedCourse);
  const { modules } = useSelector((state) => state.modules);

  const { id_matricula } = userInfo;

  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "course.pageTitle" }));

  const [courseState, setCourseState] = useState([]);
  const { banner, coursePreview, questions } = courseState;

  useEffect(() => {
    dispatch(getCourseRequest({ course_id }));
    dispatch(getModuleRequest({ id_matricula }));
    dispatch(getStudyPlanRequest({ id_matricula }));
    dispatch(getTutorsRequest({ course_id }));
    dispatch(getRelatedCourseRequest({ course_id }));
  }, [dispatch]);

  useEffect(() => {
    setCourseState(course);
  }, [course]);

  useEffect(() => {
    if (modules.length) {
      modules.forEach((item) => {
        if (!item.classes) dispatch(getSubjectRequest({ courseModule: item }));
      });
    }
  }, [modules]);

  return (
    <Container>
      {isEmpty(courseState) ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <div className="banner-container">
            <BannerCourse
              title={banner.title}
              modules={banner.modules}
              duration={banner.duration}
              description={banner.description}
              url={banner.url}
              image={banner.image || Course}
            />
          </div>
          <ContainerMD maxWidth="lg">
            <div className="course-preview-wrapper">
              <CoursePreview
                image={coursePreview.image}
                category={coursePreview.category}
                title={coursePreview.title}
                progress={coursePreview.progress}
                url={coursePreview.url}
                urlClass={coursePreview.urlClass}
                phasesTotal={coursePreview.phasesTotal}
                phasesComplete={coursePreview.phasesComplete}
                startDate={parseISO(coursePreview.startDate)}
                endDate={parseISO(coursePreview.endDate)}
                situation={coursePreview.situation}
                docsTotal={coursePreview.docsTotal}
                docsComplete={coursePreview.docsComplete}
                urlDocs={coursePreview.urlDocs}
                disciplineProgress={coursePreview.disciplineProgress}
                disciplineTotal={coursePreview.disciplineTotal}
                disciplineComplete={coursePreview.disciplineComplete}
              />
            </div>
            <Modules
              title={_e({ id: "course.modules" })}
              description={_e({ id: "course.modulesDisciplines" })}
              modules={modules}
            />

            <CourseStudyPlan
              title={_e({ id: "course.studyPlan" })}
              description={_e({ id: "course.studiesWeek" })}
              studyPlan={studyPlan.studyPlan}
            />

            <Tutors
              title={_e({ id: "course.aboutTeachers" })}
              description={_e({ id: "course.checkSummary" })}
              tutors={tutors}
            />

            <Questions
              title={_e({ id: "course.commonQuestions" })}
              description={_e({ id: "course.frequentlyAsked" })}
              questions={questions}
            />
            <div className="course-preview-wrapper">
              <CoursePreview
                image={coursePreview.image}
                category={coursePreview.category}
                title={coursePreview.title}
                progress={coursePreview.progress}
                url={coursePreview.url}
                urlClass={coursePreview.urlClass}
                phasesTotal={coursePreview.phasesTotal}
                phasesComplete={coursePreview.phasesComplete}
                startDate={parseISO(coursePreview.startDate)}
                endDate={parseISO(coursePreview.endDate)}
                situation={coursePreview.situation}
                docsTotal={coursePreview.docsTotal}
                docsComplete={coursePreview.docsComplete}
                urlDocs={coursePreview.urlDocs}
                disciplineProgress={coursePreview.disciplineProgress}
                disciplineTotal={coursePreview.disciplineTotal}
                disciplineComplete={coursePreview.disciplineComplete}
              />
            </div>
          </ContainerMD>
          {!isEmpty(relatedCourse) ? (
            <div className="related-course-preview">
              <Carousel relatedCourse={relatedCourse} />
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </Container>
  );
}

export default CoursePage;
