export function getRelatedCourseRequest({ course_id }) {
  return {
    type: "@relatedCourse/GET_RELATED_COURSE_REQUEST",
    payload: { course_id },
  };
}

export function getRelatedCourseSuccess(data) {
  return {
    type: "@relatedCourse/GET_RELATED_COURSE_SUCCESS",
    payload: { data },
  };
}

export function getRelatedCourseFailure(data) {
  return {
    type: "@relatedCourse/GET_RELATED_COURSE_FAILURE",
    payload: { data },
  };
}
