import React, { useEffect, useState } from "react";
import S3 from "react-aws-s3";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import PropTypes from "prop-types";

import { Photo, User } from "~/images";
import { updateProfilePhoto } from "~/store/modules/auth/actions";
import {
  getProfileRequest,
  putProfileRequest,
} from "~/store/modules/profile/actions";
import { formatFileName } from "~/utils/date";
import { isEmpty } from "~/utils/object";

import { Container } from "./styles";

export default function MyCoursesPreview({
  name,
  email,
  activeSubscriptions,
  phone,
}) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const { userInfo } = useSelector((state) => state.auth);

  const { formatMessage: _e } = useIntl();
  const urlAvatar = userInfo.ds_foto
    ? `${process.env.REACT_APP_API_FILE_URL}/config/${userInfo.ds_foto}`
    : User;

  const [profileState, setProfileState] = useState({});
  const [phonesState, setPhonesState] = useState([]);

  useEffect(() => {
    if (!isEmpty(profile)) {
      const newProfileState = {
        ds_foto: profile.pes_login.ds_foto,
        ds_foto_carteira: profile.pes_login.ds_foto_carteira,
        ds_email: profile.pes_email.ds_email,
        id_endereco: profile.pes_endereco.id_endereco,
        nr_cep: profile.pes_endereco.nr_cep,
        ds_uf: profile.opr_estado.ds_uf,
        ds_endereco: profile.pes_endereco.ds_endereco,
        nr_endereco: profile.pes_endereco.nr_endereco,
        ds_bairro: profile.pes_endereco.ds_bairro,
        ds_complemento: profile.pes_endereco.ds_complemento,
        id_cidade: profile.opr_cidade.id_cidade,
        id_estado: profile.opr_cidade.id_estado,
      };
      setProfileState(newProfileState);
      setPhonesState(profile.contato);
    }
  }, [profile]);

  useEffect(() => {
    if (isEmpty(profile)) {
      dispatch(getProfileRequest());
    }
  }, [dispatch, profile]);

  function handleSaveProfile(fileName) {
    dispatch(
      putProfileRequest({
        ...profileState,
        ds_foto: fileName,
        data: phonesState,
      })
    );
    dispatch(
      updateProfilePhoto({
        ds_foto: fileName,
      })
    );
  }

  return (
    <Container>
      <div className="profile">
        <div className="firstColumn">
          <div
            className="preview"
            style={{
              backgroundImage: `url(${urlAvatar})`,
            }}
          />
          <label htmlFor="file" className="link">
            <img className="iconPhoto" src={Photo} alt="Profile" />
            <p>{_e({ id: "myProfile.changePic" })}</p>
          </label>
          <input
            onChange={(event) => {
              const file = event.currentTarget.files[0];
              if (
                file.name.split(".")[1] !== "jpg" &&
                file.name.split(".")[1] !== "jpeg" &&
                file.name.split(".")[1] !== "png"
              ) {
                toast.error(
                  "Os formatos aceitos para Upload são: .jpg, .jpeg, .png."
                );
                return;
              }
              const fileName = `${userInfo.id_matricula}_${formatFileName(
                new Date()
              )}.${
                file.name.split(".").pop() === "jpg"
                  ? "jpeg"
                  : file.name.split(".").pop()
              }`;

              const config = {
                bucketName: "avaead-lms-dev",
                dirName: "config",
                region: "us-east-1",
                accessKeyId: process.env.REACT_APP_S3_ID,
                secretAccessKey: process.env.REACT_APP_S3_ACESSES_KEY,
                s3Url: process.env.REACT_APP_S3_URL,
              };

              const ReactS3 = new S3(config);

              ReactS3.uploadFile(
                file,
                fileName.split(".").splice(0, 1).join(".")
              )
                .then(() => {
                  handleSaveProfile(fileName);
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(err);
                  toast.error("Erro ao troca imagem de perfil!");
                });
            }}
            id="file"
            accept="image/*"
            type="file"
          />
        </div>

        <div className="course-preview-content">
          <div className="top">
            <div className="left">
              <div>
                <div className="title">{name}</div>
              </div>
            </div>
          </div>
          <div>
            <div className="bodyPerfil">
              <div className="box-body">
                <div className="label">{email}</div>
                <div className="label">{phone}</div>
              </div>
              <div className="box-body" style={{ display: "flex" }}>
                <div>
                  <div className="label">Você tem:</div>
                  <div className="text">
                    {activeSubscriptions}{" "}
                    {`${
                      activeSubscriptions !== 1
                        ? "assinaturas ativas"
                        : "assinatura ativa"
                    }`}
                  </div>
                </div>
                <Link
                  style={{ cursor: "not-allowed" }}
                  to="/meu-perfil"
                  className="manage-enrollment"
                >
                  {_e({ id: "myProfile.manageEnrollments" })}
                </Link>
              </div>
            </div>
            <div className="bottom">
              <div className="progress-area">
                <p>{_e({ id: "coursePreview.progress" })}</p>
                <div className="progress-mts">
                  <span className="bar-bg">
                    <span className="bar" style={{ width: "80%" }} />
                  </span>
                  <p className="porcent">80%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

MyCoursesPreview.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  activeSubscriptions: PropTypes.number,
};

MyCoursesPreview.defaultProps = {
  activeSubscriptions: 1,
};
