import { Link } from "react-router-dom";

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) =>
    theme.palette.backgroundTwo[theme.palette.type]};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ background }) => background});
  .banner-content {
    width: 100%;
    min-height: 800px;
    padding: 50px 100px;
    display: flex;
    align-items: center;
    position: relative;
    backdrop-filter: blur(5px) brightness(70%);
  }
  h1 {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
    color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    margin-bottom: 20px;
  }

  p {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    margin: 0;
    &.label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .content {
    max-width: 33vw;

    .title {
      text-transform: capitalize;
    }

    .avaliation-wrapper {
      display: flex;
      margin-bottom: 1rem;
      align-items: center;

      .avaliation {
        margin-right: 1.5rem;
      }
    }

    .course-info {
      display: flex;
      margin-bottom: 1.5rem;
      .modules {
        margin-right: 1.5rem;
      }
    }

    .description {
      margin-bottom: 3rem;
    }
  }
`;

/**
 * @todo cores definidas em hard code
 */
export const BtnCallback = styled(Link)`
  top: ${({ bottom }) => (bottom ? "auto" : "70px")};
  bottom: ${({ bottom }) => (bottom ? "70px" : "auto")};
  display: flex;
  align-items: center;
  transition: opacity 0.2s ease;
  position: absolute;
  left: 100px;
  color: ${({ theme }) => theme.palette.tertiary} !important;
  span {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  p {
    margin-bottom: 0;
    color: #ffffff;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 18px;
    text-decoration: none !important;
  }
  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
`;
