const enUS = {
  pageTitle: "Academic Suport",
  openingHours: "Opening hours",
  schedule: "Schedules",
};

const ptBR = {
  pageTitle: "Suporte Acadêmico",
  openingHours: "Horários de atendimento",
  recesses: "Recessos",
  schedule: "Horários",
};

export default { enUS, ptBR };
