export function getSearchRequest(search) {
  return {
    type: "@search/GET_SEARCH_REQUEST",
    payload: { search },
  };
}

export function getSearchSuccess(data) {
  return {
    type: "@search/GET_SEARCH_SUCCESS",
    payload: { data },
  };
}

export function getSearchFailure(data) {
  return {
    type: "@search/GET_SEARCH_FAILURE",
    payload: { data },
  };
}

export function searchCourseRequest(ds_curso) {
  return {
    type: "@search/SEARCH_COURSE_REQUEST",
    payload: { ds_curso },
  };
}

export function searchCourseSuccess(data) {
  return {
    type: "@search/SEARCH_COURSE_SUCCESS",
    payload: { data },
  };
}

export function searchCourseFailure(data) {
  return {
    type: "@search/SEARCH_COURSE_FAILURE",
    payload: { data },
  };
}

export function searchCourseSubjectsRequest({ id_matricula }) {
  return {
    type: "@search/SEARCH_COURSE_SUBJECTS_REQUEST",
    payload: { id_matricula },
  };
}

export function searchCourseSubjectsSuccess(data) {
  return {
    type: "@search/SEARCH_COURSE_SUBJECTS_SUCCESS",
    payload: { data },
  };
}

export function searchCourseSubjectsFailure(data) {
  return {
    type: "@search/SEARCH_COURSE_SUBJECTS_FAILURE",
    payload: { data },
  };
}

export function searchModuleSubjectsRequest({
  id_modulo,
  id_grade,
  id_matricula,
}) {
  return {
    type: "@search/SEARCH_MODULE_SUBJECTS_REQUEST",
    payload: { id_modulo, id_grade, id_matricula },
  };
}

export function searchModuleSubjectsSuccess(data) {
  return {
    type: "@search/SEARCH_MODULE_SUBJECTS_SUCCESS",
    payload: { data },
  };
}

export function searchModuleSubjectsFailure(data) {
  return {
    type: "@search/SEARCH_MODULE_SUBJECTS_FAILURE",
    payload: { data },
  };
}
