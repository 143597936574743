export function getCommentsRequest({ id_aula, id_curso }) {
  return {
    type: "@comments/GET_COMMENTS_REQUEST",
    payload: { id_aula, id_curso },
  };
}

export function getCommentsSuccess(data) {
  return {
    type: "@comments/GET_COMMENTS_SUCCESS",
    payload: { data },
  };
}

export function getCommentsFailure(data) {
  return {
    type: "@comments/GET_COMMENTS_FAILURE",
    payload: { data },
  };
}

export function postCommentRequest({ id_aula, id_curso, ds_comentario, data }) {
  return {
    type: "@comments/POST_COMMENT_REQUEST",
    payload: { id_aula, id_curso, ds_comentario, data },
  };
}

export function postCommentSuccess() {
  return {
    type: "@comments/POST_COMMENT_SUCCESS",
  };
}

export function postCommentFailure(data) {
  return {
    type: "@comments/POST_COMMENT_FAILURE",
    payload: { data },
  };
}

export function postReportCommentRequest({ id_reporte_motivo, id_comentario }) {
  return {
    type: "@comments/POST_REPORT_COMMENT_REQUEST",
    payload: { id_reporte_motivo, id_comentario },
  };
}

export function postReportCommentSuccess() {
  return {
    type: "@comments/POST_REPORT_COMMENT_SUCCESS",
  };
}

export function postReportCommentFailure(data) {
  return {
    type: "@comments/POST_REPORT_COMMENT_FAILURE",
    payload: { data },
  };
}

export function deleteFileRequest({ id_arquivo }) {
  return {
    type: "@comments/DELETE_FILE_REQUEST",
    payload: { id_arquivo },
  };
}

export function deleteFileSuccess() {
  return {
    type: "@comments/DELETE_FILE_SUCCESS",
  };
}

export function deleteFileFailure() {
  return {
    type: "@comments/DELETE_FILE_FAILURE",
  };
}

export function postCommentReplyRequest({
  ds_resposta,
  id_comentario,
  id_aula,
  id_curso,
}) {
  return {
    type: "@comments/POST_COMMENT_REPLY_REQUEST",
    payload: { ds_resposta, id_comentario, id_aula, id_curso },
  };
}

export function postCommentReplySuccess() {
  return {
    type: "@comments/POST_COMMENT_REPLY_SUCCESS",
  };
}

export function postCommentReplyFailure() {
  return {
    type: "@comments/POST_COMMENT_REPLY_FAILURE",
  };
}
