const enUS = {
  requiredField: "Required field!",
  passwordsDoNotMatch: "Passwords do not match!",
  passwordTooSimple:
    "Password must contain at least 6 characters, one lowercase, one uppercase and one number",
};

const ptBR = {
  requiredField: "Campo obrigatório!",
  passwordsDoNotMatch: "Senhas não conferem!",
  passwordTooSimple:
    "A senha deve conter pelo menos 6 caracteres, uma minúscula, uma maiúscula e um número",
};

export default { enUS, ptBR };
