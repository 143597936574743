import React, { useState } from "react";
import { BsDownload, BsUpload } from "react-icons/bs";
import { MdKeyboardArrowDown, MdSearch } from "react-icons/md";

import { useTheme } from "@material-ui/core";
import PropTypes from "prop-types";

import {
  Container,
  AccordionBase,
  AccordionSummary,
  AccordionDetails,
} from "./styles";

export default function AccordionDocuments({ items, ...rest }) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container>
      {items.map(
        (item, index) =>
          item && (
            <AccordionBase
              key={item.crs_curso.id_curso}
              square
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              {...rest}
            >
              <AccordionSummary
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-header`}
                expandIcon={
                  <MdKeyboardArrowDown
                    color={theme.palette.quinary[theme.palette.type]}
                    size={40}
                  />
                }
              >
                <div className="accordion-title">
                  <h4>{item.crs_curso.ds_curso}</h4>
                </div>
                <span className="registration-info">
                  <p>Matrícula</p>
                  <span>{item.mat_matricula.id_matricula}</span>
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <table>
                  <tbody>
                    <tr>
                      <th>Documento</th>
                      <th>Formato</th>
                      <th>Situação</th>
                      <th>Classificação</th>
                      <th>Ação</th>
                    </tr>
                    {item.documents.map((doc) => {
                      return (
                        doc && (
                          <>
                            <tr>
                              <td>{doc.crs_documento.ds_tipo}</td>
                              <td>{doc.crs_documento_formato.ds_formato}</td>
                              <td>{doc.mat_documento_situacao.ds_situacao}</td>
                              <td>
                                {
                                  doc.crs_documento_classificacao
                                    .ds_classificacao
                                }
                              </td>
                              <td className="button-group">
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={`${process.env.REACT_APP_API_FILE_URL}/documentos/${doc.mat_documento.ds_arquivo}`}
                                  download
                                >
                                  <BsDownload
                                    size={25}
                                    color={
                                      theme.palette.primary[theme.palette.type]
                                    }
                                  />
                                </a>
                                <button type="button">
                                  <BsUpload
                                    size={25}
                                    color={
                                      theme.palette.primary[theme.palette.type]
                                    }
                                  />
                                </button>
                                <button type="button">
                                  <MdSearch
                                    color={
                                      theme.palette.primary[theme.palette.type]
                                    }
                                    size={25}
                                  />
                                </button>
                              </td>
                            </tr>
                            <tr className="instructions">
                              <td colSpan="5">
                                <div>Instruções:</div>
                                {doc.crs_documento_instrucao.ds_instrucao}
                              </td>
                            </tr>
                          </>
                        )
                      );
                    })}
                  </tbody>
                </table>
              </AccordionDetails>
            </AccordionBase>
          )
      )}
    </Container>
  );
}

AccordionDocuments.propTypes = {
  items: PropTypes.array.isRequired,
};
