const INITIAL_STATE = [];

export default function tutors(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@tutors/GET_TUTORS_SUCCESS":
      return action.payload.data;
    default:
      return state;
  }
}
