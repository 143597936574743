import React from "react";

import { Container } from "./styles";

export default function NoVideo() {
  return (
    <Container>
      <h2>Esta aula não possui vídeo</h2>
      <p>Leia o texto abaixo...</p>
    </Container>
  );
}
