import produce from "immer";

const INITIAL_STATE = {
  categories: [],
  questions: [],
  isLoading: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@faq/GET_FAQ_SEARCH_REQUEST":
        draft.isLoading = true;
        break;
      case "@faq/GET_FAQ_SEARCH_SUCCESS":
        draft.questions = action.payload.data;
        draft.isLoading = false;
        break;
      case "@faq/GET_FAQ_SEARCH_FAILURE":
        draft.isLoading = false;
        break;
      case "@faq/GET_FAQ_QUESTION_REQUEST":
        draft.isLoading = true;
        break;
      case "@faq/GET_FAQ_QUESTION_SUCCESS":
        draft.questions = action.payload.data;
        draft.isLoading = false;
        break;
      case "@faq/GET_FAQ_CATEGORIES_SUCCESS":
        draft.categories = action.payload.data;
        draft.isLoading = false;
        break;
      default:
        return state;
    }
  });
}
