import { toast } from "react-toastify";

import Moment from "moment";
import { extendMoment } from "moment-range";
import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API, ROUTES } from "~/constants/routes";
import api, { fakeApi } from "~/services/api";
import { userInfo } from "~/store/selectors";
import { formatDateLine, weekDays, isDateEquals } from "~/utils/date";

import { getDashboardSuccess } from "./actions";

const moment = extendMoment(Moment);

function removeDuplicateEvents(calendar, recesses) {
  calendar.forEach((item) => {
    recesses.forEach((recess, idx) => {
      if (isDateEquals(new Date(item.date), new Date(recess.date))) {
        item.events = item.events.concat(recess.events);
        recesses.splice(idx, 1);
      }
    });
  });
  return calendar.concat(recesses);
}

function createStudyPlaneEvent(day, response) {
  const avergeHours = response.mat_plano_estudo.nr_horas_media;
  return {
    date: formatDateLine(day),
    events: [
      {
        title: response.crs_curso.ds_curso,
        description: `${avergeHours} horas de estudo.`,
      },
    ],
  };
}

function createRecessEvents(recessTime) {
  const range = moment.range(recessTime.dt_inicial, recessTime.dt_final);
  return Array.from(range.by("day")).map((item) => {
    return {
      date: formatDateLine(new Date(item)),
      events: [
        {
          title: "Recesso",
          description: recessTime.ds_motivo,
        },
      ],
    };
  });
}

function formatCalendar(stydyResponse, recessResponse) {
  const week = weekDays(new Date());
  const weekCalendar = [];
  let recessCalendar = [];
  const { horario_recesso } = recessResponse;
  horario_recesso.forEach(
    (time) =>
      (recessCalendar = recessCalendar.concat(
        createRecessEvents(time.pes_horario_recesso)
      ))
  );
  Object.keys(stydyResponse.mat_plano_estudo).forEach((item) => {
    if (
      typeof stydyResponse.mat_plano_estudo[item] === "boolean" &&
      stydyResponse.mat_plano_estudo[item]
    ) {
      switch (item) {
        case "tp_segunda":
          weekCalendar.push(createStudyPlaneEvent(week[0], stydyResponse));
          break;
        case "tp_terca":
          weekCalendar.push(createStudyPlaneEvent(week[1], stydyResponse));
          break;
        case "tp_quarta":
          weekCalendar.push(createStudyPlaneEvent(week[2], stydyResponse));
          break;
        case "tp_quinta":
          weekCalendar.push(createStudyPlaneEvent(week[3], stydyResponse));
          break;
        case "tp_sexta":
          weekCalendar.push(createStudyPlaneEvent(week[4], stydyResponse));
          break;
        case "tp_sabado":
          weekCalendar.push(createStudyPlaneEvent(week[5], stydyResponse));
          break;
        case "tp_domingo":
          weekCalendar.push(createStudyPlaneEvent(week[6], stydyResponse));
          break;
        default:
          break;
      }
    }
  });

  return removeDuplicateEvents(weekCalendar, recessCalendar);
}

export function* getDashboard() {
  const { id_login, id_matricula } = yield select(userInfo);
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;

  try {
    const response = yield call(fakeApi.get, "dashboard");
    const dashboardResponse = yield call(api.get, API.DASHBOARD_LIST, {
      id_login,
      id_instituicao,
    });

    const studyPlanResponse = yield call(api.get, API.STUDY_PLAN_LIST, {
      id_matricula,
      id_instituicao,
    });
    const recessResponse = yield call(api.get, API.SUPORTE_ACADEMICO, {
      id_matricula,
      id_login,
    });

    const {
      crs_disciplina,
      crs_area,
      mat_situacao,
      mat_matricula,
      mat_documento,
      crs_curso,
      crs_documento_instrucao,
      mensagem_banner,
    } = dashboardResponse.data.data[0];

    const coursePreview = {
      category: crs_area.ds_area.toLowerCase(),
      title: crs_curso.ds_curso.toLowerCase(),
      image: `${process.env.REACT_APP_S3_URL}/curso/${crs_curso.ds_imagem_divulgacao}`,
      progress: crs_curso.progresso_curso,
      url: `${ROUTES.COURSE}/${crs_curso.ds_curso.toLowerCase()}/${
        crs_curso.id_curso
      }?id_mat=${mat_matricula.id_matricula}`,
      urlClass: `${ROUTES.CLASSROOM}/${crs_curso.id_curso}/40/aulas/2 `,
      phasesTotal: 0,
      phasesComplete: 0,
      startDate: mat_matricula.dt_inicio,
      endDate: mat_matricula.dt_fim,
      situation: mat_situacao.ds_situacao.toLowerCase(),
      docsTotal: crs_documento_instrucao.qtde,
      docsComplete: mat_documento.qtde,
      urlDocs: `${ROUTES.COURSE}/${crs_curso.ds_curso.toLowerCase()}/${
        mat_matricula.id_matricula
      }/biblioteca`,
      disciplineProgress: crs_disciplina.progresso_disciplinas,
      disciplineTotal: crs_disciplina.qtde,
      disciplineComplete: crs_disciplina.qtde_concluido,
    };

    response.data = response.data ? response.data : {};
    response.data.calendar = formatCalendar(
      studyPlanResponse.data.data,
      recessResponse.data.data
    );

    response.data.coursePreview = coursePreview;
    response.data.banner = mensagem_banner[0]
      ? mensagem_banner[0].crs_mensagem_banner
      : undefined;

    yield put(getDashboardSuccess(response.data));
  } catch (err) {
    toast.error("Erro ao carregar dashboard!");
  }
}

export default all([
  takeLatest("@dashboard/GET_DASHBOARD_REQUEST", getDashboard),
]);
