/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@material-ui/core";
import ContainerMD from "@material-ui/core/Container";
import { Form } from "@unform/web";

import { Loading, Title, Pagination, SearchBar, Select } from "~/components";
import { ROUTES } from "~/constants/routes";
import { getSearchRequest } from "~/store/modules/search/actions";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";
import { mostRecentOrder, oldestOrder, az, za } from "~/utils/orderFunctions";

import SearchResult from "../../components/SearchResult";
import { Container, BtnCallback } from "./styles";

export default function Search() {
  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "search.title" }));

  const dispatch = useDispatch();
  const { search, loading } = useSelector((state) => state.search);
  const [searchState, setSearchState] = useState([]);
  const [searchDisplay, setSearchDisplay] = useState([]);
  const [searchTotal, setSearchTotal] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [orderFilter, setOrderFilter] = useState("mostRecent");

  const [page, setPage] = useState(1);

  useMemo(() => {
    if (!isEmpty(search)) {
      const searchCourses = search.courses.map((src) => (
        <SearchResult
          id={String(`${src.crs_curso.id_curso}c`)}
          categoryText={src.crs_nivel.ds_nivel}
          category="myCourses"
          title={src.crs_curso.ds_curso}
          date={src.mat_matricula.dt_inicio}
        />
      ));

      const searchDiciplines = search.subjects.map((src) => (
        <SearchResult
          id={String(`${src.crs_disciplina.id_disciplina}s`)}
          categoryText={src.crs_curso.ds_curso}
          category="subjects"
          title={src.crs_disciplina.ds_disciplina}
          date={src.mat_matricula.dt_inicio}
        />
      ));

      const searchClasses = search.classes.map((src) => (
        <SearchResult
          id={String(`${src.crs_aula.id_aula}s`)}
          categoryText={src.crs_curso.ds_curso}
          category="classes"
          title={src.crs_aula.ds_aula}
        />
      ));
      setSearchState(
        [...searchCourses, ...searchDiciplines, ...searchClasses].sort(
          mostRecentOrder
        )
      );
      setSearchTotal(
        [...searchCourses, ...searchDiciplines, ...searchClasses].length
      );
      setSearchDisplay(
        [...searchCourses, ...searchDiciplines, ...searchClasses]
          .slice(0, 5)
          .sort(mostRecentOrder)
      );
    }
  }, [search]);

  useEffect(() => {
    let newOrderState = searchState;

    switch (orderFilter) {
      case "mostRecent":
        newOrderState = searchState.sort(mostRecentOrder);
        break;
      case "oldest":
        newOrderState = searchState.sort(oldestOrder);
        break;
      case "az":
        newOrderState = searchState.sort(az);
        break;
      case "za":
        newOrderState = searchState.sort(za);
        break;
      default:
    }

    if (categoryFilter !== "all") {
      const newState = newOrderState.filter(
        (src) => src.props.category === categoryFilter
      );
      setSearchTotal(newState.length);
      setSearchDisplay(newState.slice(page > 1 ? (page - 1) * 5 : 0, page * 5));
    } else {
      setSearchTotal(searchState.length);
      setSearchDisplay(
        newOrderState.slice(page > 1 ? (page - 1) * 5 : 0, page * 5)
      );
    }
  }, [categoryFilter, page, orderFilter]);

  const categoryFilters = [
    { value: "all", label: "Todas" },
    { value: "subjects", label: "Disciplinas" },
    { value: "myCourses", label: "Meus cursos" },
    { value: "classes", label: "Aulas" },
    // { value: "savedItens", label: "Itens salvos" },
    // { value: "myNotes", label: "Minhas anotações" },
    // { value: "exams", label: "Provas" },
    // { value: "forum", label: "Forum" },
  ];

  const orderByFilters = [
    { value: "mostRecent", label: "Mais recente" },
    { value: "oldest", label: "Mais antigos" },
    { value: "az", label: "A - Z" },
    { value: "za", label: "Z - A" },
  ];

  const theme = useTheme();

  return (
    <Container>
      {loading ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <BtnCallback to={ROUTES.DASHBOARD}>
            <span>
              <MdKeyboardArrowLeft
                size={16}
                color={theme.palette.primary[theme.palette.type]}
              />
            </span>
            Voltar
          </BtnCallback>
          <ContainerMD maxWidth="md">
            <div className="content-wrapper">
              <div className="title-wrapper">
                <Title>{_e({ id: "search.title" })}</Title>
              </div>
              <Form
                className={'form-search "active'}
                onSubmit={(data) => {
                  dispatch(getSearchRequest(data.search));
                }}
              >
                <SearchBar
                  placeholder={_e({ id: "header.searchHere" })}
                  name="search"
                  type="text"
                />
              </Form>
              <div className="search-container">
                <div className="search-options">
                  <div className="total-results">
                    {_e({ id: "search.searchReturned" })}
                    <span className="total">{`${searchTotal} ${
                      searchTotal === 1
                        ? _e({
                            id: "search.result",
                          })
                        : _e({
                            id: "search.results",
                          })
                    }`}</span>
                  </div>
                  <div className="filters">
                    <Select
                      className="select-filter"
                      label={_e({ id: "search.searchFilterCategory" })}
                      items={categoryFilters}
                      onChange={(item) => {
                        setPage(1);
                        setCategoryFilter(item.value);
                      }}
                    />
                    <Select
                      className="select-filter"
                      label={_e({ id: "search.searchFilterOrderBy" })}
                      items={orderByFilters}
                      onChange={(item) => {
                        setPage(1);
                        setOrderFilter(item.value);
                      }}
                    />
                  </div>
                </div>
                <ul className="ul-search-results">
                  {searchDisplay && searchDisplay}
                </ul>
                <Pagination
                  postsPerPage={5}
                  totalPosts={searchTotal}
                  setPaged={(number) => setPage(number)}
                  currentPage={page}
                />
              </div>
            </div>
          </ContainerMD>
        </>
      )}
    </Container>
  );
}
