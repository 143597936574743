import styled from "styled-components";

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #ececec;
  padding: 15px 20px 30px;
  width: 60%;
  .area-loadding {
    display: block;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title-container {
    margin: 15px 0 30px;
  }
  .recesses {
    width: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    color: ${({ theme }) => theme.palette.quinary[theme.palette.type]};
  }
`;
