import styled, { css } from "styled-components";

export const Container = styled.div`
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  .area-loadding {
    display: block;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
`;

export const NewComment = styled.div`
  border: 1px solid #ececec;
  padding-left: 31px;
  padding-top: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-height: 240px;
  width: 807px;
  background-color: white;

  span.coment-error {
    ${({ error }) =>
      error &&
      css`
        visibility: hidden;
      `};
    height: 40px;
    max-width: 748px;
    margin-bottom: 5px;
    margin-top: -23px;
    color: #fff;
    background-color: ${({ theme }) => theme.palette.error[theme.palette.type]};
    display: flex;
    align-items: flex-end;
    padding: 5px;
    border-radius: 0 0 5px 5px;
    border-bottom: 5px solid #ececec;
  }

  & > div {
    display: flex;
    flex-direction: column;
  }

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 24px;
    display: block;
    background-color: ${({ theme }) =>
      theme.palette.quaternary[theme.palette.type]};
    border-radius: 50%;
    transition: all 0.4s ease;
    object-fit: cover;
  }

  input {
    width: 748px;
    border: none;
    border-bottom: 1px solid #979797;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 23px;
  }
`;

export const NewCommentButtons = styled.div`
  padding-right: 31px;

  button:first-of-type {
    box-sizing: border-box;
    height: 40px;
    width: 110px;
    border: 2px solid #e6edfb;
    border-radius: 3px;
    background-color: #ffffff;

    & > * {
      height: 13px;
      width: 59px;
      color: #9a86f7;
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 13px;
      text-align: center;
    }
  }

  button:last-of-type {
    margin-left: 22px;
    height: 40px;
    width: 163px;
    border-radius: 5px;
    border: none;
    background-color: #9a86f7;

    & > * {
      height: 15px;
      width: 75px;
      color: #ffffff;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
    }
  }
`;

export const NewCommentOptions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AddFiles = styled.div`
  input[type="file"] {
    display: none;
  }

  label {
    cursor: pointer;
    height: 15px;
    background: none;
    width: 100px;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0;
    padding: 0;
    border: none;
    line-height: 15px;
    color: #5433f1;
    text-decoration: underline;
  }
`;

export const CommentsContainer = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CommentsHeader = styled.div`
  width: 807px;
  justify-self: flex-start;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin-bottom: 50px;
`;

export const VideoTools = styled.div`
  height: 61px;
  display: flex;
  z-index: 1;
`;

export const VideoToolsText = styled.div`
  display: flex;
  align-items: center;
  width: 635px;
  background-color: #252525;
  z-index: 1;

  img {
    height: 25px;
    width: 26.27px;
    margin-left: 21px;
    margin-right: 15px;
    z-index: 1;
    border-radius: 50%;
  }

  p {
    margin-bottom: 0;
    opacity: 0.67;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 25px;
    z-index: 1;
  }
`;

export const Video = styled.div`
  height: 544.66px;
  width: 817px;
  z-index: 1;
  button {
    margin-top: 30%;
    margin-left: 42.5%;
  }
`;

export const IconContainer = styled.div`
  width: 182px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: black;
  z-index: 1;

  p {
    margin: 0;
    margin-left: 5px;
    opacity: 0.85;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
  }

  div {
    width: 43px;
    display: flex;
    align-items: baseline;
  }
`;

export const ButtonStar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: none;
  margin: 0;
  margin-left: 5px;
  border: none;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ButtonLike = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: none;
  margin: 0;
  margin-left: 5px;
  border: none;

  img {
    ${({ liked }) => {
      if (!liked) {
        return css`
          transform: scaleX(-1) scaleY(-1);
        `;
      }
    }}

    width: 20px;
    height: 20px;
  }
`;

export const ButtonDislike = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: none;
  margin: 0;
  margin-left: 5px;
  border: none;

  img {
    ${({ disliked }) => {
      if (disliked) {
        return css`
          transform: scaleX(-1) scaleY(-1);
        `;
      }
    }}

    width: 20px;
    height: 20px;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 90px;
  z-index: 0;
  width: 100%;
  height: 1058px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ background }) => background});
  filter: brightness(70%);

  div {
    width: 100%;
    min-height: 1058px;
    backdrop-filter: blur(5px);
  }
`;

export const ClassTools = styled.div`
  z-index: 1;
  margin-left: 25px;
  margin-top: 324px;
  display: flex;

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  p {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: right;
  }

  div {
    display: flex;
    flex-direction: column;
    height: 100px;
  }

  div:first-of-type {
    justify-content: space-evenly;
    width: 65px;
  }

  div:last-of-type {
    margin-left: 15px;
    justify-content: space-evenly;
    align-items: center;
    width: 50px;
    border: 1px solid #efefef;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  img:first-of-type {
    height: 20px;
    width: 17.92px;
  }

  img:nth-of-type(2n) {
    height: 17.18px;
    width: 20px;
  }

  img:last-of-type {
    height: 20px;
    width: 18.39px;
  }
`;

export const Files = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 31px;
  margin-bottom: 20px;
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 25px;
  max-width: 230px;

  border-radius: 3px;
  background-color: #ececec;
  height: 25px;

  margin-top: 10px;
  margin-right: 10px;
  padding-left: 30px;

  span {
    margin: 0;
    color: #5433f1;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ececec;
    width: 25px;
    font-weight: 600;
    line-height: 25px;
    color: #5433f1;
    border: none;
    height: 25px;
    text-align: center;
  }
`;

export const LeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 58px;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 60px;
  flex-direction: column;
  margin-left: 40px;
`;

export const MainHeader = styled.div`
  z-index: 1;
  display: flex;
  margin-bottom: 70px;
  flex-direction: column;
  margin-bottom: 20px;

  h1 {
    max-width: 817px;
    margin-bottom: 13px;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 60px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 70px;
  }

  div {
    display: flex;

    p {
      margin: 0;
      margin-right: 35px;
      color: #ffffff;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
`;

export const BtnCallback = styled.a`
  z-index: 1;
  margin-bottom: 53px;
  width: 91px;
  span {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  p {
    margin-bottom: 0;
    color: #ffffff;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 18px;
    text-decoration: none !important;
  }
  display: flex;
  align-items: center;
  transition: opacity 0.2s ease;
  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
`;

export const TextContainer = styled.div`
  width: 807px;
  margin-top: 80px;
  z-index: 1;

  .livro {
    width: 100%;
    min-height: 100px;
    background: rgb(232, 232, 232);
  }

  .textHeader {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    height: 70px;
    margin-bottom: 70px;
  }

  .icons {
    margin-right: 5px;
  }

  .buttonClassroom {
    background: white;
    border: 0;
    border-left: 0.5px solid lightgray;
    box-shadow: 2px 2px 8px rgba(97, 97, 97, 0.2);
    &:active,
    &:focus {
      h2 {
        margin-top: 5px;
      }
      border-bottom: 5px solid lightgray;
    }
  }

  .textSubtittle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    button {
      width: 100px;
      height: 35px;
      border: 2px solid #efefef;
      border-radius: 5px;
      background-color: #ffffff;
      transition: opacity 0.2s ease;
      img {
        margin-left: 10px;
      }
      &:active {
        opacity: 0.5;
      }
    }
  }

  h2 {
    font-weight: 400;
    text-align: center;
    img {
      margin-right: 5px;
    }
  }
  .markebleText {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    background-color: ${({ markerActive }) =>
      markerActive ? "#fcf6c7" : "initial"};
    transition: background-color 0.7s ease;
    white-space: pre-wrap;
  }
  .selectedText {
    text-decoration: underline;
    color: #252525;
    background-color: transparent;
  }
  .unmarkedText {
    color: #424242;
  }
`;
