/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useEffect, useState, useMemo } from "react";
import S3 from "react-aws-s3";
import Highlighter from "react-highlight-words";
import { AiOutlineForm } from "react-icons/ai";
import { MdSlowMotionVideo, MdKeyboardArrowLeft } from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TextSelector from "text-selection-react";
import { Player } from "video-react";

import { useTheme } from "@material-ui/core";

import { Loading, AccordionClassromModules, NoVideo } from "~/components";
import Comment from "~/components/Comment";
import FavoritesDrawer from "~/components/FavoritesDrawer";
import NotesDrawer from "~/components/NotesDrawer";
import {
  IconLike,
  IconDislike,
  IconStar,
  IconEmph,
  IconFavorites,
  IconNotes,
  IconStarFilled,
  User,
} from "~/images";
import { getAnnotationsRequest } from "~/store/modules/annotations/actions";
import {
  getCommentsRequest,
  postCommentRequest,
} from "~/store/modules/comments/actions";
import {
  getCourseRequest,
  setAciveClass,
} from "~/store/modules/course/actions";
import {
  editFavoritesRequest,
  getFavoritesRequest,
  postFavoritesRequest,
} from "~/store/modules/favorites/actions";
import "~/styles/video-react.css";
import { postLikedislikeRequest } from "~/store/modules/likedislike/actions";
import {
  deleteMarkerRequest,
  getMarkersRequest,
  postMarkerRequest,
} from "~/store/modules/marker/actions";
import {
  getModuleRequest,
  getSubjectRequest,
} from "~/store/modules/module/actions";
import { getVideoRequest } from "~/store/modules/videos/actions";
import { formatFileName } from "~/utils/date";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";
import { capitalize } from "~/utils/stringUtils";

import CourseLinkDisplay from "../../components/Book";
import {
  Container,
  NewComment,
  NewCommentOptions,
  NewCommentButtons,
  AddFiles,
  CommentsContainer,
  CommentsHeader,
  VideoContainer,
  VideoTools,
  Video,
  IconContainer,
  VideoToolsText,
  ButtonLike,
  Background,
  ClassTools,
  ButtonDislike,
  ButtonStar,
  Files,
  File,
  LeftMenu,
  BtnCallback,
  Content,
  MainHeader,
  TextContainer,
} from "./styles";

function ClassRoomPage() {
  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "classroom.pageTitle" }));

  const theme = useTheme();
  const dispatch = useDispatch();
  const { course } = useSelector((state) => state.course);
  const { modules, modulesWithSubjects } = useSelector(
    (state) => state.modules
  );
  const { loading: likedislikeLoading } = useSelector(
    (state) => state.likedislikes
  );
  const { comments, loading: commentLoading } = useSelector(
    (state) => state.comments
  );
  const { favorites } = useSelector((state) => state.favorites);
  const { markers } = useSelector((state) => state.marker);
  const { video, videoLoading } = useSelector((state) => state.videos);

  const [newCommentState, setNewCommentState] = useState("");
  const [commentsState, setCommentsState] = useState(null);
  const [courseState, setCourseState] = useState(null);
  const [modulesState, setModulesState] = useState(null);
  const [disciplineState, setDisciplineState] = useState(0);
  const [classState, setClassState] = useState(null);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [likedCount, setLikedCount] = useState(0);
  const [dislikedCount, setDislikedCount] = useState(0);
  const [commentFiles, setCommentFiles] = useState(null);
  const [commentFilesName, setCommentFilesName] = useState(null);
  const [favorited, setFavorited] = useState(null);
  const [favoritesState, setFavoritesState] = useState(null);
  const [showFavoriteList, setShowFavoriteList] = useState(null);
  const [showNoteList, setShowNoteList] = useState(null);
  const [textSelected, setTextSelected] = useState([]);
  const [isMarkerActive, setIsMarkerActive] = useState(false);
  const [isCommentToLong, setIsCommentToLong] = useState(false);

  const { course_id, module_id, class_id } = useParams();
  const { userInfo } = useSelector((state) => state.auth);

  const moduleId = parseInt(module_id, 10);
  const classId = parseInt(class_id, 10);
  const moduleIndex = modules.findIndex((module) => module.id === moduleId);

  const urlAvatar = userInfo.ds_foto
    ? `${process.env.REACT_APP_API_FILE_URL}/config/${userInfo.ds_foto}`
    : User;

  const markText = async (tag, text) => {
    if (text.length < 15) {
      toast.error("Selecione um texto com pelo menos 15 caracteres");
      return;
    }
    textSelected.forEach((markedText) => {
      if (markedText.includes(text)) {
        toast.error("Texto já selecionado");
      }
    });

    setTextSelected([...textSelected, text]);
    dispatch(
      postMarkerRequest({
        id_matricula: userInfo.id_matricula,
        id_trecho: classState.crs_livro_trecho.id_trecho,
        ds_grifo: text,
      })
    );
  };

  const ereaseSelectedText = (tag, text) => {
    if (text.length < 15) {
      toast.error("Mínimo de 15 caracteres para grifo!");
      return;
    }
    const marker = markers.find((item) =>
      item.mat_grifo.ds_grifo.includes(text)
    );
    if (marker) {
      setTextSelected(
        textSelected.filter((markedText) => !markedText.includes(text))
      );
      dispatch(
        deleteMarkerRequest({
          id_grifo: marker.mat_grifo.id_grifo,
          id_trecho: classState.crs_livro_trecho.id_trecho,
          id_matricula: userInfo.id_matricula,
        })
      );
    }
    if (classState.crs_video.ds_url_video) {
      dispatch(
        getVideoRequest({
          file: classState.crs_video.ds_url_video,
        })
      );
    }
  };

  const handleCreateComment = () => {
    if (!isCommentToLong) return;
    if (newCommentState !== "" && !commentFiles) {
      setNewCommentState("");
      dispatch(
        postCommentRequest({
          ds_comentario: newCommentState.toString(),
          id_aula: classState.crs_aula.id_aula,
          id_curso: course_id,
        })
      );
    } else if (newCommentState !== "" && commentFiles) {
      setNewCommentState("");
      const config = {
        bucketName: "avaead-lms-dev",
        dirName: "comentario",
        region: "us-east-1",
        accessKeyId: process.env.REACT_APP_S3_ID,
        secretAccessKey: process.env.REACT_APP_S3_ACESSES_KEY,
        s3Url: process.env.REACT_APP_S3_URL,
      };

      const ReactS3 = new S3(config);

      Promise.all(
        commentFiles.map((file, index) =>
          setTimeout(() => {
            return ReactS3.uploadFile(
              file,
              `${commentFilesName.map((fileName) =>
                fileName.newName.split(".").splice(0, 1).join(".")
              )}`
            );
          }, 2000)
        )
      )
        .then(() => {
          setCommentFiles(null);
          setCommentFilesName(null);
          dispatch(
            postCommentRequest({
              ds_comentario: newCommentState.toString(),
              id_aula: classState.crs_aula.id_aula,
              id_curso: course_id,
              data: commentFilesName.map((fileName) => fileName.newName),
            })
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error("Erro ao gravar anexo!");
        });
    }
  };

  async function handleText(data) {
    setNewCommentState(data.currentTarget.value);
  }

  useEffect(() => {
    if (modulesState && modulesState[moduleIndex].classes) {
      modulesState[moduleIndex].classes.forEach((discipline, index) => {
        discipline.aulas.forEach((aula) => {
          if (aula.crs_aula.id_aula === classId) {
            setClassState(aula);
            setDisciplineState(index);
          }
        });
      });
    }
  }, [modulesState, classId]);

  useMemo(() => {
    dispatch(getCourseRequest({ course_id }));
  }, [dispatch, course_id]);

  useEffect(() => {
    const comment = newCommentState.toString();
    if (comment.length <= 255) setIsCommentToLong(true);
    if (comment.length > 255) setIsCommentToLong(false);
  }, [newCommentState]);

  useEffect(() => {
    if (modules.length) {
      dispatch(
        getFavoritesRequest({
          id_grade: modules[0].grade,
          id_matricula: userInfo.id_matricula,
        })
      );
      setModulesState(modules);
      if (!modules[moduleIndex].classes) {
        const courseModule = modules.find((module) => module.id === moduleId);
        dispatch(getSubjectRequest({ courseModule, index: moduleIndex }));
      }
    }
  }, [modules]);

  useMemo(() => {
    if (course) setCourseState(course);
  }, [course]);

  useMemo(() => {
    if (favorites) setFavoritesState(favorites);
  }, [favorites]);

  useEffect(() => {
    if (favoritesState && favoritesState.length && classState) {
      let favoriteIndex = favoritesState.findIndex(
        (fav) =>
          fav.disciplina[0].capitulo[0].aula[0].crs_aula.id_aula ===
          classState.crs_aula.id_aula
      );

      if (favoriteIndex !== -1) {
        setFavorited(
          favoritesState[favoriteIndex].mat_favorito_aula.tp_situacao === "A"
        );
      }
    }
  }, [favoritesState]);

  useMemo(() => {
    if (comments) setCommentsState(comments);
  }, [comments]);

  useMemo(() => {
    const markerText = markers.map((item) => item.mat_grifo.ds_grifo);
    setTextSelected(markerText);
  }, [markers]);

  useMemo(() => {
    if (courseState && courseState.id_matricula)
      dispatch(
        getModuleRequest({
          id_matricula: courseState.id_matricula,
        })
      );
  }, [courseState]);

  useEffect(() => {
    if (!isEmpty(classState)) {
      dispatch(
        setAciveClass({
          id_disciplina: 1,
          id_aula: classState.crs_aula.id_aula,
        })
      );
      dispatch(
        getCommentsRequest({
          id_aula: classState.crs_aula.id_aula,
          id_curso: course_id,
        })
      );
      dispatch(
        getAnnotationsRequest({
          id_curso: course_id,
          id_aula: classState.crs_aula.id_aula,
        })
      );
      setLikedCount(classState.crs_video.nr_like);
      setDislikedCount(classState.crs_video.nr_dislike);
      setLiked(classState.mat_video_like.nr_like > 0);
      setDisliked(classState.mat_video_like.nr_dislike > 0);
      const favoriteIndex = favoritesState.findIndex(
        (fav) =>
          fav.disciplina[0].capitulo[0].aula[0].crs_aula.id_aula ===
          classState.crs_aula.id_aula
      );

      if (favoriteIndex !== -1) {
        setFavorited(
          favoritesState[favoriteIndex].mat_favorito_aula.tp_situacao === "A"
        );
      } else {
        setFavorited(null);
      }
      if (classState.crs_livro_trecho.id_trecho) {
        dispatch(
          getMarkersRequest({
            id_matricula: userInfo.id_matricula,
            id_trecho: classState.crs_livro_trecho.id_trecho,
          })
        );
      }
      if (classState.crs_video.ds_url_video) {
        dispatch(
          getVideoRequest({
            file: classState.crs_video.ds_url_video,
          })
        );
      }
    }
  }, [classState]);

  let isReady = false;

  if (!isEmpty(courseState) && !isEmpty(modules) && !isEmpty(classState)) {
    isReady = true;
  }

  function goBack() {
    window.history.go(-1);
  }

  return (
    <div>
      {!isReady ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <Container>
          <Background background={courseState.coursePreview.image}>
            <div />
          </Background>

          <FavoritesDrawer
            show={showFavoriteList}
            closeList={setShowFavoriteList}
          />
          <NotesDrawer show={showNoteList} closeList={setShowNoteList} />

          <LeftMenu>
            <BtnCallback onClick={goBack}>
              <span>
                <MdKeyboardArrowLeft
                  size={16}
                  color={theme.palette.primary[theme.palette.type]}
                />
              </span>
              <p>Voltar</p>
            </BtnCallback>

            <AccordionClassromModules
              defaultModule={moduleIndex}
              defaultDiscipline={disciplineState}
              items={modules}
            />
          </LeftMenu>

          {!isEmpty(classState) && (
            <>
              <Content>
                <MainHeader>
                  <h1>{capitalize(courseState.coursePreview.title)}</h1>
                  <div>
                    <p>
                      {courseState.banner.modules > 1
                        ? `${courseState.banner.modules} ${_e({
                            id: "course.modulesBannerPlural",
                          })}`
                        : `${courseState.banner.modules} ${_e({
                            id: "course.modulesBannerSingular",
                          })}`}
                    </p>
                    <p>{courseState.banner.duration}h de duração</p>
                  </div>
                </MainHeader>
                {classState.crs_video.ds_url_video ? (
                  <>
                    {!videoLoading && (
                      <VideoContainer videoLoading={videoLoading}>
                        <Video>
                          <Player
                            fluid
                            playsInline
                            poster="/assets/poster.png"
                            src={video}
                          />
                        </Video>

                        <VideoTools>
                          <VideoToolsText>
                            <img src={urlAvatar} alt="Avatar de usuário" />
                            <p>Escreva um comentário_</p>
                          </VideoToolsText>
                          <IconContainer>
                            <div>
                              <ButtonLike
                                liked={liked}
                                onClick={() => {
                                  if (!liked && !likedislikeLoading) {
                                    setLikedCount(likedCount + 1);
                                    if (disliked)
                                      setDislikedCount(dislikedCount - 1);
                                    setLiked(true);
                                    setDisliked(false);
                                    dispatch(
                                      postLikedislikeRequest({
                                        id_matricula: courseState.id_matricula,
                                        id_video: classState.crs_video.id_video,
                                        id_aula: classState.crs_aula.id_aula,
                                        nr_like: 1,
                                        nr_dislike: 0,
                                      })
                                    );
                                  }

                                  if (liked && !likedislikeLoading) {
                                    setLikedCount(likedCount - 1);
                                    setLiked(false);
                                    dispatch(
                                      postLikedislikeRequest({
                                        id_matricula: courseState.id_matricula,
                                        id_video: classState.crs_video.id_video,
                                        id_aula: classState.crs_aula.id_aula,
                                        nr_like: 0,
                                        nr_dislike: 0,
                                      })
                                    );
                                  }
                                }}
                              >
                                <img
                                  src={liked ? IconLike : IconDislike}
                                  alt="like"
                                />
                              </ButtonLike>
                              <p>{likedCount}</p>
                            </div>

                            <div>
                              <ButtonDislike
                                disliked={disliked}
                                onClick={() => {
                                  if (!disliked && !likedislikeLoading) {
                                    setDislikedCount(dislikedCount + 1);
                                    if (liked) setLikedCount(likedCount - 1);
                                    setDisliked(true);
                                    setLiked(false);
                                    dispatch(
                                      postLikedislikeRequest({
                                        id_matricula: courseState.id_matricula,
                                        id_video: classState.crs_video.id_video,
                                        id_aula: classState.crs_aula.id_aula,
                                        nr_like: 0,
                                        nr_dislike: 1,
                                      })
                                    );
                                  }

                                  if (disliked && !likedislikeLoading) {
                                    setDisliked(false);
                                    setDislikedCount(dislikedCount - 1);
                                    dispatch(
                                      postLikedislikeRequest({
                                        id_matricula: courseState.id_matricula,
                                        id_video: classState.crs_video.id_video,
                                        id_aula: classState.crs_aula.id_aula,
                                        nr_like: 0,
                                        nr_dislike: 0,
                                      })
                                    );
                                  }
                                }}
                              >
                                <img
                                  src={disliked ? IconLike : IconDislike}
                                  alt="dislike"
                                />
                              </ButtonDislike>
                              <p>{dislikedCount}</p>
                            </div>

                            <ButtonStar
                              onClick={() => {
                                if (favorited !== null) {
                                  setFavorited(!favorited);
                                }

                                if (favorited === null) {
                                  dispatch(
                                    postFavoritesRequest({
                                      id_aula: classState.crs_aula.id_aula,
                                      id_matricula: courseState.id_matricula,
                                      id_grade: modulesState[0].grade,
                                      tp_situacao: "A",
                                    })
                                  );
                                  setFavorited(true);
                                } else if (!favorited) {
                                  dispatch(
                                    editFavoritesRequest({
                                      id_aula: classState.crs_aula.id_aula,
                                      id_matricula: courseState.id_matricula,
                                      id_grade: modulesState[0].grade,
                                      tp_situacao: "A",
                                    })
                                  );
                                } else if (favorited) {
                                  dispatch(
                                    editFavoritesRequest({
                                      id_aula: classState.crs_aula.id_aula,
                                      id_matricula: courseState.id_matricula,
                                      id_grade: modulesState[0].grade,
                                      tp_situacao: "I",
                                    })
                                  );
                                }
                              }}
                            >
                              <img
                                src={favorited ? IconStarFilled : IconStar}
                                alt="star"
                              />
                            </ButtonStar>
                          </IconContainer>
                        </VideoTools>
                      </VideoContainer>
                    )}
                  </>
                ) : (
                  <NoVideo />
                )}
                <TextContainer markerActive={isMarkerActive}>
                  <div className="textHeader">
                    <button type="button" className="buttonClassroom">
                      <h2>
                        <MdSlowMotionVideo
                          alt="Videos"
                          color={theme.palette.primary[theme.palette.type]}
                          className="icons"
                        />
                        Vídeos
                      </h2>
                    </button>
                    <button type="button" className="buttonClassroom">
                      <h2>
                        <img
                          src={IconFavorites}
                          alt="Conteúdo"
                          className="icons"
                          color={theme.palette.primary[theme.palette.type]}
                        />
                        Conteúdo
                      </h2>
                    </button>
                    <button type="button" className="buttonClassroom">
                      <h2>
                        <AiOutlineForm
                          alt="Exercicios"
                          color={theme.palette.primary[theme.palette.type]}
                          className="icons"
                        />
                        Exercícios
                      </h2>
                    </button>
                  </div>
                </TextContainer>
                {classState.crs_livro_trecho.ds_conteudo && (
                  <TextContainer markerActive={isMarkerActive}>
                    <div className="textSubtittle">
                      <h3>{classState.crs_aula.ds_aula}</h3>
                      <button
                        type="button"
                        onClick={() => setIsMarkerActive(!isMarkerActive)}
                      >
                        Grifar
                        <img src={IconEmph} alt="Grifos" />
                      </button>
                    </div>

                    {modulesState[0].classes && (
                      <CourseLinkDisplay
                        url={modulesState[0].classes[0].crs_livro.ds_caminho}
                        file={modulesState[0].classes[0].crs_livro.ds_livro}
                      />
                    )}

                    <Highlighter
                      className="markebleText"
                      unhighlightClassName="unmarkedText"
                      highlightClassName="selectedText"
                      searchWords={textSelected}
                      autoEscape
                      textToHighlight={classState.crs_livro_trecho.ds_conteudo}
                    />
                    {isMarkerActive ? (
                      <TextSelector
                        events={[
                          {
                            text: "Grifar",
                            handler: markText,
                          },
                          {
                            text: "Excluir",
                            handler: ereaseSelectedText,
                          },
                        ]}
                      />
                    ) : (
                      <> </>
                    )}
                  </TextContainer>
                )}

                <CommentsContainer>
                  <CommentsHeader>
                    <h1>Comentários</h1>
                    <p>Compartilhe um comentário sobre esta aula</p>
                  </CommentsHeader>
                  {commentLoading ? (
                    <div className="area-loadding">
                      <Loading />
                    </div>
                  ) : (
                    <div>
                      <NewComment error={isCommentToLong}>
                        <div>
                          <img src={urlAvatar} alt="Avatar" />
                          <input
                            onChange={handleText}
                            placeholder="Adicionar um comentário público..."
                            type="text"
                            maxLength={256}
                          />
                          <span className="coment-error">
                            Comentário muito longo! Tamanho máximo 255
                            caracteres.
                          </span>
                          <NewCommentOptions>
                            <AddFiles>
                              <label htmlFor="file">Anexar</label>
                              <input
                                onChange={(event) => {
                                  let filesArray = Array.from(
                                    event.currentTarget.files
                                  );

                                  for (let i = 0; i < filesArray.length; i++) {
                                    if (
                                      filesArray[i].name.split(".")[1] !==
                                        "jpg" &&
                                      filesArray[i].name.split(".")[1] !==
                                        "jpeg" &&
                                      filesArray[i].name.split(".")[1] !==
                                        "png" &&
                                      filesArray[i].name.split(".")[1] !== "pdf"
                                    ) {
                                      toast.error(
                                        "Os formatos aceitos para Upload são: .jpg, .jpeg, .png e pdf."
                                      );
                                      break;
                                    }
                                  }

                                  filesArray = filesArray.filter(
                                    (file) =>
                                      file.name.split(".")[1] === "jpg" ||
                                      file.name.split(".")[1] === "jpeg" ||
                                      file.name.split(".")[1] === "png" ||
                                      file.name.split(".")[1] === "pdf"
                                  );
                                  setCommentFilesName(
                                    filesArray.map((file, index) => ({
                                      oldName: file.name,
                                      newName: `${
                                        courseState.id_matricula
                                      }_${formatFileName(new Date())}${index}.${
                                        file.name.split(".").pop() === "jpg"
                                          ? "jpeg"
                                          : file.name.split(".").pop()
                                      }`,
                                    }))
                                  );
                                  setCommentFiles(filesArray);
                                }}
                                id="file"
                                multiple
                                type="file"
                              />
                            </AddFiles>
                            <NewCommentButtons>
                              {/* <button type="button">
                                <span>CANCELAR</span>
                              </button> */}
                              <button
                                type="button"
                                onClick={handleCreateComment}
                              >
                                <span>COMENTAR</span>
                              </button>
                            </NewCommentButtons>
                          </NewCommentOptions>
                        </div>

                        {commentFilesName && (
                          <Files>
                            {commentFilesName.map((file, index) => (
                              <File key={index}>
                                <span>{file.newName}</span>
                                <button
                                  onClick={() => {
                                    setCommentFiles(
                                      Array.from(commentFiles).filter(
                                        (crrFile) =>
                                          file.oldName !== crrFile.name
                                      )
                                    );
                                    setCommentFilesName(
                                      commentFilesName.filter(
                                        (crrFileName) =>
                                          file.oldName !== crrFileName.oldName
                                      )
                                    );
                                  }}
                                  type="button"
                                >
                                  x
                                </button>
                              </File>
                            ))}
                          </Files>
                        )}
                      </NewComment>
                      {commentsState.map((comment, index) => (
                        <Comment
                          key={index}
                          comment={comment}
                          id_aula={classState.crs_aula.id_aula}
                          id_curso={course_id}
                        />
                      ))}
                    </div>
                  )}
                </CommentsContainer>
              </Content>

              <ClassTools>
                <div>
                  <p>Anotações</p>
                  <p>Favoritos</p>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => setShowNoteList(!showNoteList)}
                  >
                    <img src={IconNotes} alt="Anotações" />
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowFavoriteList(!showFavoriteList)}
                  >
                    <img src={IconFavorites} alt="Favoritos" />
                  </button>
                </div>
              </ClassTools>
            </>
          )}
        </Container>
      )}
    </div>
  );
}

export default ClassRoomPage;
