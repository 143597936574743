export function getDocumentsRequest(data) {
  return {
    type: "@documents/GET_DOCUMENTS_REQUEST",
    payload: data,
  };
}
export function getDocumentsSuccess(data) {
  return {
    type: "@documents/GET_DOCUMENTS_SUCCESS",
    payload: { data },
  };
}
