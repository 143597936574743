import { toast } from "react-toastify";

import { takeLatest, call, put, all } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";

import { getTutorsSuccess } from "./actions";

export function* getTutors({ payload }) {
  const { id_curso } = payload;
  try {
    const response = yield call(api.get, API.TEACHERS_LIST, {
      id_curso,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(getTutorsSuccess(response.data.data));
  } catch (err) {
    toast.error("Erro ao carregar informações sobre professores!");
  }
}

export default all([takeLatest("@tutors/GET_TUTORS_REQUEST", getTutors)]);
