import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loading } from "~/components";
import AccordionDocuments from "~/components/AccordionDocuments";
import { getDocumentsRequest } from "~/store/modules/documents/actions";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";

import { Container } from "./styles";

function WorkInProgress() {
  documentTitle("Meus Documentos");

  const dispatch = useDispatch();
  const { documents } = useSelector((state) => state.documents);

  useMemo(() => {
    dispatch(getDocumentsRequest());
  }, [dispatch]);

  return (
    <Container>
      <h1>Meus Documentos</h1>
      {!isEmpty(documents) ? (
        <AccordionDocuments items={documents} />
      ) : (
        <div className="area-loadding">
          <Loading />
        </div>
      )}
    </Container>
  );
}

export default WorkInProgress;
