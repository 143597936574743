/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { Title, Loading } from "~/components";
import { getAcademicSuportRequest } from "~/store/modules/academicSuport/actions";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";

import { Container } from "./styles";

function AcademicSuport() {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const academicSuport = useSelector((state) => state.academicSuport);
  const { formatMessage: _e } = useIntl();

  useMemo(() => {
    dispatch(getAcademicSuportRequest(userInfo.id_matricula));
  }, [dispatch]);
  const { daysAvailable, nextRecesses } = academicSuport;

  documentTitle(_e({ id: "academicSuport.pageTitle" }));

  function TitleAcademicSuport() {
    return `${_e({ id: "academicSuport.pageTitle" })}`;
  }
  return isEmpty(academicSuport) ? (
    <Loading />
  ) : (
    <Container>
      <div className="title-container">
        <Title>
          <TitleAcademicSuport />
        </Title>
      </div>
      <div className="content">
        <div>
          <h2>{_e({ id: "academicSuport.openingHours" })}</h2>
          {daysAvailable.map((day) => (
            <p key={day.id}>
              <b>{day.day}</b>: {day.openTime} - {day.closeTime}
            </p>
          ))}
        </div>
        {!isEmpty(nextRecesses) ? (
          <div>
            <h2>{_e({ id: "academicSuport.recesses" })}</h2>
            {nextRecesses.map((recess) => (
              <div key={recess.id}>
                <p>
                  <span className="recesses">{recess.description}</span>:{" "}
                  {moment(recess.startDate).format("DD/MM")} -{" "}
                  {moment(recess.endDate).format("DD/MM")}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
}

export default AcademicSuport;
