import { toast } from "react-toastify";

import { takeLatest, call, put, all } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";

import { getCEPSuccess, getPhoneTypeSuccess } from "./actions";

export function* getPhoneTypes() {
  try {
    const response = yield call(api.get, API.PHONE_TYPE_LIST);

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(getPhoneTypeSuccess(response.data.data));
  } catch (err) {
    toast.error("Erro ao buscar telefone tipo!");
  }
}

export function* getCEP({ payload }) {
  const { cep } = payload;
  try {
    const response = yield call(api.get, API.CEP_LIST, {
      cep,
    });

    if (response.data.data.error)
      throw new Error(response.data.errors[0].message);

    yield put(getCEPSuccess(response.data.data));
  } catch (err) {
    toast.error("Digite um cep valido!");
  }
}

export default all([
  takeLatest("@phone/GET_PHONE_TYPE_REQUEST", getPhoneTypes),
  takeLatest("@phone/GET_CEP_REQUEST", getCEP),
]);
