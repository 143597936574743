import { createMuiTheme } from "@material-ui/core";

import { cool } from "~/utils/cool";

import { Palette } from "./palette/Palette";

export function theme(colors) {
  if (!colors.primary) return;
  const palette = {
    primary: new Palette(
      colors.primary,
      colors.primary,
      colors.primary,
      colors.primary
    ),
    secondary: new Palette(
      colors.secondary,
      colors.secondary,
      colors.secondary,
      colors.secondary
    ),
    tertiary: new Palette(
      colors.tertiary,
      colors.tertiary,
      colors.tertiary,
      colors.tertiary
    ),
    quaternary: new Palette(
      colors.quaternary,
      colors.quaternary,
      colors.quaternary,
      colors.quaternary
    ),
    quinary: new Palette(
      colors.quinary,
      colors.quinary,
      colors.quinary,
      colors.quinary
    ),
    senary: new Palette(
      colors.senary,
      colors.senary,
      colors.senary,
      colors.senary
    ),
    background: new Palette(
      colors.background,
      colors.background,
      colors.background,
      colors.background
    ),
    backgroundTwo: new Palette(
      colors.backgroundTwo,
      colors.backgroundTwo,
      colors.backgroundTwo,
      colors.backgroundTwo
    ),
    error: new Palette(colors.error, colors.error, colors.error, colors.error),
    text: new Palette(colors.text, colors.text, colors.text, colors.text),
    textTwo: new Palette(
      colors.textTwo,
      colors.textTwo,
      colors.textTwo,
      colors.textTwo
    ),
    button: new Palette(
      colors.button,
      colors.button,
      colors.button,
      colors.button
    ),
    textButton: new Palette(
      colors.textButton,
      colors.textButton,
      colors.textButton,
      colors.textButton
    ),
  };
  const typography = {
    fontFamily: "Montserrat, sans-serif",
  };

  const customTheme = cool({
    palette,
    typography,
  });
  return createMuiTheme(customTheme);
}
