/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import ContainerMD from "@material-ui/core/Container";

import { Loading } from "~/components";
import MyProfilePreview from "~/components/MyProfile";
import { Money, Score, Graph, Certificate } from "~/images";
import { getProfileRequest } from "~/store/modules/profile/actions";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";
import { capitalize } from "~/utils/stringUtils";

import { MainHeader } from "../Classroom/styles";
import {
  Container,
  LeftMenu,
  BtnCallback,
  Content,
  Title,
  ProfileTitle,
  DivCardPerfil,
  CardPerfil,
  ModulesName,
} from "./styles";

function MyProfile() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const { userInfo } = useSelector((state) => state.auth);

  const { formatMessage: _e } = useIntl();
  documentTitle(_e({ id: "myProfile.account" }));

  function goBack() {
    window.history.go(-1);
  }

  useEffect(() => {
    if (isEmpty(profile)) dispatch(getProfileRequest());
  }, [dispatch]);

  return (
    <Container>
      {isEmpty(profile) ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <ContainerMD maxWidth="lg">
          <LeftMenu>
            <BtnCallback onClick={goBack}>
              <span>
                <MdKeyboardArrowLeft
                  size={16}
                  color={theme.palette.primary[theme.palette.type]}
                />
              </span>
              <p>Voltar</p>
            </BtnCallback>
          </LeftMenu>
          <Content>
            <MainHeader>
              <Title>
                Olá {capitalize(userInfo.ds_nome)}. Seja bem-vindo novamente.
              </Title>
              <p>Confira o resumo de suas atividades</p>
            </MainHeader>
            <ProfileTitle>
              {_e({ id: "myProfile.myProfile" })}
              <Link className="edit" to="/configuracoes">
                Editar
              </Link>
            </ProfileTitle>

            <MyProfilePreview
              name={userInfo.ds_nome}
              email={profile.pes_email.ds_email}
              phone={`(${profile.contato[0].pes_telefone.nr_ddd}) ${profile.contato[0].pes_telefone.nr_telefone}`}
              avatar={profile.pes_login.ds_foto}
            />
            <DivCardPerfil>
              <CardPerfil>
                <img
                  color={theme.palette.primary[theme.palette.type]}
                  src={Money}
                  alt="Money"
                />
                <ModulesName>
                  {_e({ id: "myProfile.subAndPayment" })}
                </ModulesName>
              </CardPerfil>
              <CardPerfil>
                <img
                  color={theme.palette.primary[theme.palette.type]}
                  src={Score}
                  alt="Score"
                  style={{ width: "19px" }}
                />
                <ModulesName>{_e({ id: "myProfile.myScore" })}</ModulesName>
              </CardPerfil>
              <CardPerfil>
                <img
                  color={theme.palette.primary[theme.palette.type]}
                  src={Graph}
                  alt="Graph"
                />
                <ModulesName>{_e({ id: "myProfile.statistic" })}</ModulesName>
              </CardPerfil>
              <CardPerfil>
                <img
                  color={theme.palette.primary[theme.palette.type]}
                  src={Certificate}
                  alt="Certificate"
                />
                <ModulesName className="modulesName">
                  {_e({ id: "myProfile.certificate" })}
                </ModulesName>
              </CardPerfil>
            </DivCardPerfil>
          </Content>
        </ContainerMD>
      )}
    </Container>
  );
}

export default MyProfile;
