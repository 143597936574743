/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo, modulesState } from "~/store/selectors";

import { getModuleSuccess, getSubjectSuccess } from "./actions";

function* getModuleSubjects({ payload }) {
  const { courseModule } = payload;
  const { id, grade } = courseModule;
  const { id_matricula } = yield select(userInfo);
  const modules = yield select(modulesState);
  const newModules = modules.slice();
  const index = newModules.findIndex((item) => item.id === courseModule.id);

  try {
    const responseModuleSubjects = yield call(api.get, API.SUBJECTS_LIST, {
      id_modulo: id,
      id_grade: grade,
      id_matricula,
    });

    if (responseModuleSubjects.data.errors.length)
      throw new Error(responseModuleSubjects.data.errors[0].message);

    const moduleSubjects = responseModuleSubjects.data.data;

    newModules[index] = {
      ...newModules[index],
      classes: moduleSubjects.map((disciplina) => ({
        crs_disciplina: disciplina.crs_disciplina,
        crs_livro: disciplina.crs_livro,
        aulas: disciplina.capitulo.reduce(
          (curr, cap) => curr.concat(cap.aula),
          []
        ),
      })),
    };
    yield put(getSubjectSuccess(newModules));
  } catch (err) {
    toast.error("Erro ao carregar disciplina!");
  }
}

export function* getModule({ payload }) {
  const { id_matricula } = payload;
  const { id_login } = yield select(userInfo);
  try {
    const responseModules = yield call(api.get, API.MODULES_LIST, {
      id_login,
      id_matricula,
    });
    if (responseModules.data.errors.length)
      throw new Error(responseModules.data.errors[0].message);

    const modules = responseModules.data.data;

    const formatedModules = modules.map((module) => {
      return {
        id: module.crs_modulo.id_modulo,
        number: module.crs_modulo.nr_modulo,
        title: module.crs_modulo.ds_modulo,
        dateUnlock: module.mat_modulo.dt_inicio,
        discipline: module.crs_modulo_disciplina.qtd,
        grade: module.crs_modulo.id_grade,
      };
    });

    yield put(getModuleSuccess(formatedModules));
  } catch (err) {
    toast.error("Erro ao carregar modulos!");
  }
}

export default all([
  takeLatest("@module/GET_MODULE_REQUEST", getModule),
  takeLatest("@module/GET_SUBJECT_REQUEST", getModuleSubjects),
]);
