/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Loading, Title } from "~/components";
import { getLibraryRequest } from "~/store/modules/course/actions";
import documentTitle from "~/utils/documentTitle";

import LibraryLinkDisplay from "../../components/LibraryLinkDisplay";
import Select from "../../components/Select";
import { Container, LibraryItem, LibraryItemsGrid } from "./styles";

export default function Library() {
  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "library.title" }));

  const dispatch = useDispatch();
  const { loading, library, libraryCourses } = useSelector(
    (state) => state.course
  );
  const [librariesToDisplay, setLibrariesToDisplay] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [categoryOptions, setCategoryOptions] = useState([
    { value: "all", label: "Todos" },
  ]);

  useEffect(() => {
    dispatch(getLibraryRequest());
  }, [dispatch]);

  useEffect(() => {
    if (libraryCourses.length > 0)
      setCategoryOptions([...categoryOptions, ...libraryCourses]);
  }, [libraryCourses]);

  useEffect(() => {
    if (categoryFilter.value === "all") {
      setLibrariesToDisplay(library);
    } else {
      setLibrariesToDisplay(
        library.filter((lib) => lib.crs_curso.ds_curso === categoryFilter.value)
      );
    }
  }, [categoryFilter]);

  useEffect(() => {
    setLibrariesToDisplay(library);
  }, [library]);

  function TitleLibrary() {
    return `${_e({ id: "library.title" })}`;
  }

  return (
    <Container>
      {loading ? (
        <div className="area-loading">
          <Loading />
        </div>
      ) : (
        <>
          <div className="content-wrapper">
            <div className="title-container">
              <div>
                <Title>
                  <TitleLibrary />
                </Title>
                <p>{_e({ id: "dashboard.summary" })}</p>
              </div>
              <div>
                <Select
                  className="select-filter"
                  label={_e({ id: "search.searchFilterCategory" })}
                  items={categoryOptions}
                  onChange={(item) => setCategoryFilter(item)}
                />
              </div>
            </div>
            <LibraryItemsGrid>
              {librariesToDisplay &&
                librariesToDisplay.map((item) => (
                  <LibraryItem key={String(item.crs_biblioteca.id_biblioteca)}>
                    <div className="info">
                      <h3>{item.crs_biblioteca.ds_titulo}</h3>
                      <p>{item.crs_biblioteca.ds_sobre}</p>
                    </div>

                    <div className="bottom">
                      <hr />
                      <div className="link">
                        <LibraryLinkDisplay
                          url={item.crs_biblioteca.ds_link}
                          file="Download do Livro"
                        />
                      </div>
                    </div>
                  </LibraryItem>
                ))}
            </LibraryItemsGrid>
          </div>
        </>
      )}
    </Container>
  );
}
