import styled from "styled-components";

export const Container = styled.div`
  width: 817px;
  height: 605.55px;
  background: ${({ theme }) => theme.palette.background[theme.palette.type]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  z-index: 0;
  border-radius: 5px;
`;
