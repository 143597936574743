import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
} from "date-fns";
import pt from "date-fns/locale/pt";

import browserLocale from "~/utils/browserLocale";

const isPT = browserLocale === "pt-BR" || browserLocale === "pt";

const locale = isPT ? pt : false;

export const formatDate = (date) => {
  if (differenceInDays(new Date(), date) === 0) {
    if (differenceInMinutes(new Date(), date) >= 60) {
      if (isPT) {
        return `Há ${differenceInHours(new Date(), date, { locale })} horas`;
      }
      return `${differenceInHours(new Date(), date)} hours ago`;
    }

    if (isPT) {
      return `Há ${differenceInMinutes(new Date(), date, { locale })} minutos`;
    }
    return `${differenceInMinutes(new Date(), date)} minutes ago`;
  }

  if (differenceInDays(new Date(), date) <= 7) {
    if (isPT) {
      return `Há ${differenceInDays(new Date(), date, { locale })} dias`;
    }
    return `${differenceInDays(new Date(), date)} days ago`;
  }

  return `${format(date, "dd")} ${isPT ? "de" : "of"} ${format(date, "MMMM", {
    locale,
  })}`;
};

export const formatDateStandard = (date) => {
  if (isPT) {
    return format(date, "dd/MM/yyyy", { locale });
  }

  return format(date, "MM/dd/yyyy", { locale });
};

export const formatDateDot = (date) => {
  if (isPT) {
    return format(date, "dd.MM.yyyy", { locale });
  }

  return format(date, "MM.dd.yyyy", { locale });
};

export const formatDateLine = (date) => {
  return format(date, "yyyy-MM-dd", { locale });
};

export const formatDateHour = (date) => {
  return format(date, "dd MMM yyyy - hh:mm aa", { locale });
};

export const formatDateDayMonth = (date) => {
  return format(date, "dd/MM", { locale });
};

export const formatWrittenDate = (date) => {
  return format(date, "dd, MMM yyyy", { locale });
};

export const formatFileName = (date) => {
  return format(date, "yyyyMMddkkmmssSS", { locale });
};

export const weekDay = (day) => {
  return day.toLocaleDateString(locale.code, {
    weekday: "short",
  });
};

export const weekDays = (current) => {
  let week = [];
  let first = current.getDate() - current.getDay() + 1;
  current.setDate(first);
  for (let i = 0; i < 7; i++) {
    week.push(new Date(+current));
    current.setDate(current.getDate() + 1);
  }
  return week;
};

export const isDateEquals = (firstDate, secondeDate) => {
  if (firstDate.getDate() !== secondeDate.getDate()) return false;
  if (firstDate.getMonth() !== secondeDate.getMonth()) return false;
  if (firstDate.getYear() !== secondeDate.getYear()) return false;
  return true;
};
