export function getNotifysRequest({ from, to }) {
  return {
    type: "@notifys/GET_NOTIFYS_REQUEST",
    payload: { from, to },
  };
}

export function getNotifysSuccess(data) {
  return {
    type: "@notifys/GET_NOTIFYS_SUCCESS",
    payload: { data },
  };
}

export function getNotifysFailure(data) {
  return {
    type: "@notifys/GET_NOTIFYS_FAILURE",
    payload: { data },
  };
}

export function postNotifysRequest({ id_notificacao }) {
  return {
    type: "@notifys/POST_NOTIFYS_REQUEST",
    payload: { id_notificacao },
  };
}

export function postNotifysSuccess() {
  return {
    type: "@notifys/POST_NOTIFYS_SUCCESS",
  };
}
