export function getProfileRequest() {
  return {
    type: "@profile/GET_PROFILE_REQUEST",
  };
}

export function getProfileSuccess(data) {
  return {
    type: "@profile/GET_PROFILE_SUCCESS",
    payload: { data },
  };
}

export function putProfileRequest(data) {
  return {
    type: "@profile/PUT_PROFILE_REQUEST",
    payload: { data },
  };
}

export function putProfileSuccess(data) {
  return {
    type: "@profile/PUT_PROFILE_SUCCESS",
    payload: { data },
  };
}

export function putPasswordRequest({ ds_senha }) {
  return {
    type: "@profile/PUT_PASSWORD_REQUEST",
    payload: { ds_senha },
  };
}

export function putPasswordSuccess() {
  return {
    type: "@profile/PUT_PASSWORD_SUCCESS",
  };
}
