import produce from "immer";

const INITIAL_STATE = {
  loading: false,
};

export default function likedislikes(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@likedislike/POST_LIKEDISLIKE_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@likedislike/POST_LIKEDISLIKE_SUCCESS": {
        draft.loading = false;
        break;
      }
      case "@likedislike/POST_LIKEDISLIKE_FAILURE": {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
