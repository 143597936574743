export function getStatitiscsRequest() {
  return {
    type: "@statistics/GET_STATISTICS_REQUEST",
  };
}

export function getStatitiscsSuccess(data) {
  return {
    type: "@statistics/GET_STATISTICS_SUCCESS",
    payload: { data },
  };
}
