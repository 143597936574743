import styled from "styled-components";

export const MenuButton = styled.button`
  height: 30px;
  width: auto;
  min-width: 110px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 15px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.palette.primary[theme.palette.type] : "#ffffff"};
  border: ${(props) => (props.selected ? 0 : "1px solid #EFEFEF")};
`;

export const ButtonText = styled.span`
  color: ${({ selected, theme }) =>
    selected ? "#ffffff" : theme.palette.primary[theme.palette.type]};
  height: 15px;
  width: auto;
  margin-left: 5px;
  margin-right: 5px;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  font-weight: 700;

  span {
    font-weight: 400;
  }
`;
