import styled from "styled-components";

export const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  .area-loadding {
    display: block;
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  ul.ul-announcements {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: 100px;
    * {
      color: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
    }
    li {
      margin: 0;
      padding: 0;
      display: flex;
      padding: 25px;
      border-bottom: 1px solid
        ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
      .content {
        width: 100%;
        .topbar {
          display: flex;
          justify-content: space-between;
          .category {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: normal;
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.84375px;
            text-transform: uppercase;
            color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
          }
          .time {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 18px;
            color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
          }
        }
        .title-announcements {
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
        }
      }
    }
  }
`;

export const BtnCallback = styled.a`
  span {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  position: absolute;
  left: 100px;
  top: ${({ bottom }) => (bottom ? "auto" : "70px")};
  bottom: ${({ bottom }) => (bottom ? "70px" : "auto")};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none !important;
  color: ${({ theme }) =>
    theme.palette.secondary[theme.palette.type]} !important;
  transition: opacity 0.2s ease;
  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
`;
