/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";

import {
  getStudyPlanSuccess,
  getStudyPlanFailure,
  postStudyPlanSuccess,
  postStudyPlanFailure,
  putStudyPlanSuccess,
  putStudyPlanFailure,
} from "./actions";

export function* getStudyPlan({ payload }) {
  const { id_matricula } = payload;
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;

  try {
    const response = yield call(api.get, API.STUDY_PLAN_LIST, {
      id_matricula,
      id_instituicao,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);
    const resposeData = response.data.data;
    const formatedPlan = [
      {
        studyDays: {
          id: resposeData.mat_plano_estudo.id_plano_estudo,
          mon: resposeData.mat_plano_estudo.tp_segunda,
          tue: resposeData.mat_plano_estudo.tp_terca,
          wed: resposeData.mat_plano_estudo.tp_quarta,
          thu: resposeData.mat_plano_estudo.tp_quinta,
          fri: resposeData.mat_plano_estudo.tp_sexta,
          sat: resposeData.mat_plano_estudo.tp_sabado,
          sun: resposeData.mat_plano_estudo.tp_domingo,
          totalHours: resposeData.mat_plano_estudo.nr_horas_estudo,
          averageHours: resposeData.mat_plano_estudo.nr_horas_media,
        },
      },
    ];
    const data = {
      studyPlan: formatedPlan,
      course: resposeData.crs_curso,
      mat_matricula: resposeData.mat_matricula,
    };
    yield put(getStudyPlanSuccess(data));
  } catch (err) {
    toast.error("Erro ao carregar plano de estudo!");
    return yield put(getStudyPlanFailure());
  }
}

export function* postStudyPlan({ payload }) {
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;
  const { id_matricula, studyPlan } = payload;

  const sendData = {
    tp_segunda: studyPlan.mon ? true : "",
    tp_terca: studyPlan.tue ? true : "",
    tp_quarta: studyPlan.wed ? true : "",
    tp_quinta: studyPlan.thu ? true : "",
    tp_sexta: studyPlan.fri ? true : "",
    tp_sabado: studyPlan.sat ? true : "",
    tp_domingo: studyPlan.sun ? true : "",
    nr_horas_estudo: studyPlan.totalHours,
  };

  const sendObject = {
    id_matricula,
    id_instituicao,
    ...sendData,
  };

  try {
    const response = yield call(api.post, API.STUDY_PLAN_CREATE, sendObject);
    if (response.data.errors.length) throw new Error(response.data.errors);

    yield put(postStudyPlanSuccess(response.data));
  } catch (err) {
    toast.error("Erro ao gravar plano de estudo");
    return yield put(postStudyPlanFailure());
  }
}

export function* putStudyPlan({ payload }) {
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;
  const { id_matricula, studyPlan } = payload;

  const sendData = {
    id_plano_estudo: studyPlan.id,
    tp_segunda: studyPlan.mon ? true : "",
    tp_terca: studyPlan.tue ? true : "",
    tp_quarta: studyPlan.wed ? true : "",
    tp_quinta: studyPlan.thu ? true : "",
    tp_sexta: studyPlan.fri ? true : "",
    tp_sabado: studyPlan.sat ? true : "",
    tp_domingo: studyPlan.sun ? true : "",
    nr_horas_estudo: studyPlan.totalHours,
  };
  const sendObject = {
    id_matricula,
    id_instituicao,
    ...sendData,
  };

  try {
    const response = yield call(api.put, API.STUDY_PLAN_EDIT, sendObject);
    if (response.data.errors.length) throw new Error(response.data.errors);

    yield put(putStudyPlanSuccess());
  } catch (err) {
    toast.error("Erro ao atualizar plano de estudo");
    return yield put(putStudyPlanFailure());
  }
}

export default all([
  takeLatest("@studyPlan/GET_STUDY_PLAN_REQUEST", getStudyPlan),
  takeLatest("@studyPlan/POST_STUDY_PLAN_REQUEST", postStudyPlan),
  takeLatest("@studyPlan/PUT_STUDY_PLAN_REQUEST", putStudyPlan),
]);
