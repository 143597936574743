import Carousel from "@brainhubeu/react-carousel";
import React from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import "@brainhubeu/react-carousel/lib/style.css";
import PropTypes from "prop-types";

import { CardCarousel } from "~/components";

import { Container } from "./styles";

export default function CarouselOfCard({ relatedCourse }) {
  return (
    <Container>
      <h2>Cursos Relacionados</h2>
      <p>Conheça nossos cursos</p>
      <Carousel
        className="related-course"
        infinite
        slides={relatedCourse.map((course) => (
          <CardCarousel
            urlClass={course.urlClass}
            image={course.image}
            area={course.area}
            nivel={course.nivel}
            title={course.title}
            duracaoHoras={course.duracaoHoras}
            duracaoMeses={course.duracaoMeses}
          />
        ))}
        slidesPerPage={3}
        arrowLeft={
          <div className="iconArrow">
            <AiOutlineLeft className="icon" size={24} />
          </div>
        }
        arrowRight={
          <div className="iconArrow">
            <AiOutlineRight className="icon" size={24} />
          </div>
        }
        addArrowClickHandler
      />
    </Container>
  );
}

CarouselOfCard.propTypes = {
  relatedCourse: PropTypes.array,
};

CarouselOfCard.defaultProps = {
  relatedCourse: [{}],
};
