import styled from "styled-components";

export const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 250px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-image: url(${({ image }) => image});
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 40px;
  padding: 20px 30px;

  .right {
    display: flex;
    align-items: center;
    padding-left: 15px;
    a {
      background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
      border: 2px solid
        ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
      box-sizing: border-box;
      border-radius: 3px;
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
      min-width: 110px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      text-decoration: none;
    }
    button {
      display: block;
      width: fit-content;
      height: fit-content;
      background: transparent;
      border: 0px solid;
    }
  }
`;
