import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { parseISO } from "date-fns";

import {
  Title,
  CoursePreview,
  BoxInfo,
  BoxListSimple,
  BoxListLarge,
  Loading,
  Calendar,
  News,
} from "~/components";
import { ROUTES } from "~/constants/routes";
import { IconWorkProgress } from "~/images";
import { getAnnouncementsRequest } from "~/store/modules/announcements/actions";
import { getDashboardRequest } from "~/store/modules/dashboard/actions";
import { getStatitiscsRequest } from "~/store/modules/statistics/actions";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";
import { capitalize } from "~/utils/stringUtils";

import { Container } from "./styles";

export default function Dashboard() {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);
  const statistics = useSelector((state) => state.statistics);
  const { announcements } = useSelector((state) => state.announcements);
  const { userInfo } = useSelector((state) => state.auth);
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(true);

  documentTitle("Dashboard");

  const { formatMessage: _e } = useIntl();

  useEffect(() => {
    dispatch(getDashboardRequest());
    dispatch(getStatitiscsRequest());
    dispatch(getAnnouncementsRequest({ from: 1, to: 3 }));
  }, [dispatch]);

  const { coursePreview, activities, calendar, banner } = dashboard;
  /**
   * @todo mescla de hardcode com intl, ReactIntl permite passar parametros
   */
  function TitleDashboard() {
    return `${_e({ id: "dashboard.hello" })}, ${capitalize(
      userInfo.ds_nome
    )}. ${_e({
      id: "dashboard.welcome",
    })}`;
  }
  return (
    <Container>
      {isEmpty(dashboard) ? (
        <Loading />
      ) : (
        <>
          {!isEmpty(banner) && show && (
            <News
              date={banner.dt_inicio_mensagem}
              description={banner.banner}
              backgroundImage={`${process.env.REACT_APP_S3_URL}/banner/${banner.ds_imagem}`}
              link={banner.ds_link}
              onClose={(e) => {
                e.preventDefault();
                setShow(false);
              }}
            />
          )}
          <div className="title-container">
            <Title>
              <TitleDashboard />
            </Title>
            <p>{_e({ id: "dashboard.summary" })}</p>
          </div>
          {coursePreview && (
            <CoursePreview
              image={coursePreview.image}
              category={coursePreview.category}
              title={coursePreview.title}
              progress={coursePreview.progress}
              url={coursePreview.url}
              urlClass={coursePreview.urlClass}
              phasesTotal={coursePreview.phasesTotal}
              phasesComplete={coursePreview.phasesComplete}
              startDate={parseISO(coursePreview.startDate)}
              endDate={parseISO(coursePreview.endDate)}
              situation={coursePreview.situation}
              docsTotal={coursePreview.docsTotal}
              docsComplete={coursePreview.docsComplete}
              urlDocs={ROUTES.DOCS_SEE_ALL}
              disciplineProgress={coursePreview.disciplineProgress}
              disciplineTotal={coursePreview.disciplineTotal}
              disciplineComplete={coursePreview.disciplineComplete}
            />
          )}
          {statistics.curso_concluido && (
            <Grid container spacing={3}>
              <Grid item className="boxInfos" sm={3}>
                <BoxInfo
                  type="finishedCourses"
                  quantity={statistics.curso_concluido.qtd}
                />
              </Grid>
              <Grid item className="boxInfos" sm={3}>
                <BoxInfo
                  type="coursesInProgress"
                  quantity={statistics.curso_andamento.qtd}
                />
              </Grid>
            </Grid>
          )}

          <div className="info-grid">
            <div className="communications-grid-area">
              {announcements && (
                <BoxListSimple
                  title={_e({ id: "dashboard.announcements" })}
                  linkAll={ROUTES.ANNOUNCEMENTS}
                  data={announcements}
                />
              )}
            </div>
            <div className="calendar-grid-area">
              <Calendar
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                calendar={calendar}
              />
            </div>
            <div className="activities-grid-area">
              {activities && (
                <BoxListLarge
                  title={_e({ id: "dashboard.recentActivity" })}
                  subtitle={_e({ id: "dashboard.recentActivityDesc" })}
                  linkAll="/"
                  data={activities}
                />
              )}
              <img
                id="icon_work"
                src={IconWorkProgress}
                alt="Work in progress Icon"
              />
            </div>
          </div>
        </>
      )}
    </Container>
  );
}
