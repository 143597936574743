import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import PropTypes from "prop-types";

import { ROUTES, PUBLIC } from "~/constants/routes";
import Account from "~/layouts/Account";
import AuthLayout from "~/layouts/Auth";
import DefaultLayout from "~/layouts/Default";
import { store } from "~/store";
import { signOut } from "~/store/modules/auth/actions";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isAccount,
  ...rest
}) {
  const { pages, userInfo } = useSelector((state) => state.auth);
  const { signed } = store.getState().auth;
  const dispatch = useDispatch();

  let Layout = AuthLayout;

  if (rest.path === ROUTES.LOGIN) {
    dispatch(signOut());
  }

  if (!signed && (isPrivate || isAccount)) {
    return <Redirect to={ROUTES.DEFAULT} />;
  }

  if (signed && !isPrivate && !isAccount) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  if (isPrivate) {
    Layout = DefaultLayout;
  }

  if (isAccount) {
    Layout = Account;
  }

  if (!signed) {
    Layout = AuthLayout;
  }

  let url = [];
  if (pages) {
    url = pages.find(
      (item) =>
        item.url === rest.path && item.registration === userInfo.id_matricula
    );
  }

  if (!url && !PUBLIC.find((route) => route === rest.path)) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isAccount: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isAccount: false,
};
