import produce from "immer";

const INITIAL_STATE = {
  documents: [],
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@documents/GET_DOCUMENTS_REQUEST":
        break;
      case "@documents/GET_DOCUMENTS_SUCCESS":
        draft.documents = action.payload.data;
        break;
      default:
        return state;
    }
  });
}
