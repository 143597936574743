import styled from "styled-components";

export const Container = styled.div`
  max-width: 1200px;
  padding-left: 100px;
  padding-top: 100px;
  padding-bottom: 50px;
  h2 {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
    margin-bottom: 10px;
  }
  .related-course {
    margin-top: 10px;
  }

  .iconArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 40px;
    background-color: ${({ theme }) =>
      theme.palette.tertiary[theme.palette.type]};
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .icon {
    color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
  }
`;
