import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  studyPlan: [],
  course: {},
  mat_matricula: {},
};

export default function studyPlan(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@studyPlan/GET_STUDY_PLAN_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@studyPlan/GET_STUDY_PLAN_SUCCESS": {
        draft.loading = false;
        draft.studyPlan = action.payload.data.studyPlan;
        draft.course = action.payload.data.course;
        draft.mat_matricula = action.payload.data.mat_matricula;
        break;
      }
      case "@studyPlan/GET_STUDY_PLAN_FAILURE": {
        draft.loading = false;
        break;
      }
      case "@studyPlan/POST_STUDY_PLAN_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@studyPlan/POST_STUDY_PLAN_SUCCESS": {
        draft.loading = false;
        break;
      }
      case "@studyPlan/POST_STUDY_PLAN_FAILURE": {
        draft.loading = false;
        break;
      }
      case "@studyPlan/PUT_STUDY_PLAN_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@studyPlan/PUT_STUDY_PLAN_SUCCESS": {
        draft.loading = false;
        break;
      }
      case "@studyPlan/PUT_STUDY_PLAN_FAILURE": {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
