export function getPhoneTypeRequest() {
  return {
    type: "@phone/GET_PHONE_TYPE_REQUEST",
  };
}

export function getPhoneTypeSuccess(data) {
  return {
    type: "@phone/GET_PHONE_TYPE_SUCCESS",
    payload: { data },
  };
}

export function getCEPRequest({ cep }) {
  return {
    type: "@phone/GET_CEP_REQUEST",
    payload: { cep },
  };
}
export function getCEPSuccess(data) {
  return {
    type: "@phone/GET_CEP_SUCCESS",
    payload: { data },
  };
}
