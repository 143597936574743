import { takeLatest, all, call, put } from "redux-saga/effects";

import api from "~/services/api";

import { themeSuccess, themeFailure } from "./actions";

export function* getTheme() {
  const id_instituicao = process.env.REACT_APP_ID_INSTITUICAO;
  try {
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_FILE_URL}/customizacao/${id_instituicao}.json`
    );

    if (response.status !== 200) throw new Error("Erro ao carregar tema");

    yield put(themeSuccess(response.data));
  } catch (err) {
    return yield put(themeFailure());
  }
}

export default all([takeLatest("@theme/GET_THEME_REQUEST", getTheme)]);
