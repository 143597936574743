import styled from "styled-components";

/**
 * @todo box-shadow com cor hardcode
 */
export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
  border: 1px solid
    ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;

  .situation_info {
    position: absolute;
    right: 19px;
    border-radius: 4px;
    padding: 5px;
    top: 160px;
    text-align: center;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 13px;
  }

  .preview {
    background-color: ${({ theme }) =>
      theme.palette.tertiary[theme.palette.type]};
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
  }
  .firstColumn {
    width: 250px;
    height: auto;
    margin: auto;
    padding: 10px;
    position: relative;
    text-align: center;
  }

  input[type="file"] {
    display: none;
  }

  .link {
    display: inline-flex;
    cursor: pointer;
    p {
      color: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
      font-weight: 600;
      font-size: 16px;
      margin: 0;
      height: 16px;
      margin-top: 7px;
    }
    &:hover {
      filter: contrast(0.2);
    }
  }
  .profile {
    height: 260px;
    display: grid;
    grid-template-columns: 20% 1fr;
    grid-auto-flow: column;
  }
  .iconPhoto {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .bodyPerfil {
    display: flex;
    justify-content: space-around;
    position: relative;
    top: 35px;
    width: 100%;
    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
      margin-right: 4px;
    }
    .box-body {
      border: 1px solid
        ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      padding: 20px;
      &:first-child {
        width: 40%;
      }
    }
    .text {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
      text-transform: capitalize;
    }
    .manage-enrollment {
      min-width: 195px;
      height: 50px;
      background: ${({ theme }) => theme.palette.primary[theme.palette.type]};
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
      text-decoration: none;
      transition: all 0.4s ease;
      margin-left: 60px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .course-preview-content {
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-left: 1px solid #ebebeb;
    .top {
      display: flex;
      justify-content: space-between;
      align-self: flex-start;
      align-items: center;
    }
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .category {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 11px;
        margin-bottom: 7px;
        letter-spacing: 0.84375px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
      }
      .title {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme.palette.text[theme.palette.type]};
        text-transform: capitalize;
      }
    }
    .bottom {
      position: absolute;
      margin-top: 20px;
      bottom: 30px;

      .progress-area {
        display: flex;
        p {
          margin: 0;
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.4375px;
          color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
        }
        .progress-mts {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
          .bar-bg {
            display: block;
            background: ${({ theme }) =>
              theme.palette.quaternary[theme.palette.type]};
            border-radius: 5px;
            width: 207px;
            height: 5px;
            margin-right: 15px;
            position: relative;
            border-radius: 5px;
            height: 5px;
            .bar {
              display: block;
              background: ${({ theme }) =>
                theme.palette.primary[theme.palette.type]};
              width: 80%;
              border-radius: 5px;
              height: 5px;
            }
          }
        }
        .porcent {
          font-weight: 800;
          line-height: 13px;
          color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
        }
      }
    }
  }
`;
