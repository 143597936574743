import styled from "styled-components";

export const Container = styled.div`
  overflow-x: hidden;
  margin-left: 30px;
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  .area-loadding {
    display: block;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  position: absolute;
  z-index: 1;
`;

export const BtnCallback = styled.a`
  z-index: 1;
  margin-bottom: 20px;
  width: 91px;
  span {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  p {
    margin-bottom: 0;
    color: #000;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 18px;
    text-decoration: none !important;
  }
  display: flex;
  align-items: center;
  transition: opacity 0.2s ease;
  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
`;

export const Content = styled.div`
  margin-left: 120px;
  margin-top: 30px;
  overflow: hidden;
  p {
    color: grey;
  }
`;

export const DivCardPerfil = styled.div`
  display: flex;
  width: 100%;
`;

export const CardPerfil = styled.div`
  position: relative;
  border: 1px solid
    ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
  background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-right: 70px;
  padding: 20px;
  margin-left: 20px;
  margin-bottom: 50px;
  margin-top: 50px;
  width: 218px;
  height: 200px;
  img {
    width: 27px;
  }
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text[theme.palette.type]};
  margin-bottom: 15px;
  margin-top: 40px;
`;

export const ModulesName = styled.div`
  position: absolute;
  bottom: 30px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text[theme.palette.type]};
`;

export const ProfileTitle = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text[theme.palette.type]};
  margin-bottom: 15px;

  .edit {
    font-weight: 700;
    font-size: 0.9rem;
    text-decoration: underline;
    margin-left: 10px;
    display: inline;
    width: auto;
    color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
  }
`;
