/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import { User } from "~/images";
import {
  deleteFileRequest,
  postCommentReplyRequest,
  postReportCommentRequest,
} from "~/store/modules/comments/actions";

import {
  Container,
  Top,
  CommentHeader,
  Bottom,
  Files,
  File,
  Options,
  ReplyContainer,
  CommentReplyContainer,
  ReportBox,
} from "./styles";

export default function Comment({ comment, id_aula, id_curso }) {
  const { id_login } = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();

  const [replyState, setReplyState] = useState(false);
  const [reportState, setReportState] = useState(false);
  const [newReply, setNewReply] = useState("");

  const handleOpenCloseReport = () => setReportState(!reportState);

  const urlAvatar = comment.pes_login.ds_foto
    ? `${process.env.REACT_APP_API_FILE_URL}/config/${comment.pes_login.ds_foto}`
    : User;

  async function handleText(data) {
    setNewReply(data.currentTarget.value);
  }

  const handleReport = (id_reporte_motivo) => {
    handleOpenCloseReport();
    dispatch(
      postReportCommentRequest({
        id_reporte_motivo,
        id_comentario: comment.crs_comentario_aula.id_comentario,
      })
    );
  };

  return (
    <Container key={comment.crs_comentario_aula.id_comentario}>
      <Top>
        <CommentHeader>
          <img src={urlAvatar} alt="Avatar" />
          <div>
            <h3>{comment.pes_pessoa.ds_nome}</h3>
            <p className="post_date">
              Postou {comment.crs_comentario_aula.dt_cadastro}
            </p>
          </div>
        </CommentHeader>

        <p className="comment">{comment.crs_comentario_aula.ds_comentario}</p>

        {comment.resposta.length > 0 && (
          <CommentReplyContainer>
            {comment.resposta.map((resposta, index) => (
              <div key={index}>
                <CommentHeader>
                  <img
                    src={
                      resposta.pes_login.ds_foto
                        ? `${process.env.REACT_APP_API_FILE_URL}/config/${resposta.pes_login.ds_foto}`
                        : User
                    }
                    alt="Avatar"
                  />
                  <div>
                    <h3>{resposta.pes_pessoa.ds_nome}</h3>
                    <p className="post_date">
                      Postou {resposta.crs_comentario_resposta.dt_cadastro}
                    </p>
                  </div>
                </CommentHeader>

                <p className="comment">
                  {resposta.crs_comentario_resposta.ds_resposta}
                </p>
              </div>
            ))}
          </CommentReplyContainer>
        )}
      </Top>

      <Bottom>
        <Files>
          {comment.arquivo.length > 0 &&
            comment.arquivo.map((arq, index) => (
              <File key={index}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${process.env.REACT_APP_API_FILE_URL}/comentario/${arq.crs_comentario_arquivo.ds_arquivo}`}
                >
                  {arq.crs_comentario_arquivo.ds_arquivo}
                </a>
                {comment.pes_login.id_login === id_login && (
                  <button
                    onClick={() =>
                      dispatch(
                        deleteFileRequest({
                          id_arquivo: arq.crs_comentario_arquivo.id_arquivo,
                        })
                      )
                    }
                    type="button"
                  >
                    x
                  </button>
                )}
              </File>
            ))}
        </Files>
        {replyState ? (
          <ReplyContainer>
            <input
              type="text"
              onChange={handleText}
              placeholder="Responda o comentário"
            />
            <button
              onClick={() => {
                setReplyState(false);
                dispatch(
                  postCommentReplyRequest({
                    id_comentario: comment.crs_comentario_aula.id_comentario,
                    ds_resposta: newReply,
                    id_curso,
                    id_aula,
                  })
                );
              }}
              type="button"
            >
              <span>RESPONDER</span>
            </button>
          </ReplyContainer>
        ) : (
          <Options>
            <ReportBox visible={reportState}>
              <button
                className="close"
                type="button"
                onClick={handleOpenCloseReport}
              >
                <MdClose style={{ height: 24, width: 24 }} />
              </button>
              <div className="text-container">
                <h3>Reportar Comentário</h3>
                <p>
                  Se por algum motivo você acha que o comentário é inapropriado,
                  justifique abaixo
                </p>
              </div>
              <div className="button-container">
                <button type="button" onClick={() => handleReport(1)}>
                  SPAM
                </button>
                <button type="button" onClick={() => handleReport(2)}>
                  É IMPRÓPRIO
                </button>
              </div>
            </ReportBox>

            <button
              style={{
                visibility:
                  comment.pes_login.id_login !== id_login
                    ? "initial"
                    : "hidden",
              }}
              onClick={handleOpenCloseReport}
              type="button"
            >
              Reportar
            </button>
            <button onClick={() => setReplyState(!replyState)} type="button">
              Responder
            </button>
          </Options>
        )}
      </Bottom>
    </Container>
  );
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  id_aula: PropTypes.number.isRequired,
  id_curso: PropTypes.string.isRequired,
};
