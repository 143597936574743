/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Form } from "@unform/web";

import { Title, Loading, SearchBar, Questions, Select } from "~/components";
import {
  getFaqSearchRequest,
  getFaqQuestionsRequest,
  getFaqCategoriesRequest,
} from "~/store/modules/faq/actions";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";

import { Container } from "./styles";

function Faq() {
  const dispatch = useDispatch();
  const faq = useSelector((state) => state.faq);
  const { questions, categories, isLoading } = faq;
  const { formatMessage: _e } = useIntl();

  const [selectedCategorie, setSelectedCategorie] = useState(-1);

  useEffect(() => {
    dispatch(getFaqCategoriesRequest());
  }, [dispatch]);

  documentTitle(_e({ id: "faq.pageTitle" }));
  function TitleFAQ() {
    return `${_e({ id: "faq.pageTitle" })}`;
  }

  const handleSearch = (data) => {
    if (data.search.length < 5) {
      toast.error("Pelo menos 5 caracteres");
      return;
    }
    setSelectedCategorie(-1);
    dispatch(getFaqSearchRequest(data.search));
  };

  const selectCategorie = (categorieId) => {
    if (categorieId === -1) return;
    categories.forEach((categorie, index) => {
      if (categorieId === categorie.id) {
        setSelectedCategorie(index);
      }
    });
    dispatch(getFaqQuestionsRequest(categorieId));
  };

  return isEmpty(categories) ? (
    <Loading />
  ) : (
    <Container>
      <div className="title-container">
        <div>
          <Title>
            <TitleFAQ />
          </Title>
          <p>{_e({ id: "faq.pageSubtitle" })}</p>
        </div>
      </div>

      <Form className={'form-search "active'} onSubmit={handleSearch}>
        <SearchBar
          placeholder={_e({ id: "faq.searchBarPlaceHolder" })}
          name="search"
          type="text"
        />
      </Form>
      <p>{_e({ id: "faq.searchBarSubtitle" })}</p>

      {/* <div className="card-container">
        {categories.map((categorie, idx )=>
          <SimpleCard 
          onClick={() => {
            selectCategorie(idx)
          }} 
          key={categorie.id} 
          title={categorie.title}/>
        )}
      </div> */}
      <div className="select-container">
        <Select
          label="Categorias"
          onChange={(categorie) => selectCategorie(categorie.value)}
          items={categories}
          defaultValue={{ value: -1, label: _e({ id: "faq.option" }) }}
        />
      </div>

      <div className="questions">
        {isLoading ? (
          <Loading />
        ) : (
          <Questions
            className="questions"
            title={
              selectedCategorie !== -1
                ? categories[selectedCategorie].label
                : ""
            }
            questions={questions}
          />
        )}
      </div>
    </Container>
  );
}

export default Faq;
