import React from "react";

import PropTypes from "prop-types";

import { ButtonText, MenuButton } from "./styles";

export default function MyCoursesMenuButton({
  children,
  selected,
  length,
  ...rest
}) {
  return (
    <MenuButton selected={selected} {...rest}>
      <ButtonText selected={selected}>
        {children} <span>({length})</span>
      </ButtonText>
    </MenuButton>
  );
}

MyCoursesMenuButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  selected: PropTypes.bool.isRequired,
  length: PropTypes.number.isRequired,
};
