import React, { useState, useEffect } from "react";
import {
  MdKeyboardArrowDown,
  MdNotificationsNone,
  MdSearch,
  MdNotifications,
} from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ContainerMD from "@material-ui/core/Container";
import { Form } from "@unform/web";
import { parseISO } from "date-fns";

import { Search } from "~/components";
import { ROUTES } from "~/constants/routes";
import { LogoWhite, User } from "~/images";
import { signOut } from "~/store/modules/auth/actions";
import {
  getNotifysRequest,
  postNotifysRequest,
} from "~/store/modules/notifys/actions";
import { getSearchRequest } from "~/store/modules/search/actions";
import { formatDate } from "~/utils/date";

import { Header } from "./styles";

export default function HeaderTheme() {
  const [account, setAccount] = useState(false);
  const [notify, setNotify] = useState(false);
  const [search, setSearch] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const { formatMessage: _e } = useIntl();

  const dispatch = useDispatch();
  const { notifications, unseen } = useSelector((state) => state.notifys);
  const { userInfo, pages } = useSelector((state) => state.auth);
  const urlAvatar = userInfo.ds_foto
    ? `${process.env.REACT_APP_API_FILE_URL}/config/${userInfo.ds_foto}`
    : User;

  useEffect(() => {
    dispatch(getNotifysRequest({ from: 0, to: 50 }));
  }, [dispatch]);

  function handleAccount() {
    setAccount(!account);
    setNotify(false);
  }

  function handleNotify() {
    setNotify(!notify);
    setAccount(false);
  }

  const handleClickAway = () => {
    setNotify(false);
    setAccount(false);
  };

  const handleLogout = () => {
    dispatch(signOut());
  };

  const handleSearch = (data) => {
    dispatch(getSearchRequest(data.search));
    history.push(`${ROUTES.SEARCH}`);
  };

  const handleVisulizeNotify = (item) => {
    if (item.mat_notificacao_destinatario.tp_msg_lida === "N") {
      const { id_notificacao } = item.mat_notificacao;
      dispatch(
        postNotifysRequest({
          id_notificacao,
        })
      );
    }
  };

  return (
    <Header>
      <ClickAwayListener onClickAway={handleClickAway}>
        <ContainerMD maxWidth="lg">
          <div className="header-wrapper">
            <div className="left">
              <Link to={ROUTES.DASHBOARD}>
                <img src={LogoWhite} alt="logo" />
              </Link>
            </div>
            <div className="right">
              <Form
                className={`form-search ${search && "active"}`}
                onSubmit={handleSearch}
              >
                <Search
                  placeholder={_e({ id: "header.searchHere" })}
                  name="search"
                  type="text"
                  handleClose={() => setSearch(false)}
                />
              </Form>
              <div className="area-icons">
                <button type="button" onClick={() => setSearch(true)}>
                  <MdSearch
                    title="Pesquisa"
                    color={theme.palette.tertiary[theme.palette.type]}
                    size={20}
                  />
                </button>
                <button type="button" onClick={handleNotify}>
                  {notify ? (
                    <MdNotifications
                      title="Notificações"
                      color={theme.palette.tertiary[theme.palette.type]}
                      size={20}
                    />
                  ) : (
                    <MdNotificationsNone
                      title="Notificações"
                      color={theme.palette.tertiary[theme.palette.type]}
                      size={20}
                    />
                  )}
                  {unseen > 0 && <span className="noti-number">{unseen}</span>}
                </button>
                <div className={notify ? "notifys active" : "notifys"}>
                  <div className="top">
                    <div className="title-box">
                      {_e({ id: "header.notifications" })}
                    </div>
                    <Link
                      to={ROUTES.NOTIFYS}
                      onClick={handleClickAway}
                      className="show-all"
                    >
                      {_e({ id: "header.viewAll" })}
                    </Link>
                  </div>
                  {notifications.length > 0 && (
                    <ul>
                      {notifications
                        .filter(
                          (itemNotify) =>
                            itemNotify.mat_notificacao_destinatario
                              .tp_msg_lida === "N"
                        )
                        .slice(0, 5)
                        .map((item) => {
                          const { mat_notificacao } = item;
                          return (
                            <li key={String(mat_notificacao.id_notificacao)}>
                              <div className="content">
                                <div className="category">
                                  {mat_notificacao.ds_titulo}
                                </div>
                                <Link
                                  onClick={() => {
                                    handleVisulizeNotify(item);
                                    handleClickAway();
                                  }}
                                  to={`${ROUTES.NOTIFYS}/${mat_notificacao.id_notificacao}`}
                                  className="title-noti"
                                >
                                  {mat_notificacao.ds_notificacao}
                                </Link>
                                <div className="time">
                                  {formatDate(
                                    parseISO(mat_notificacao.dt_cadastro)
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </div>
              </div>
              <div className="area-account">
                <div className={account ? "account active" : "account"}>
                  <button
                    type="button"
                    className="content"
                    onClick={handleAccount}
                  >
                    <div className="user-info">
                      <span>
                        <img src={urlAvatar} alt="Avatar" className="avatar" />
                      </span>
                      <h6 className="name">{userInfo.ds_nome}</h6>
                    </div>
                    <MdKeyboardArrowDown
                      color={theme.palette.tertiary[theme.palette.type]}
                    />
                  </button>
                  <div className="account-overlay">
                    {pages.length > 0 && (
                      <ul>
                        {pages
                          .filter(
                            (page) =>
                              page.registration === userInfo.id_matricula &&
                              page.position === 4
                          )
                          .map((item, index) => {
                            return (
                              <li key={String(index)}>
                                <Link onClick={handleClickAway} to={item.url}>
                                  {item.title}
                                </Link>
                              </li>
                            );
                          })}
                        <div />
                      </ul>
                    )}
                    <Link
                      to={ROUTES.DEFAULT}
                      onClick={handleLogout}
                      className="exit"
                    >
                      {_e({ id: "header.exit" })}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerMD>
      </ClickAwayListener>
    </Header>
  );
}
