import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import { Form } from "@unform/web";
import md5 from "md5";

import { Button, Input } from "~/components";
import { ROUTES } from "~/constants/routes";
import { Entrar } from "~/images";
import { passwordRecoveryRequest } from "~/store/modules/passwordReset/actions";
import documentTitle from "~/utils/documentTitle";
import { resetPasswordValidator } from "~/validators";

import ConfirmationScreen from "./ConfirmationScreen";
import { Container } from "./styles";

export default function SignIn() {
  const { formatMessage: _e } = useIntl();
  const dispatch = useDispatch();
  const { loading, passwordAltered } = useSelector(
    (state) => state.passwordReset
  );

  documentTitle(_e({ id: "login.forgotYourPassword" }));

  const theme = useTheme();

  const form = useRef(null);

  const [color, setColor] = useState("var(--red)");
  const [password, setPassword] = useState(0);
  const { token } = useParams();

  const Progress = withStyles({
    root: {
      height: 5,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.quaternary[theme.palette.type],
    },
    barColorPrimary: {
      backgroundColor: color,
    },
  })(LinearProgress);

  function scale(num, in_min, in_max, out_min, out_max) {
    return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  }

  function handlePassword({ target }) {
    const colors = [
      "var(--red)",
      "var(--orange)",
      "var(--yellow)",
      "var(--green)",
    ];

    const pass = target.value;

    const validations = [
      pass.length > 0,
      pass.length >= 6,
      pass.search(/[A-Z]/) > -1,
      pass.search(/[0-9]/) > -1,
    ];
    const passedValidations = validations.reduce((acc, cur) => acc + cur, 0);
    const count = scale(passedValidations, 0, validations.length, 0, 100);

    if (count >= 100) {
      setPassword(100);
    } else {
      setPassword(Math.floor(count));
    }

    return setColor(colors[passedValidations > 0 ? passedValidations - 1 : 0]);
  }

  async function handleSubmit(data) {
    const isValid = await resetPasswordValidator(data, form, _e);

    // passe os dados e o formulário
    if (!isValid) return;

    dispatch(passwordRecoveryRequest({ password: md5(data.password), token }));
  }

  if (passwordAltered) return <ConfirmationScreen />;

  /**
   * @todo alt hardcode sem intl
   * @todo remover bootstrap
   */
  return (
    <Container>
      <Form ref={form} onSubmit={handleSubmit}>
        <div className="signin">
          <img src={Entrar} alt="Entrar" />{" "}
          {_e({ id: "login.resetYourPassword" })}
        </div>
        <Input
          label={_e({ id: "login.newPassword" })}
          name="password"
          type="password"
          autoComplete="off"
          required
          onChange={handlePassword}
        />
        <div className="lengthPassword">
          <p>{_e({ id: "login.passwordStrength" })}</p>
          <Progress variant="determinate" value={password} />
        </div>
        <Input
          label={_e({ id: "login.confirmPassword" })}
          name="confirmPassword"
          type="password"
          autoComplete="off"
          required
        />
        <Button loading={loading} type="submit">
          {_e({ id: "login.send" })}
        </Button>
      </Form>
      <p className="cancel-text">
        <Link to={ROUTES.DEFAULT}>{_e({ id: "login.cancel" })}</Link>
      </p>
    </Container>
  );
}
