import { combineReducers } from "redux";

import academicSuport from "./academicSuport/reducer";
import annotations from "./annotations/reducer";
import announcements from "./announcements/reducer";
import auth from "./auth/reducer";
import comments from "./comments/reducer";
import course from "./course/reducer";
import dashboard from "./dashboard/reducer";
import documents from "./documents/reducer";
import faq from "./faq/reducer";
import favorites from "./favorites/reducer";
import likedislikes from "./likedislike/reducer";
import marker from "./marker/reducer";
import modules from "./module/reducer";
import notes from "./notes/reducer";
import notifys from "./notifys/reducer";
import passwordReset from "./passwordReset/reducer";
import phone from "./phone/reducer";
import profile from "./profile/reducer";
import relatedCourse from "./relatedCourse/reducer";
import search from "./search/reducer";
import statistics from "./statistics/reducer";
import studyPlan from "./studyPlan/reducer";
import theme from "./theme/reducer";
import tutors from "./tutors/reducer";
import videos from "./videos/reducer";

export default combineReducers({
  academicSuport,
  annotations,
  announcements,
  auth,
  comments,
  course,
  dashboard,
  faq,
  favorites,
  likedislikes,
  marker,
  modules,
  notes,
  notifys,
  passwordReset,
  relatedCourse,
  search,
  statistics,
  theme,
  videos,
  tutors,
  studyPlan,
  documents,
  profile,
  phone,
});
