import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 270px;
    width: fit-content;
    height: 70px;
    background: ${({ theme }) => theme.palette.button[theme.palette.type]};
    border-radius: 5px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.textButton[theme.palette.type]};
    outline: none;
    transition: all 0.4s ease;
    text-decoration: none;
    &:hover {
      background: ${({ theme }) => theme.palette.button[theme.palette.type]};
      opacity: 0.7;
    }
  }
`;

export const ButtonStyles = withStyles((theme) => ({
  root: {
    background: theme.palette.button[theme.palette.type],
    borderRadius: "5px",
    transition: "all 0.4s ease",
    width: "100%",
    height: 60,
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: 16,
    textAlign: "center",
    textTransform: "uppercase",
    color: theme.palette.textButton[theme.palette.type],
    "&:hover": {
      background: theme.palette.button[theme.palette.type],
      opacity: 0.7,
    },
    "&:disabled": {
      opacity: 0.7,
      color: theme.palette.textButton[theme.palette.type],
    },
  },
}))(Button);
