const enUS = {
  return: "Return",
  account: "My account",
  hello: "Hi",
  welcome: " welcome again.",
  myProfile: "My profile",
  edit: "edit",
  changePic: "Change your picture",
  profileFilling: "profile filling",
  manageEnrollments: "Manage enrollments",
  subAndPayment: "Subscriptions and payments",
  myScore: "My notes",
  statistic: "Statistic",
  certificate: "Certificates",
};

const ptBR = {
  return: "Voltar",
  account: "Minha conta",
  hello: "Olá",
  welcome: " seja bem-vindo novamente.",
  myProfile: "Meu perfil",
  edit: "Editar",
  changePic: "Alterar foto",
  profileFilling: "Preenchimento do Perfil",
  manageEnrollments: "Gerenciar matrículas",
  subAndPayment: "Assinaturas e pagamentos",
  myScore: "Minhas notas",
  statistic: "Estatísticas",
  certificate: "Certificados",
};

export default { enUS, ptBR };
