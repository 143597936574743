import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import PropTypes from "prop-types";

import { Container } from "./styles";

export default function SelectTheme({
  label,
  items,
  defaultValue,
  onChange,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(
    defaultValue.value ? defaultValue : items[0]
  );

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (item) => {
    setSelected(item);
    setIsOpen(false);
    onChange(item);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Container isOpen={isOpen} {...rest} onClick={toggleMenu}>
        <div className="select">
          <span className="label">{label}</span>
          <div className="selected-value">{selected.label}</div>
          <MdKeyboardArrowDown className="dropdown-icon" />
        </div>
        <ul className="options">
          {items?.map((item) => (
            <li
              className="option"
              key={item.label}
              onClick={() => handleInputChange(item)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </Container>
    </ClickAwayListener>
  );
}

SelectTheme.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  defaultValue: PropTypes.object,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

/**
 * @todo valor default campo fora da internacionalização
 */
SelectTheme.defaultProps = {
  label: "Campo",
  defaultValue: {},
  required: false,
  onChange: () => {},
};
