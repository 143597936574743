import React, { useRef } from "react";
import { FaBookReader } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { useTheme } from "@material-ui/core";
import PropTypes from "prop-types";

import "slick-carousel/slick/slick.css";
import { weekDays, weekDay, isDateEquals } from "~/utils/date";
import { isEmpty } from "~/utils/object";
import { capitalize } from "~/utils/stringUtils";

import { Container, Week } from "./styles";

const today = new Date();
const month = capitalize(today.toLocaleString("pt-BR", { month: "long" }));
const daysOfweek = weekDays(new Date());

const getNameOfDay = (date) => {
  return date
    .toLocaleDateString("en-US", {
      weekday: "short",
    })
    .toLowerCase();
};

export default function CourseStudyPlan({ title, description, studyPlan }) {
  const sliderRef = useRef();
  const theme = useTheme();

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    !isEmpty(studyPlan) && (
      <Container>
        <div className="studies-title-container">
          <div>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
          <div className="arrows-slick">
            <button type="button" onClick={() => sliderRef.current.slickPrev()}>
              <MdKeyboardArrowLeft
                color={theme.palette.primary[theme.palette.type]}
                size={20}
              />
            </button>
            <button type="button" onClick={() => sliderRef.current.slickNext()}>
              <MdKeyboardArrowRight
                color={theme.palette.primary[theme.palette.type]}
                size={20}
              />
            </button>
          </div>
        </div>
        <h3 className="month-title">{month}</h3>
        <Slider {...settings} ref={sliderRef} className="slider-content">
          {studyPlan.map((week, index) => (
            <Week key={index.toString()}>
              {daysOfweek.map((day) => (
                <div key={day.getDate()}>
                  <div className="header">
                    <span>{weekDay(day).toUpperCase()}</span>
                    <span>{day.getDate()}</span>
                  </div>
                  <div
                    className={`body ${isDateEquals(today, day) && "current"}`}
                  >
                    {week.studyDays[getNameOfDay(day)] && (
                      <div className="item-event" key={day.getDate()}>
                        <FaBookReader
                          color={theme.palette.primary[theme.palette.type]}
                          size={20}
                        />
                        <div>
                          <span className="title-event">
                            {`${week.studyDays.averageHours} HORAS `}
                          </span>
                          <br />
                          <span>DE ESTUDO.</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Week>
          ))}
        </Slider>
        <Link className="edit-button" to="/plano-estudo">
          Editar meu plano de estudo
        </Link>
      </Container>
    )
  );
}

CourseStudyPlan.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  studyPlan: PropTypes.array,
};

CourseStudyPlan.defaultProps = {
  title: "",
  description: "",
  studyPlan: [{}],
};
