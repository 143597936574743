import styled, { keyframes, css } from "styled-components";

const appearFromRight = keyframes`
  from {
    opacity: 0;
    right: -360px;
  }
  to {
    opacity: 1;
    right: 0;
  }
`;

export const Container = styled.div`
  display: inline;
  position: absolute;
  min-height: 930px;
  width: 360px;
  z-index: 3;
  top: 120px;
  right: -360px;
  border: 1px solid #efefef;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06),
    10px 2px 70px 0 rgba(0, 0, 0, 0.5);

  ${(props) =>
    props.show === null &&
    css`
      display: none;
    `}

  ${(props) =>
    props.show === true
      ? css`
          animation: ${appearFromRight} 0.5s forwards;
        `
      : css`
          animation: ${appearFromRight} 0.5s backwards;
          display: none;
        `}
`;

export const InputContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  box-sizing: border-box;
  align-items: center;
  height: 50px;
  width: 302px;
  border: 2px solid #5433f1;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.1);

  img {
    margin-left: 16px;
    margin-right: 16px;
    height: 22px;
    width: 22px;
  }

  input {
    margin-right: 18px;
    border: none;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 25px;
  }
`;

export const NoteItem = styled.div`
  padding: 0px 30px;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
  background: none;
  border: none;
  border-bottom: 1px solid #ececec;

  button {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 30px;
    margin-right: 0;
    padding: 0;
    background: none;
    border: none;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    div:first-of-type {
      width: 202px;

      p:first-of-type {
        font-size: 14px;
        color: black;
        font-weight: 700;
        ${(props) =>
          !props.isActive &&
          css`
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          `}
      }
    }
  }

  p {
    margin-bottom: 6px;
  }

  .arrow {
    ${(props) =>
      props.isActive &&
      css`
        transform: rotate(90deg);
      `}
  }

  .date {
    color: #7b7b7b;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
`;

export const Header = styled.div`
  padding: 19px 30px;
  display: flex;
  flex-direction: column;

  p {
    color: #7b7b7b;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 60px;
  }

  button {
    align-self: flex-end;
    background: none;
    border: none;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-items: center;
    /*
    span {
      align-items: center;
      justify-items: center;
      font-size: 20px;
      color: #5433f1;
      font-weight: 600;
    } */
  }

  & > div:first-of-type {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;

    h2 {
      margin-left: 13px;
      color: #1f1f1f;
      font-family: Montserrat;
      font-size: 19px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 23px;
    }
  }
`;

export const NewNode = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  textarea {
    height: 180px;
    border: 2px solid #efefef;
    border-radius: 3px;
    margin-bottom: 15px;
    resize: none;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 14px;

    button {
      width: auto;
      border: none;
      background: none;
      display: inline;
      flex-grow: 0;
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.button[theme.palette.type]};
    }
  }
  .edit-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    flex-grow: 1;
    button.white-button {
      background: #fff;
      color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
      border: 2px solid #b7c3f866;
    }
    button {
      padding: 8px 25px;
    }
  }
`;
