/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import {
  getAnnotationsSuccess,
  getAnnotationsFailure,
  postAnnotationsSuccess,
  postAnnotationsFailure,
  editAnnotationsSuccess,
  editAnnotationsFailure,
} from "./actions";

export function* getAnnotations({ payload }) {
  const { id_curso, id_aula } = payload;
  const { id_login, id_matricula } = yield select(userInfo);

  try {
    const response = yield call(api.get, API.NOTES_SUBJECTS_LIST, {
      id_aula,
      id_curso,
      id_login,
      id_matricula,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(getAnnotationsSuccess(response.data.data[0]));
  } catch (err) {
    toast.error(err.message);
    return yield put(getAnnotationsFailure());
  }
}

export function* postAnnotations({ payload }) {
  const { id_aula, id_disciplina, ds_anotacao } = payload;
  const { id_login, id_matricula } = yield select(userInfo);

  try {
    const response = yield call(api.post, API.NOTES_SUBJECTS_CREATE, {
      id_login,
      id_aula,
      id_disciplina,
      id_matricula,
      ds_anotacao,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield getAnnotations({ payload: { id_aula } });
    yield put(postAnnotationsSuccess());
  } catch (err) {
    toast.error(err.message);
    return yield put(postAnnotationsFailure());
  }
}

export function* editAnnotations({ payload }) {
  const { id_anotacao, ds_anotacao, id_aula, id_curso } = payload;
  const { id_login } = yield select(userInfo);

  try {
    const response = yield call(api.put, API.NOTES_SUBJECTS_EDIT, {
      id_anotacao,
      ds_anotacao,
      id_login,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield getAnnotations({ payload: { id_aula, id_curso } });
    yield put(editAnnotationsSuccess());
  } catch (err) {
    toast.error(err.message);
    return yield put(editAnnotationsFailure());
  }
}

export function* deleteAnnotations({ payload }) {
  const { id_anotacao, id_aula, id_curso } = payload;
  const { id_login } = yield select(userInfo);

  try {
    const response = yield call(api.delete, API.NOTES_SUBJECTS_DELETE, {
      id_anotacao,
      id_login,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield getAnnotations({ payload: { id_aula, id_curso } });
    yield put(editAnnotationsSuccess());
  } catch (err) {
    toast.error(err.message);
    return yield put(editAnnotationsFailure());
  }
}

export default all([
  takeLatest("@annotations/GET_ANNOTATIONS_REQUEST", getAnnotations),
  takeLatest("@annotations/POST_ANNOTATIONS_REQUEST", postAnnotations),
  takeLatest("@annotations/EDIT_ANNOTATIONS_REQUEST", editAnnotations),
  takeLatest("@annotations/DELETE_ANNOTATIONS_REQUEST", deleteAnnotations),
]);
