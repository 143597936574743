import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { Container } from "./styles";

export default function Sidebar() {
  const { pages } = useSelector((state) => state.auth);
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <Container>
      {pages.length > 0 && (
        <ul>
          {pages
            .filter(
              (page) =>
                page.registration === userInfo.id_matricula &&
                page.position === 1
            )
            .map((item, index) => {
              return (
                <li key={String(index)}>
                  <NavLink activeClassName="active" to={item.url}>
                    {item.title}
                  </NavLink>
                </li>
              );
            })}
          <div />
        </ul>
      )}
    </Container>
  );
}
