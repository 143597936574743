export function getAnnotationsRequest({ id_curso, id_aula }) {
  return {
    type: "@annotations/GET_ANNOTATIONS_REQUEST",
    payload: { id_curso, id_aula },
  };
}

export function getAnnotationsSuccess(data) {
  return {
    type: "@annotations/GET_ANNOTATIONS_SUCCESS",
    payload: { data },
  };
}

export function getAnnotationsFailure() {
  return {
    type: "@annotations/GET_ANNOTATIONS_FAILURE",
  };
}

export function postAnnotationsRequest({
  id_aula,
  id_disciplina,
  ds_anotacao,
}) {
  return {
    type: "@annotations/POST_ANNOTATIONS_REQUEST",
    payload: { id_aula, id_disciplina, ds_anotacao },
  };
}

export function postAnnotationsSuccess() {
  return {
    type: "@annotations/POST_ANNOTATIONS_SUCCESS",
  };
}

export function postAnnotationsFailure() {
  return {
    type: "@annotations/POST_ANNOTATIONS_FAILURE",
  };
}

export function editAnnotationsRequest({ id_aula, id_anotacao, ds_anotacao }) {
  return {
    type: "@annotations/EDIT_ANNOTATIONS_REQUEST",
    payload: { id_aula, id_anotacao, ds_anotacao },
  };
}

export function editAnnotationsSuccess() {
  return {
    type: "@annotations/EDIT_ANNOTATIONS_SUCCESS",
  };
}

export function editAnnotationsFailure() {
  return {
    type: "@annotations/EDIT_ANNOTATIONS_FAILURE",
  };
}

export function deleteAnnotationsRequest({ id_aula, id_anotacao }) {
  return {
    type: "@annotations/DELETE_ANNOTATIONS_REQUEST",
    payload: { id_aula, id_anotacao },
  };
}

export function deleteAnnotationsSuccess() {
  return {
    type: "@annotations/DELETE_ANNOTATIONS_SUCCESS",
  };
}

export function deleteAnnotationsFailure() {
  return {
    type: "@annotations/DELETE_ANNOTATIONS_FAILURE",
  };
}
