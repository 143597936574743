import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  notifications: [],
  total: 0,
  unseen: 0,
};

export default function notifys(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@notifys/GET_NOTIFYS_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@notifys/GET_NOTIFYS_SUCCESS": {
        const { data: notifications, total } = action.payload.data;
        draft.loading = false;
        draft.notifications = notifications;
        draft.total = total.qtd_documento;
        draft.unseen = total.qtd_nao_visualizados;
        break;
      }
      case "@notifys/GET_NOTIFYS_FAILURE": {
        draft.loading = false;
        break;
      }
      case "@notifys/POST_NOTIFYS_SUCCESS": {
        draft.unseen -= 1;
        break;
      }

      default:
    }
  });
}
