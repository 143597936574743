/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { IconNotes, IconRightArrow, IconSearch, IconExit } from "~/images";
import {
  postAnnotationsRequest,
  deleteAnnotationsRequest,
  editAnnotationsRequest,
} from "~/store/modules/annotations/actions";
import { formatDate } from "~/utils/date";

import { Button } from "..";

import Loading from "../Loading";
import Pagination from "../Pagination";
import { Container, NoteItem, Header, InputContainer, NewNode } from "./styles";

export default function FavoritesDrawer({ show, closeList }) {
  const dispatch = useDispatch();
  const [notesList, setFavoritesList] = useState(null);
  const [notesDisplay, setNotesDisplay] = useState([]);
  const [notesLength, setFavoritesLength] = useState(0);
  const [activeNote, setActiveNote] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [newNoteInput, setNewNoteInput] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [noteChange, setNoteChange] = useState(false);

  const { notes, loading: notesLoading } = useSelector(
    (state) => state.annotations
  );
  const { id_disciplina, id_aula } = useSelector(
    (state) => state.course.activeClass
  );

  const { course_id } = useParams();

  const [page, setPage] = useState(1);

  function validateInput(note) {
    if (!note.trim()) {
      toast.error("Insira uma nota");
      return false;
    }
    if (note.length > 255) {
      toast.error("Nota muito longa! Tamanho máximo 255 caracteres.");
      return false;
    }
    return true;
  }

  function handleNewNote() {
    if (validateInput(newNoteInput)) {
      dispatch(
        postAnnotationsRequest({
          id_disciplina,
          id_aula,
          id_curso: course_id,
          ds_anotacao: newNoteInput,
        })
      );
      setNoteChange(false);
      setNewNoteInput("");
      setActiveNote(null);
    }
  }

  function clearNote() {
    if (noteChange) {
      setDialogOpen(true);
    } else {
      setNewNoteInput("");
      setActiveNote(null);
    }
  }

  const handleDialogClose = () => {
    setNoteChange(false);
    setDialogOpen(false);
    setNewNoteInput("");
    setActiveNote(null);
  };

  function handleEditNote() {
    if (validateInput(newNoteInput)) {
      dispatch(
        editAnnotationsRequest({
          id_aula,
          id_curso: course_id,
          id_anotacao: activeNote.mat_anotacao.id_anotacao,
          ds_anotacao: newNoteInput,
        })
      );
      setNoteChange(false);
      setNewNoteInput("");
      setActiveNote(null);
    }
  }

  const handleSave = () => {
    if (activeNote) {
      handleEditNote();
    } else {
      handleNewNote();
    }
    setNoteChange(false);
    setDialogOpen(false);
    setNewNoteInput("");
    setActiveNote(null);
  };

  async function handleText(data) {
    setSearchInput(data.currentTarget.value);
  }

  function handleSelectNode(node) {
    if (noteChange) {
      setDialogOpen(true);
      return;
    }
    if (!activeNote) {
      setActiveNote(node);
    } else if (
      node.mat_anotacao.id_anotacao === activeNote.mat_anotacao.id_anotacao
    ) {
      setActiveNote(null);
    } else {
      setActiveNote(node);
    }
  }

  function handleDeleteNote() {
    dispatch(
      deleteAnnotationsRequest({
        id_aula,
        id_curso: course_id,
        id_anotacao: activeNote.mat_anotacao.id_anotacao,
      })
    );
    setActiveNote(null);
  }

  useMemo(() => {
    if (notesList !== null) {
      setNotesDisplay(notesList.slice(page > 1 ? (page - 1) * 3 : 0, page * 3));
      setFavoritesLength(notesList.length);
    }
    clearNote();
  }, [notesList, page]);

  useEffect(() => {
    if (notesList && searchInput !== "") {
      clearNote();
      setNotesDisplay([
        ...new Set([
          ...notesList.filter((note) =>
            note.mat_anotacao.ds_anotacao
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          ),
          ...notesList.filter((note) =>
            note.crs_disciplina.ds_disciplina
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          ),
        ]),
      ]);
    } else {
      setNotesDisplay(notesList);
    }
  }, [searchInput]);

  useEffect(() => {
    if (!activeNote) {
      setNewNoteInput("");
      return;
    }
    const annotation = notesDisplay.find(
      (note) =>
        note.mat_anotacao.id_anotacao === activeNote.mat_anotacao.id_anotacao
    );
    if (!annotation) {
      setNewNoteInput("");
      return;
    }
    setNewNoteInput(annotation.mat_anotacao.ds_anotacao);
  }, [activeNote]);

  useEffect(() => {
    if (notes && notes.data) {
      setFavoritesList(notes.data);
    } else {
      setFavoritesList([]);
    }
    clearNote();
    setPage(1);
  }, [notes]);

  useEffect(() => {
    if (activeNote && activeNote.mat_anotacao.ds_anotacao !== newNoteInput) {
      setNoteChange(true);
    }
  }, [newNoteInput]);

  return (
    <Container show={show}>
      <Header>
        <button onClick={() => closeList(false)} type="button">
          <img src={IconExit} alt="Ícone fechar" />
        </button>
        <div>
          <img src={IconNotes} alt="Ícone favoritos" />
          <h2>Minhas anotacões</h2>
        </div>
        <p>Crie e consulte suas anotações aqui.</p>
        <InputContainer>
          <img src={IconSearch} alt="Ícone Busca" />
          <input onChange={handleText} placeholder="Pesquise suas anotações" />
        </InputContainer>
      </Header>
      <div>
        {notesLoading ? (
          <Loading />
        ) : (
          notesDisplay &&
          notesDisplay.map((note, index) => (
            <NoteItem
              type="button"
              isActive={
                activeNote &&
                note.mat_anotacao.id_anotacao ===
                  activeNote.mat_anotacao.id_anotacao
              }
              onClick={() => handleSelectNode(note)}
              key={index}
            >
              <p className="date">
                {formatDate(parseISO(note.mat_anotacao.dt_alteracao))}
              </p>
              <div>
                <div>
                  <p>{note.mat_anotacao.ds_anotacao}</p>
                </div>
                <div>
                  <button type="button">
                    <img
                      className="arrow"
                      src={IconRightArrow}
                      alt="Flecha para a direita"
                    />
                  </button>
                </div>
              </div>
            </NoteItem>
          ))
        )}
      </div>
      <Pagination
        postsPerPage={3}
        totalPosts={notesLength}
        setPaged={(number) => setPage(number)}
        currentPage={page}
      />
      <NewNode>
        <div className="header">
          <p>
            {activeNote
              ? `Última modificação em ${formatDate(
                  parseISO(activeNote.mat_anotacao.dt_alteracao)
                ).toLocaleLowerCase()}`
              : "Nova anotação"}
          </p>
          {activeNote && (
            <button onClick={handleDeleteNote} type="button">
              excluir
            </button>
          )}
        </div>
        <textarea
          maxLength={256}
          onChange={(data) => setNewNoteInput(data.currentTarget.value)}
          value={newNoteInput}
        />
        <div className="save-box">
          {!activeNote ? (
            <Button onClick={handleNewNote}>Criar anotação</Button>
          ) : (
            <div className="edit-buttons">
              <Button className="white-button" onClick={clearNote}>
                Nova
              </Button>
              <Button onClick={handleEditNote}>Salvar</Button>
            </div>
          )}
        </div>
      </NewNode>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Gostaria de salvar suas alterações?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Se decidir não salvar, todas as alterações serão perdidas
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Não salvar</Button>
          <Button onClick={handleSave}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

FavoritesDrawer.propTypes = {
  show: PropTypes.bool,
  closeList: PropTypes.func.isRequired,
};

FavoritesDrawer.defaultProps = {
  show: null,
};
