import styled, { css } from "styled-components";

export const Container = styled.div`
  align-items: center;
  width: 100%;
  min-height: 100px;
  background: rgb(232, 232, 232);
  margin-bottom: 30px;
  vertical-align: middle;
  display: inline-flex;
  padding-left: 20px;

  a {
    text-overflow: ellipsis;
    color: #5433f1;
    font-family: Montserrat;
    font-size: 14px;
    text-decoration: underline;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    vertical-align: middle;
    display: inline-flex;
    padding-left: 20px;
  }

  img {
    margin-right: 13px;

    ${(props) =>
      props.file
        ? css`
            height: 27px;
            width: 18px;
          `
        : css`
            height: 20px;
            width: 24px;
          `}
  }

  div {
    display: block;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #5433f1;
    font-size: 20px;
  }
`;
