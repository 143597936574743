import { toast } from "react-toastify";

import { takeLatest, call, put, all } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";

import {
  getFaqCategoriesSuccess,
  getFaqQuestionsSuccess,
  getFaqSearchSuccess,
} from "./actions";

export function* getFaqSearch({ payload }) {
  const { search } = payload;
  try {
    const response = yield call(api.get, API.FAQ_SEARCH, {
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
      ds_pergunta: search,
    });
    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    const questions = response.data.data.map((faq) => {
      return {
        id: faq.id_faq,
        title: faq.ds_pergunta,
        answer: faq.ds_resposta,
        file: faq.ds_arquivo,
      };
    });
    yield put(getFaqSearchSuccess(questions));
  } catch (err) {
    toast.error("Erro ao buscar perguntas!");
  }
}

export function* getFaqQuestions({ payload }) {
  const { id_faq_categoria } = payload;
  try {
    const response = yield call(api.get, API.FAQ_SEARCH, {
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
      id_faq_categoria,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    const questions = response.data.data.map((faq) => {
      return {
        id: faq.id_faq,
        title: faq.ds_pergunta,
        answer: faq.ds_resposta,
        file: faq.ds_arquivo,
      };
    });

    yield put(getFaqQuestionsSuccess(questions));
  } catch (err) {
    toast.error("Erro ao buscar perguntas!");
  }
}

export function* getFaqCategories() {
  try {
    const response = yield call(api.get, API.FAQ_CATEGORIES, {
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    const categories = response.data.data.map((faq) => {
      return {
        id: faq.id_faq_categoria,
        value: faq.id_faq_categoria,
        label: faq.ds_categoria,
      };
    });
    yield put(getFaqCategoriesSuccess(categories));
  } catch (err) {
    toast.error("Erro ao buscar categorias!");
  }
}

export default all([
  takeLatest("@faq/GET_FAQ_SEARCH_REQUEST", getFaqSearch),
  takeLatest("@faq/GET_FAQ_QUESTION_REQUEST", getFaqQuestions),
  takeLatest("@faq/GET_FAQ_CATEGORIES_REQUEST", getFaqCategories),
]);
