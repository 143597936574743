/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import ContainerMD from "@material-ui/core/Container";
import { parseISO } from "date-fns";

import { Loading, Title } from "~/components";
import { formatDate } from "~/utils/date";
import documentTitle from "~/utils/documentTitle";

import { Container, BtnCallback } from "./styles";
import { getNotifysRequest } from "~/store/modules/notifys/actions";

export default function ViewAnnouncement() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id_notify } = useParams();
  const { loading, notifications } = useSelector((state) => state.notifys);
  const [notify, setNotify] = useState(null);

  documentTitle("Notificação");
  useEffect(() => {
    dispatch(
      getNotifysRequest({
        from: 1,
        to: 50,
      })
    );
  }, [dispatch, id_notify]);

  useEffect(() => {
    setNotify(
      notifications.find(
        (item) =>
          String(item.mat_notificacao.id_notificacao) === String(id_notify)
      )
    );
  }, [notifications]);

  function goBack() {
    window.history.go(-1);
  }

  return (
    <Container>
      {loading ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <BtnCallback onClick={goBack}>
            <span>
              <MdKeyboardArrowLeft
                size={16}
                color={theme.palette.primary[theme.palette.type]}
              />
            </span>
            <p>Voltar</p>
          </BtnCallback>
          <ContainerMD maxWidth="md">
            {notify && (
              <div>
                <div className="title">
                  <h2>Notificação</h2>
                  <p>Informações sobre suas atividades</p>
                </div>
                <div className="notify-content-title">
                  <Title>{notify.mat_notificacao.ds_titulo}</Title>
                  <span>
                    {formatDate(parseISO(notify.mat_notificacao.dt_cadastro))}
                  </span>
                </div>
                <p>{notify.mat_notificacao.ds_notificacao}</p>
              </div>
            )}
          </ContainerMD>
        </>
      )}
    </Container>
  );
}
